<ng-container *ngIf="context$ | async as context">
    <fillout-form-container
        *ngIf="context?.type === 'recruitment'"
        [hidden]="loading"
        [hideEmailBox]="hideEmailBox"
        [contact]="contact"
        [isSubmitted]="isSubmitted"
        [form]="form$ | async"
        (submitForm)="onSubmitForm($event)"
    ></fillout-form-container>

    <fillout-upload-images
        *ngIf="context?.type === 'upload-images'"
        [hidden]="loading"
        [isSubmitted]="isSubmitted"
        [email]="emailUploadPhoto$ | async"
        (submitPhotoForm)="onSubmitPhoto($event)"
    ></fillout-upload-images>

    <div *ngIf="disabled(context)" class="loading">
        <h1>Oups !</h1>
        Le contenu n'est plus accessible.<br />The content is no longer accessible.
    </div>
</ng-container>

<div *ngIf="loading && !notFound" class="loading">
    <clr-spinner>Loading ...</clr-spinner>
</div>

<div *ngIf="notFound" class="loading">
    <h1>404</h1>
    Le contenu n'existe pas.<br />The content does not exist.
</div>
