import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { forkJoin, from, Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
    AddPictureRequest,
    EditContactRequest,
    MassEditContactsRequest,
    MergeProfilesRequest,
    RemovePictureRequest,
} from 'src/app/api-clients/system-request-api-client.service';
import { md5 } from 'src/app/core/md5';
import { SystemRequestService } from 'src/app/services/system-request.service';
import { ElasticSearchMapperService, QueryObject } from 'src/modules/core/mappers/elasticsearch-mapper.service';
import { ContactPublic, RecruitService } from 'src/modules/fillout/services/recruit.service';

import { ContactApiClientService, ContactDTO } from '../api-clients/contact-api-client.service';
import { AttributeChange } from '../components/contacts-mass-edit/contacts-mass-edit.component';
import { ContactUpdateContext } from '../components/contacts/suppress-contact-prompt/suppress-contact-prompt.component';
import { ContactMapperService } from '../mappers/contact-mapper.service';
import { Attribute, Contact } from '../model/contact';
import { ContactPerspective } from '../model/contactPerspective';
import { SearchDefinition } from '../model/search-definition';
import { ElasticSearchService, SearchResultContacts } from './elasticsearch.service';
import { MassEditContactsSystemJob, SystemJobsService } from './system-jobs.service';
import { ApplicationDepartmentService } from 'src/app/services/application-department.service';
import { LocalDatabaseService } from './local-database.service';
import { DocumentReference, Timestamp } from 'firebase/firestore';
import { SearchState } from '../model/search-state';
import { ContactCacheService } from 'src/app/services/contact-cache.service';



@Injectable({
    providedIn: "root",
})
export class ContactService {
    constructor(
        private contactApi: ContactApiClientService,
        private contactMapper: ContactMapperService,
        private systemRequestService: SystemRequestService,
        private recruitService: RecruitService,
        private systemJobsService: SystemJobsService,
        private elasticSearchMapper: ElasticSearchMapperService,
        private esService: ElasticSearchService,
        private applicationDepartmentService: ApplicationDepartmentService,
        private localDatabaseService: LocalDatabaseService,
        private contactCacheService: ContactCacheService,
        private db: AngularFirestore
    ) { }


    searchContacts(searchDefinition: SearchDefinition, searchState: SearchState, options = { simpleProfile: false }): Observable<SearchResultContacts> {
        return this.esService.searchContacts(searchDefinition, searchState, options);
    }

    deactivateContact(contact: Contact, context: ContactUpdateContext): Observable<void> {
        return this.systemRequestService
            .add({
                requestType: "contactUpdate",
                idfig: contact.idfig,
                contact: { suppressed: true },
                context,
                created: new Date(),
            } as EditContactRequest)
            .pipe(
                switchMap((systemRequest) => {
                    console.log(systemRequest.id);
                    return this.systemRequestService
                        .get(systemRequest.id)
                        .pipe(filter((sr) => sr.status === "success"));
                }),
                map((_) => undefined)
            );
    }

    activateContact(contact: Contact): Observable<void> {
        return this.systemRequestService
            .add({
                requestType: "contactUpdate",
                idfig: contact.idfig,
                contact: { suppressed: false },
                context: { type: "reactivation" },
                created: new Date(),
            } as EditContactRequest)
            .pipe(
                switchMap((systemRequest) => {
                    console.log(systemRequest.id);
                    return this.systemRequestService
                        .get(systemRequest.id)
                        .pipe(filter((sr) => sr.status === "success"));
                }),
                map((_) => undefined)
            );
    }

    removeImage(contactId: string, imageId: string): Observable<void> {
        return from(
            this.db.collection("contacts").doc(contactId).collection("pictures").doc(imageId).update({ deleted: true })
        );
    }

    removeVideo(contactId: string, videoId: string): Observable<void> {
        return from(
            this.db.collection("contacts").doc(contactId).collection("videos").doc(videoId).update({ deleted: true })
        );
    }

    createContactManually(contact: ContactPublic, context: CreateContactContext): Observable<void> {
        return this.recruitService.publicRecruitment(contact, context.formId, context.origin, {});
    }

    massEditContacts(idfigs: string[], changes: AttributeChange[]): Observable<MassEditContactsSystemJob> {
        const sysReq: MassEditContactsRequest = {
            requestType: "massEditContacts",
            contacts: idfigs,
            changes,
        };
        return this.systemRequestService.add(sysReq).pipe(
            tap((sr) => console.log(sr.id)),
            switchMap((sr) => this.systemRequestService.get(sr.id)),
            filter((sr) => sr.status === "success" || sr.status === "error"),
            switchMap((sr) => this.systemJobsService.massEditJob(sr.id))
        );
    }

    massEditContactIds(contactIds: string[], changes: AttributeChange[], options = { wait: true }): Observable<MassEditContactsSystemJob> {
        return this.massEditSearchDefinitionResults(SearchDefinition.DEFAULT_SEARCH_DEFINITION_CONTACT_IDS(contactIds), changes, options);
    }

    massEditSearchDefinitionResults(
        searchDefinition: SearchDefinition,
        changes: AttributeChange[],
        options = { wait: true }
    ): Observable<MassEditContactsSystemJob> {
        const query = this.elasticSearchMapper.searchParamsToESQuery(this.applicationDepartmentService.currentDepartmentId(), searchDefinition);

        if (query.query.bool?.must_not) {
            delete query.query.bool.must_not;
        }

        const sysReq: MassEditContactsRequest = {
            requestType: "massEditContacts",
            query: JSON.stringify(query.query),
            changes,
        };

        if (options.wait) {
            return this.systemRequestService.add(sysReq).pipe(
                tap((sr) => console.log(sr.id)),
                switchMap((sr) => this.systemRequestService.get(sr.id)),
                filter((sr) => sr.status === "success" || sr.status === "error"),
                switchMap((sr) => this.systemJobsService.massEditJob(sr.id))
            );
        } else {
            return this.systemRequestService.add(sysReq).pipe(
                tap((sr) => console.log(sr.id)),
                map(sr => {
                    return { jobId: sr.id };
                }),
                take(1)
            );
        }
    }

    massEditDatasource(datasource: string, changes: AttributeChange[]): Observable<MassEditContactsSystemJob> {
        const queryObjet: QueryObject = {
            has_child: {
                type: "attributedocument",
                query: {
                    bool: {
                        must: [
                            {
                                term: {
                                    attribute: `datasource_${datasource}`,
                                },
                            },
                        ],
                    },
                },
                min_children: "1",
            },
        };
        const sysReq: MassEditContactsRequest = {
            requestType: "massEditContacts",
            query: JSON.stringify(queryObjet),
            changes,
        };

        return this.systemRequestService.add(sysReq).pipe(
            tap((sr) => console.log(sr.id)),
            switchMap((sr) => this.systemRequestService.get(sr.id)),
            filter((sr) => sr.status === "success" || sr.status === "error"),
            switchMap((sr) => this.systemJobsService.massEditJob(sr.id))
        );
    }

    editContact(contact: Contact): Observable<Contact> {
        return this.systemRequestService
            .add({
                requestType: "contactUpdate",
                idfig: contact.idfig,
                contact: this.buildApiContactStructure(contact.attributes),
                created: new Date(),
            } as EditContactRequest)
            .pipe(
                switchMap((systemRequest) => {
                    console.log(systemRequest.id);
                    return this.systemRequestService.get(systemRequest.id).pipe(
                        filter((sr) => sr.status === "success"),
                        take(1)
                    );
                }),
                map((_) => contact)
            );
    }

    editContactAttributes(attributes: Attribute[], contactId: string): Observable<void> {
        return this.systemRequestService
            .add({
                requestType: "contactUpdate",
                contactId,
                contact: this.buildApiContactStructure(attributes),
                created: new Date(),
            } as EditContactRequest)
            .pipe(
                switchMap((systemRequest) => {
                    console.log(systemRequest.id);
                    return this.systemRequestService.get(systemRequest.id).pipe(
                        filter((sr) => sr.status === "success"),
                        take(1)
                    );
                }),
                map(_ => undefined)
            );
    }

    changeContactDefaultImageIndex(contactId: string, index: number): Observable<void> {
        return from(this.db.collection("contacts").doc(contactId).update({ defaultImageIndex: index }));
    }

    private buildApiContactStructure(attributes: Attribute[]): any {
        const attrApi: any = {};

        attributes.forEach((attr) => {
            attrApi[attr.def.id] = attr.value;
        });

        return attrApi;
    }

    contactById(id: string): Observable<Contact> {

        return this.contactCacheService.contactChange(id).pipe(
            startWith(id),
            switchMap(id => {
                return this.localDatabaseService.get<ContactDTO>("contacts", id).pipe(
                    switchMap((c: ContactDTO) => {

                        if (c) {
                            return of(this.contactMapper.contactDtoToContact(c));
                        } else {
                            return this.contactApi.contactById(id).pipe(
                                switchMap((contactDto) => {
                                    if (contactDto) {
                                        return this.localDatabaseService.set("contacts", this.firestoreToIndexDBMapper({ ...contactDto, id })).pipe(map(_ => this.contactMapper.contactDtoToContact(contactDto)));
                                    }
                                    return of(this.contactMapper.contactDtoToContact(contactDto));
                                })
                            );
                        }
                    })
                );
            })
        );
    }

    contactsByIds(contactsIds: string[]): Observable<Contact[]> {
        return forkJoin(contactsIds.map((id) => this.contactById(id)));
    }

    contactByIdfig(idfig: string): Observable<Contact> {
        if (idfig) {
            const id = this.idfigToContactId(idfig);
            return this.contactById(id);
        }
        return of(undefined);
    }

    idfigToContactId(idfig: string): string {
        return md5(idfig).toUpperCase();
    }

    deleteContactPhoto(contactId: string, photoId: string): Observable<void> {
        return this.systemRequestService
            .add({
                requestType: "removePhoto",
                image: this.contactApi.photoDocumentReference(contactId, photoId),
            } as RemovePictureRequest)
            .pipe(
                tap((sr) => console.log(sr)),
                switchMap((sr) => this.systemRequestService.get(sr.id)),
                filter((sr: any) => sr.result === "success"),
                map((_) => undefined),
                take(1)
            );
    }

    addContactPhoto(idfig: string, imageUrl: string): Observable<string> {
        return this.systemRequestService
            .add({
                requestType: "addPhoto",
                idfig,
                photo: {
                    id: imageUrl,
                    url: imageUrl,
                },
            } as AddPictureRequest)
            .pipe(
                switchMap((sr) => this.systemRequestService.get(sr.id) as Observable<AddPictureRequest>),
                filter((sr) => sr.status === "success"),
                map((sr) => sr.photo.url),
                take(1)
            );
    }

    mergeProfiles(sources: ContactPerspective[], targetIdfig: string): Observable<void> {
        return this.systemRequestService
            .add({
                requestType: "mergeProfiles",
                sourceContacts: sources.map((cp) => cp.contact?.idfig).filter((idfig) => idfig !== targetIdfig),
                targetContact: targetIdfig,
            } as MergeProfilesRequest)
            .pipe(
                switchMap((systemRequest) => {
                    console.log(systemRequest.id);
                    return this.systemRequestService
                        .get(systemRequest.id)
                        .pipe(filter((sr) => sr.status === "success"));
                }),
                map((_) => undefined)
            );
    }

    private firestoreToIndexDBMapper(contact: any): any {
        return JSON.parse(JSON.stringify(contact, (key, value) => {
            if (value instanceof DocumentReference) {
                return value.path;
            }
            if (value instanceof Timestamp) {
                return value.toDate();
            }
            return value;
        }))
    }
}

export interface CreateContactContext {
    formId?: string;
    recruitmentId?: string;
    origin: string;
}

export interface MassEditResponse {
    success: boolean;
    errorMessage: string;
    systemRequestId: string;
}
