import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, filter, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { browserLanguage, validateEmail } from 'src/app/core/functions';
import { Translatable } from 'src/modules/core/components/translatable';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Form } from 'src/modules/diversite/model/form/form';
import { FormElement, FormElementError, FormElementInput } from 'src/modules/diversite/model/form/form-element/form-element';
import { FormModule } from 'src/modules/diversite/model/form/form-module';
import { ModuleService } from 'src/modules/diversite/services/module.service';

import { ResponseInput } from '../../../model/response-input';
import { Submission } from '../../../model/submission';
import {
    AccessCodeContactInfo,
    AccessCodeService,
    AuthenticatedProfile,
    CodeRequestStrategy,
    IdfigRequestStrategy,
    IdfigRequestStrategyOption,
} from '../../../services/access-code.service';
import { ContactPublic } from '../../../services/recruit.service';
import { SubmissionReponses } from 'src/modules/fillout/model/submission-responses';
import { FormMode } from 'src/modules/fillout/model/form-mode';

declare let $: any;
@Component({
    selector: "fillout-form-old",
    templateUrl: "./form-old.component.html",
    styleUrls: ["./form-old.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormOldComponent implements OnInit, OnChanges, OnDestroy, Translatable {
    private translationId = "mPPChPxCfo9iki7M9j7N";
    @Input() form: Form;
    @Input() mode: FormMode = "fillout";
    @Input() isSubmitted = false;
    @Input() isSubmitting = false;
    @Input() forceEndText = false;
    @Input() contact: ContactPublic;
    @Input() hideEmailBox = false;

    @Output() submitForm = new EventEmitter<SubmissionReponses>();


    contactIdfig: string;
    isEmailSubmitted = false;
    selectedLang$ = new BehaviorSubject<string>(undefined);
    showErrorsBoard = false;
    chooseProfileModalOpen = false;
    unregisteredEmail: string;
    private translationLabels: StaticLabels = {};

    private formResponses = new Map<string, ResponseInput>();
    private _errors: FormElementError[][] = [];
    private disposeBag = new DisposeBag();

    doWeKnowYouBoxOpen = true;

    showCodeEntryBox = false;
    showErrors = false;
    getCodeOptionsLoading = false;
    getCodeLoading = false;
    accessCode = "";
    accessCodeLoading = false;
    accessCodeError = false;
    loadingContact = false;
    private contextId: string;
    private idfigChange$ = new BehaviorSubject<string>(undefined);
    private accessCodeChange$ = new BehaviorSubject<string>(undefined);
    knowStrategy: CodeRequestStrategy = "email";
    idfigStrategy: "sms" | "email";
    email = "";
    idfig = "";
    newEmail = "";



    idfigStrategyOptions$: Observable<IdfigRequestStrategyOption[]> = this.idfigChange$.pipe(
        skip(1),
        debounceTime(1000),
        tap((_) => {
            this.getCodeOptionsLoading = true;
            this.chRef.detectChanges();
        }),
        switchMap((value) => {
            return this.accessCodeSevice.getCommunicationOptionsForAccessCode(value).pipe(
                map((response) => {
                    return response?.options;
                })
            );
        }),
        tap((_) => {
            this.getCodeOptionsLoading = false;
            this.chRef.detectChanges();
        })
    );

    authenticatedProfiles$: Observable<AuthenticatedProfile[]> = this.accessCodeChange$.pipe(
        filter((accessCode) => (accessCode && accessCode.length === 6 && this.contextId ? true : false)),
        tap(() => {
            this.accessCodeLoading = true;
            this.chRef.detectChanges();
        }),
        switchMap((accessCode) => {
            return this.accessCodeSevice.authenticatedProfilesForAccessCode(accessCode, this.contextId);
        }),
        map((response) => {
            if (response.status === "error") {
                this.accessCodeError = true;
                if (this.selectedLang === "fr") {
                    this.notificationService.show(`Une erreur est survenu. Code d'erreur: system request #${this.contextId}`, "danger", { autoClose: false });
                } else {
                    this.notificationService.show(`An error occured. Error code: system request #${this.contextId}`, "danger", { autoClose: false });
                }
                return undefined;
            } else {
                this.idfigChange$.next(undefined);
                this.showCodeEntryBox = false;
                this.idfig = "";
                this.knowStrategy = "email";
                this.idfigStrategy = undefined;
                this.email = "";
            }
            return response.authenticatedProfiles;
        }),
        switchMap((authenticatedProfiles) => {
            if (!authenticatedProfiles) {
                return of(undefined);
            } else if (!Array.isArray(authenticatedProfiles)) {
                if (this.selectedLang === "fr") {
                    this.notificationService.show(`Une erreur est survenu. Code d'erreur: system request #${this.contextId}`, "danger", { autoClose: false });
                } else {
                    this.notificationService.show(`An error occured. Error code: system request #${this.contextId}`, "danger", { autoClose: false });
                }
                console.error(authenticatedProfiles);
            } else if (Array.isArray(authenticatedProfiles) && authenticatedProfiles.length === 0) {
                if (this.selectedLang === "fr") {
                    this.notificationService.show(`Aucun profil trouvé pour les informations fournis. Code d'erreur: system request #${this.contextId}`, "danger", { autoClose: false });
                } else {
                    this.notificationService.show(`No profile was found for provided information. Error code: system request #${this.contextId}`, "danger", { autoClose: false });
                }
            } else if (Array.isArray(authenticatedProfiles) && authenticatedProfiles.length > 1) {
                this.chooseProfileModalOpen = true;
                return of(authenticatedProfiles);
            } else if (Array.isArray(authenticatedProfiles) && authenticatedProfiles[0]) {
                if (authenticatedProfiles[0].idfig) {
                    this.loadContact(authenticatedProfiles[0].idfig);
                    return of(undefined);
                } else {
                    if (this.selectedLang === "fr") {
                        this.notificationService.show("Le profil n'a pas d'identifiant.", "warning");
                    } else {
                        this.notificationService.show("Profile has no id.", "warning",);
                    }
                    console.error(authenticatedProfiles);
                }
            }
            return of(undefined);
        }),
        tap(() => {
            this.accessCodeLoading = false;
            this.chRef.detectChanges();
        })
    );

    constructor(
        private chRef: ChangeDetectorRef,
        private title: Title,
        private route: ActivatedRoute,
        private staticLabelsService: StaticLabelsService,
        private accessCodeSevice: AccessCodeService,
        private notificationService: NotificationService,
        private modulesService: ModuleService,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.unregisteredEmail = this.route.snapshot.queryParams.unregisteredEmail;
        if (this.unregisteredEmail) {
            this.knowStrategy = "new";
            this.doWeKnowYouBoxOpen = false;
            this.hideEmailBox = true;
        }

        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);

        this.selectedLang$
            .pipe(skip(1))
            .subscribe((lang) => {
                // this.title.setTitle(`${this.form.name[lang]} - youlug`);
                lang === "fr" ? this.title.setTitle("Formulaire Youlug") : this.title.setTitle("Youlug Form");
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.form && changes.form.currentValue) {
            this.initForm(changes.form.currentValue);
        }
    }

    changeIdfigStrategy(strategy: IdfigRequestStrategy): void {
        this.idfigStrategy = strategy;
    }

    loadContact(idfig: string): void {
        if (!this.loadingContact) {
            this.loadingContact = true;
            this.accessCodeSevice
                .loadContact(idfig, this.contextId)
                .pipe(take(1))
                .subscribe((c) => {
                    this.contactIdfig = idfig;
                    this.doWeKnowYouBoxOpen = false;
                    this.contact = c;
                    this.chooseProfileModalOpen = false;
                    this.loadingContact = false;
                    this.chRef.detectChanges();
                })
                .disposedBy(this.disposeBag);
        }
    }

    get doWeKnowYouShowForm(): boolean {
        if (this.knowStrategy === "new") {
            return true;
        } else if (this.contact) {
            return true;
        }
        return false;
    }

    label(labelId: string): string {
        if (
            this.translationLabels &&
            this.translationLabels[labelId] &&
            this.translationLabels[labelId][this.selectedLang]
        ) {
            return this.translationLabels[labelId][this.selectedLang];
        }
        return labelId;
    }

    toggleDoWeKnowBox(): void {
        this.doWeKnowYouBoxOpen = !this.doWeKnowYouBoxOpen;
    }

    get browserLanguage(): string {
        return browserLanguage();
    }

    get selectedLang(): string {
        return this.selectedLang$.value;
    }

    private initForm(form: Form): void {
        if (this.form.languages.includes(this.browserLanguage)) {
            this.selectedLang$.next(this.browserLanguage);
        } else if (this.form.languages.length > 0) {
            this.selectedLang$.next(this.form.languages[0]);
        } else {
            this.selectedLang$.next("fr");
        }
    }

    disabledKnowStrategy(strategy: "email" | "idfig"): boolean {
        return this.knowStrategy !== strategy;
    }

    logo(): string {
        return this.form && this.form.logo && this.form.logo !== "" ? this.form.logo : `assets/logo.jpg`;
    }

    trackByFnModules(index: number, module: FormModule): string {
        return module.id;
    }

    trackByFnFormElement(index: number, formElement: FormElement): string {
        return `${formElement.id}-${formElement.name}`;
    }

    selectLang(lang: string): void {
        this.selectedLang$.next(lang);
    }

    value(formElement: FormElement): any {
        if (this.contact) {
            return this.contactValueForFormElement(formElement);
        }
    }

    private contactValueForFormElement(formElement: FormElement): any {
        if (formElement.isInput()) {
            const attribute = this.contact
                ? this.contact.attributes.find((attr) => {
                    return attr.index === formElement.name;
                })
                : undefined;
            return attribute && attribute.value ? attribute.value : "";
        } else {
            return undefined;
        }
    }

    get showForm(): boolean {
        return (
            (!this.isSubmitted && !this.isSubmitting && !this.isEmailSubmitted && this.doWeKnowYouShowForm) ||
            this.mode === "preview" ||
            (this.mode === "admin_fix" && !this.isSubmitted && !this.isSubmitting)
        );
    }

    private scrollToTop(): void {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    onResponseChange(response: ResponseInput): void {
        this.formResponses.set(response.input.id, response);
        this.chRef.detectChanges();
    }

    isValid(): boolean {
        if (this.mode === "admin_fix") {
            return this.contactIdfig ? true : false;
        }

        const formInputs: FormElementInput[] = this.form
            .activeFormElements()
            .filter((fe) => fe.isInput()) as FormElementInput[];

        this._errors = formInputs
            .map((fi) => {
                const responseInput = this.formResponses.get(fi.id);
                const isValid = responseInput ? fi.validate(responseInput.value) : fi.validate(undefined);
                return isValid;
            })
            .filter((_) => _);

        if (!this.isNewMemberAndEmailValid()) {
            this._errors.unshift([
                {
                    type: "required",
                    isNewMemberEmailError: true,
                },
            ]);
        }

        return this._errors.length === 0;
    }

    get isAdmin(): boolean {
        return this.mode === "admin_fix";
    }

    isAdminAndHiddenElement(formElement: FormElement): boolean {
        return (
            this.isAdmin &&
            (formElement.type === "text" ||
                formElement.type === "imageupload" ||
                formElement.type === "videoupload" ||
                formElement.type === "fileupload")
        );
    }

    private isNewMemberAndEmailValid(): boolean {
        if (this.hideEmailBox || this.mode === "preview" || this.knowStrategy !== "new") {
            return true;
        } else if (validateEmail(this.newEmail)) {
            return true;
        }
        return false;
    }

    get errors(): FormElementError[][] {
        return this._errors;
    }

    seeError(error: FormElementError): void {
        this.scrollToAndFlashElementId(error.formElement.id);
    }

    seeNewMemberEmailField(): void {
        this.scrollToAndFlashElementId("newMemberEmailInputElement");
    }

    private scrollToAndFlashElementId(elementId: string): void {
        $("html, body").animate(
            {
                scrollTop: $(`#${elementId}`).offset().top - 30,
            },
            250,
            () => {
                $(`#${elementId}`)
                    .addClass("show")
                    .delay(3000)
                    .queue((next) => {
                        $(`#${elementId}`).removeClass("show");
                        next();
                    });
            }
        );
    }

    get gettingCodeDisabled(): boolean {
        if (this.knowStrategy === "email") {
            return !validateEmail(this.email);
        }
        if (this.knowStrategy === "idfig") {
            if (this.idfig && this.idfig.length > 0) {
                return !(this.idfigStrategy === "sms" || this.idfigStrategy === "email");
            }
            return true;
        }
        return true;
    }

    getCode(): void {
        let contactInfo: AccessCodeContactInfo;
        if (this.knowStrategy === "email" && this.email) {
            contactInfo = { type: "email", email: this.email || null };
        }
        if (this.knowStrategy === "idfig" && this.idfig) {
            contactInfo = { type: this.idfigStrategy, idfig: this.idfig || null };
        }

        if (contactInfo) {
            this.getCodeLoading = true;
            this.accessCodeSevice.getCode(contactInfo, this.selectedLang).subscribe((contextId) => {
                this.contextId = contextId;
                this.getCodeLoading = false;
                this.showCodeEntryBox = true;
                this.notificationService.show("Votre code à été envoyé", "success");
                this.chRef.detectChanges();
            });
        } else {
            this.notificationService.show("Vous devez soumettre un code d'identification ou un courriel pour obtenir un code", "warning");
        }


    }

    onAccessCodeKeyUp(value: string): void {
        this.accessCodeError = false;
        this.accessCodeLoading = false;
        this.accessCodeChange$.next(value);
    }

    getStrategiesForIdfig(value: string): void {
        this.getCodeOptionsLoading = false;
        this.idfigChange$.next(value);
    }

    submit(): void {
        if (this.mode === "fillout" || this.mode === "admin_fix") {
            if (this.isValid()) {
                const responses: ResponseInput[] = Array.from(this.formResponses).map((a) => a[1]);

                this.isSubmitting = true;

                if (this.knowStrategy === "new") {
                    responses.push({
                        value: this.newEmail,
                        input: this.modulesService.formElementForIndex("contactInformation_email") as FormElementInput,
                    });
                }

                this.submitForm.emit({
                    idfig: this.contactIdfig,
                    contact: this.contact ? this.contact : undefined,
                    form: this.form,
                    responseInputs: responses,
                });
                this.scrollToTop();
            }
        }

        if (!this.isValid()) {
            this.showErrorsBoard = true;
            this.chRef.markForCheck();
        }
    }

    trackByError(index: number, errors: FormElementError[]): string {
        return `${index}`;
    }

    safe(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    ngOnDestroy(): void {
        this.title.setTitle(`youlug`);
    }
}


