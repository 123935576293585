import { ContactResult } from "../services/elasticsearch.service";
import { MetaTotal } from "./search-meta";

export class SearchResults {
    private _total: MetaTotal;
    private _contactResults: ContactResult[];

    constructor(builder?: { total: MetaTotal; contactResults: ContactResult[] }) {
        this._total = builder?.total || { value: 0, relation: "" };
        this._contactResults = builder?.contactResults || [];
    }

    get total(): MetaTotal { return this._total; }
    get contactResults(): ContactResult[] { return this._contactResults; }

    change(builder: { total?: MetaTotal; contactResults?: ContactResult[] }) {
        return new SearchResults({
            total: this._total,
            contactResults: this._contactResults,
            ...builder
        })
    }
}