<div class="clr-row change" *ngFor="let history of changeHistory">
    <div class="clr-col-10">
        <div *ngIf="history.type === 'uploadPhoto'">
            L'utilisateur à fait une mise à jour de photo.
            <button (click)="openUpdatePhoto(history)" class="btn btn-sm form-btn">Voir la mise a jour</button>
        </div>
        <div *ngIf="history.type === 'deactivation'">
            Le profil de l'utilisateur à été déactivé. Cette personne

            <span *ngIf="history.context.reason === 'back_country'">est retournée dans son pays d'origine.</span>
            <span *ngIf="history.context.reason === 'death'">est décèdée.</span>
            <span *ngIf="history.context.reason === 'ask_by_contact'">à demandée à être retirée.</span>
            <span *ngIf="history.context.reason === 'other'">
                n'est plus disponible pour la raison suivante : {{ history.context.reasonOther }}</span
            >

            <div
                *ngIf="history.context?.additionalInfo && history.context.additionalInfo !== ''"
                class="additional_info"
            >
                <strong>Informations additionelles: </strong><br />
                {{ history.context.additionalInfo }}
            </div>
        </div>
        <div *ngIf="history.type === 'legacySync'">
            Le profil de l'utilisateur a été synchronisé avec l'ancien logiciel.
        </div>
        <div *ngIf="history.type === 'formSubmission'">
            L'utilisateur a repondu à un formulaire.
            <button [hidden]="!history.data" (click)="openFormResponse(history)" class="btn btn-sm form-btn">
                Voir le formulaire
            </button>
        </div>

        <div *ngIf="history.type === 'reactivation'">
            Le profil de l'utilisateur à été réactivé.
            <div
                *ngIf="history.context?.additionalInfo && history.context.additionalInfo !== ''"
                class="additional_info"
            >
                <strong>Informations additionelles: </strong><br />
                {{ history.context.additionalInfo }}
            </div>
        </div>
        <div *ngIf="history.type === 'updateProfile'">
            Le profil de l'utilisateur à changé.
            <button *ngIf="hasDelta(history)" (click)="openDeltaModal(history)" class="btn btn-sm form-btn">
                Voir le changement
            </button>
        </div>
        <div *ngIf="history.type === 'unmapped'">{{ history | json }}</div>
    </div>
    <div class="clr-col-2 date-user">
        <small
            ><strong *ngIf="history.user">{{ history.user?.email }}</strong
            >{{ history.date | date }}</small
        >
    </div>
</div>

<div class="clr-row change">
    <div class="clr-col-10">
        L'utilisateur s'est enregistré.
        <strong *ngIf="contact.isPremium"><clr-icon shape="bolt"></clr-icon> Premium</strong>
    </div>
    <div class="clr-col-2 date-user">
        <small>{{ contact.createdAt | date }}</small>
    </div>
</div>

<clr-modal *ngIf="openDelta" [(clrModalOpen)]="openDelta" (clrModalOpenChange)="onDeltaOpenChange($event)">
    <h3 class="modal-title">Changement</h3>
    <div class="modal-body">
        <div *ngIf="delta$ | async as delta">
            <div class="delta" *ngFor="let attr of attributes(delta); trackBy: trackByFn">
                <div class="label-attribute">
                    <diversite-attribute-label [id]="attr.id"></diversite-attribute-label>
                </div>
                <diversite-attribute-value [attributeName]="attr.id" [value]="attr.value"></diversite-attribute-value>
            </div>
        </div>
    </div>
</clr-modal>
