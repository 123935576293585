<div class="node-container" #nodeElement *ngIf="node$ | async as node">
    <div class="node" [class.open]="isOpen">
        <cds-icon (click)="toggleOpenNode(!isOpen)" shape="angle" [direction]="isOpen ? 'down' : 'right'"></cds-icon>
        <div class="node-content">
            <div
                (dblclick)="onDblClickHeader(node)"
                #nodeTitleElement
                class="node-title"
                (contextmenu)="onRightClick($event, node)"
            >
                <cds-icon
                    [style.color]="node.color"
                    #draggableNode
                    class="shape-icon"
                    [solid]="true"
                    [class.draggable-node]="nodeDraggable(node)"
                    (mouseover)="setDragIcon(node)"
                    (mouseout)="setNormalIcon(node)"
                    [attr.shape]="nodeShape()"
                />

                <input
                    class="node-name-input"
                    (blur)="onFocus($event)"
                    (keydown)="onKeyDown($event)"
                    (change)="onNodeNameChange(node, $event.target.value)"
                    [value]="node.name"
                />
                <span class="actions">
                    <ng-container *ngIf="node.type === 'search'">
                        <cds-icon (click)="openSearchTab(node)" shape="view-columns"></cds-icon>
                    </ng-container>
                    <ng-container *ngIf="node.type === 'project'">
                        <cds-icon (click)="openClassificationTab(node)" shape="assign-user" />
                        <!-- <cds-icon (click)="openClassificationTab(node)" shape="assign-user"></cds-icon> -->
                    </ng-container>
                </span>
            </div>

            <clr-spinner clrSmall *ngIf="contactsLoading$ | async"></clr-spinner>
            <div *ngIf="isOpen" class="node-body open">
                <button
                    *ngIf="lastUpdatedPublishedFormFormProject$ | async as lastForm"
                    (click)="onViewRespondents(lastForm)"
                    class="btn btn-sm"
                >
                    Dernier formulaire publié ({{ lastForm.name[lang] }})
                </button>
                <!-- CONTACTS NODE -->
                <ng-container *ngIf="contacts$ | async as contacts">
                    <div class="fixed-node" *ngIf="contacts.length > 0">
                        <cds-icon
                            (click)="isContactOpen = !isContactOpen"
                            shape="angle"
                            [direction]="isContactOpen ? 'down' : 'right'"
                        ></cds-icon>
                        <diversite-context-node-contacts
                            [projectId]="projectId"
                            [isOpen]="isContactOpen"
                            [total]="contacts.length"
                            [contacts]="contacts"
                            [node]="node"
                        />
                    </div>
                </ng-container>
                <!-- END CONTACTS NODE -->

                <!-- FORMS NODE -->
                <ng-container *ngIf="node.hasForms">
                    <div class="fixed-node">
                        <cds-icon
                            (click)="isFormOpen = !isFormOpen"
                            shape="angle"
                            [direction]="isFormOpen ? 'down' : 'right'"
                        />
                        <diversite-context-node-forms
                            [isOpen]="isFormOpen"
                            [node]="node"
                            [projectId]="projectId"
                            [forms]="forms$ | async"
                        />
                    </div>
                </ng-container>
                <!-- END FORMS NODE -->

                <!-- REPORTS NODE -->
                <ng-container *ngIf="node.hasReports">
                    <div class="fixed-node">
                        <cds-icon
                            (click)="isReportsOpen = !isReportsOpen"
                            shape="angle"
                            [direction]="isReportsOpen ? 'down' : 'right'"
                        />
                        <diversite-context-node-reports
                            [isOpen]="isReportsOpen"
                            [node]="node"
                            [projectId]="projectId"
                            [reports]="reports$ | async"
                        />
                    </div>
                </ng-container>
                <!-- END REPORTS NODE -->

                <!-- SHARED FOLDER NODE -->
                <ng-container *ngIf="node.hasSharedFolder">
                    <div class="fixed-node">
                        <cds-icon
                            (click)="isSharedFolderOpen = !isSharedFolderOpen"
                            shape="angle"
                            [direction]="isSharedFolderOpen ? 'down' : 'right'"
                        />
                        <diversite-context-node-shared-packages
                            [projectId]="projectId"
                            [node]="node"
                            [isOpen]="isSharedFolderOpen"
                            [packages]="packages$ | async"
                            (editPackage)="onEditPackage($event)"
                            (deletePackage)="onDeletePackage(node, $event)"
                        />
                    </div>
                </ng-container>
                <!-- END SHARED FOLDER NODE -->

                <!-- SEARCH PARAMETERS -->
                <diversite-search-parameters-compact
                    *ngIf="node.type === 'search' && isOpen"
                    [searchDefinition]="searchParameters$ | async"
                />
                <!-- END SEARCH PARAMETERS -->

                <ng-container *ngIf="isOpen">
                    <diversite-context-node
                        *ngFor="let nodeId of node.children; trackBy: trackByString"
                        [parentNodeId]="node.id"
                        [projectId]="projectId"
                        [nodeId]="nodeId"
                    />
                </ng-container>
            </div>
        </div>
    </div>
</div>
