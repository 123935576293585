export interface SortDefinition {
    field: string;
    direction: "desc" | "asc";
}
export const DEFAULT_SORT: SortDefinition = {
    field: "lastUpdatedAt",
    direction: "desc",
};
export const DEFAULT_LIMIT = 50;

export class SearchState {
    private _limit: number;
    private _page: number;
    private _sort: SortDefinition;

    constructor(builder?: { limit?: number; page?: number; sort?: SortDefinition }) {
        this._limit = builder?.limit === 0 || builder?.limit ? builder.limit : DEFAULT_LIMIT;
        this._page = builder?.page || 1;
        this._sort = builder?.sort || DEFAULT_SORT;
    }

    get limit(): number { return this._limit; }
    get page(): number { return this._page; }
    get sort(): SortDefinition { return this._sort; }

    change(builder: { limit?: number; page?: number; sort?: SortDefinition }): SearchState {
        return new SearchState({
            limit: this._limit,
            page: this._page,
            sort: this._sort,
            ...builder
        })
    }
}

