import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, filter, from, map, Observable, of, switchMap, take } from 'rxjs';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { Form } from 'src/modules/diversite/model/form/form';
import { Project } from 'src/modules/diversite/model/project';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchState } from 'src/modules/diversite/model/search-state';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ElasticSearchService, SearchResultContacts } from 'src/modules/diversite/services/elasticsearch.service';
import { ExportContactsService } from 'src/modules/diversite/services/export-contacts.service';
import {
    ExportExcelOptions,
    ExportImageOptions,
    ExportTemplate,
    ExportTemplateService,
    ImageQuality,
} from 'src/modules/diversite/services/export-template.service';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

declare var $: any;
@Component({
    selector: 'diversite-export-action',
    templateUrl: './export-action.component.html',
    styleUrl: './export-action.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportActionComponent {
    @Input() searchDefinition: SearchDefinition;
    @Output() close = new EventEmitter<void>();

    constructor(
        private exportTemplate: ExportTemplateService,
        private elasticSearchService: ElasticSearchService,
        private contactService: ContactService,
        private exportContactsService: ExportContactsService,
        private notificationService: NotificationService,
        private projectService: ProjectService,
        private formService: FormService,
        private translateService: TranslateService,
        private chRef: ChangeDetectorRef
    ) { }


    projects$: Observable<Project[]>;
    forms$: Observable<Form[]>;
    projectSelection = "";
    projectChange = new BehaviorSubject<string>(undefined);
    formSelection = "";


    exportTemplates$: Observable<ExportTemplate[]>
    private queryResult$: Observable<SearchResultContacts>;
    contacts$: Observable<Contact[]>;
    metaMore$: Observable<number>;

    generatingBundle = false;
    progress: number = 0;

    exportTemplate$ = new BehaviorSubject<ExportTemplate>(undefined);

    includeImagesOptions$ = new BehaviorSubject<ExportImageOptions>(undefined);
    includeExcelFileOptions$ = new BehaviorSubject<ExportExcelOptions>(undefined)

    private disposeBag = new DisposeBag();

    ngOnInit(): void {
        this.queryResult$ = this.elasticSearchService.searchContacts(this.searchDefinition, new SearchState({ page: 1, limit: 10 }));
        this.contacts$ = this.queryResult$.pipe(switchMap(r => {
            return this.contactService.contactsByIds(r.contactResults.map(r => r.contactId))
        }));


        this.projects$ = this.projectService.activeProjects();
        this.forms$ = this.projectChange.pipe(filter(_ => _ ? true : false), switchMap(projectId => {
            if (projectId !== "") {
                return this.formService.formForProject(projectId);
            }
            return of([]);
        }))



        this.metaMore$ = this.queryResult$.pipe(
            filter(r => r.total && r.total.value > 10 ? true : false),
            map(r => {
                return r.total.value - 10;
            })
        );

        this.exportTemplate.templates({ listen: false }).pipe(map(templates => {
            // for now we only have 1 export template, so we dont offer user to choose it,
            // we automatically set variables for the only available template
            return templates[0];
        })).subscribe((template: ExportTemplate) => {
            this.exportTemplate$.next(template);
            this.includeImagesOptions$.next(template.options.imagesOptions);
            this.includeExcelFileOptions$.next(template.options.excelOptions);
            this.chRef.detectChanges();
        }).disposedBy(this.disposeBag);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            $("diversite-attribute-multiselect#included-fields span[role=grid]").sortable({
                update: (e, ui) => {

                    const orderedLabels = $("diversite-attribute-multiselect#included-fields span[role=grid] diversite-attribute-label[data-attribute-id]").toArray().map(e => {
                        return e.getAttribute("data-attribute-id");
                    }).filter(_ => _ ? true : false);

                    this.includeExcelFileOptions$.next({
                        ...this.includeExcelFileOptions$.value,
                        fields: orderedLabels
                    });

                }
            });
        }, 1000);
    }

    onProjectSelectionChange(): void {
        this.projectChange.next(this.projectSelection);
    }

    get lang(): string {
        return this.translateService.lang;
    }

    addFormFields(): void {
        this.formService.form(this.formSelection, { listen: false }).subscribe(form => {
            if (form) {
                const formElementNames = form.activeFormElements().map(fe => fe.name).filter(name => !this.includeExcelFileOptions$.value.fields.includes(name));

                this.includeExcelFileOptions$.next({
                    ...this.includeExcelFileOptions$.value,
                    fields: [...this.includeExcelFileOptions$.value.fields, ...formElementNames]
                });
            }
        }).disposedBy(this.disposeBag);
        this.projectChange.next("");
        this.formSelection = "";
        this.projectSelection = "";
    }

    download(): void {
        this.exportContactsService.exportContacts(
            this.searchDefinition,
            {
                imagesOptions: this.includeImagesOptions$.value,
                excelOptions: this.includeExcelFileOptions$.value
            }
        ).subscribe(sr => {
            this.generatingBundle = true;
            this.progress = sr.progress && sr.progress.totalToExport > 0 && sr.progress.exportedCount > 0 ?
                Math.round((sr.progress.exportedCount / sr.progress.totalToExport) * 100) :
                0;

            if (sr.status === "success") {
                Object.keys(sr.result).forEach(key => {
                    // window.open(sr.result[key],, {});
                    this.downloadAsDataURL(sr.result[key]).subscribe().disposedBy(this.disposeBag);
                });

                setTimeout(() => {
                    this.notificationService.show("L'export à été effectué avec succès.", "success");
                    this.close.emit();
                }, 2000)
            }

            if (sr.status === "error") {
                console.log(sr);
                this.notificationService.show("L'export à échoué", "danger");
            }
            this.chRef.detectChanges();

        }).disposedBy(this.disposeBag);
    }

    downloadAsDataURL(url: string): Observable<string> {
        return from(new Promise<string>((resolve, reject) => {
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const filename = url.split("/").reverse()[0];
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                    link.remove();
                })
                .catch(err => reject(err))
        })).pipe(take(1));
    }

    onIncludeImagesCheck(isChecked: boolean): void {
        if (isChecked) {
            this.includeImagesOptions$.next(this.exportTemplate$.value.options.imagesOptions);
        } else {
            this.includeImagesOptions$.next({
                includeImages: false,
                quality: null,
                images: null,
            });
        }
    }

    onExcelFileChange(isChecked: boolean): void {
        if (isChecked) {
            this.includeExcelFileOptions$.next(this.exportTemplate$.value.options.excelOptions);
        } else {
            this.includeExcelFileOptions$.next({
                includeFile: false,
                fields: []
            });
        }
    }

    onChangeImageCollectionType(imageCollectionType: "all" | "dateRange"): void {
        this.includeImagesOptions$.next({
            ... this.includeImagesOptions$.value,
            images: {
                type: imageCollectionType,
                date: imageCollectionType === "dateRange" ? { from: null, to: null } : null
            }
        })
    }

    onChangeImageQuality(quality: ImageQuality): void {
        this.includeImagesOptions$.next({
            ...this.includeImagesOptions$.value,
            quality
        })
    }

    onSelectedFieldsChange(event: any): void {

    }

    trackById(_: number, entity: any): string {
        return entity.id;
    }

    onChangeDateImage(fromTo: "from" | "to", event: any): void {
        this.includeImagesOptions$.next({
            ...this.includeImagesOptions$.value,
            images: {
                type: "dateRange",
                date: {
                    from: fromTo === "from" ? event : this.includeImagesOptions$.value.images?.date.from || null,
                    to: fromTo === "to" ? event : this.includeImagesOptions$.value.images?.date.to || null
                }
            }
        })
    }

    clearFields(): void {
        this.includeExcelFileOptions$.next({
            ... this.includeExcelFileOptions$.value,
            fields: []
        });
    }

    onCancel(): void {
        this.close.emit();
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
