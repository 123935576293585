import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContextNode } from 'src/modules/diversite/model/context-node';
import { Pane } from 'src/modules/diversite/model/pane';
import { ContactsSelectionService } from 'src/modules/diversite/services/contacts-selection.service';
import { ContextMenuService } from 'src/modules/diversite/services/context-menu.service';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';

import { ContextNodePaneActionsService } from '../../../context-node/context-node-pane-actions.service';
import { PaneReferenceService } from '../../services/pane-reference.service';
import { PaneService } from '../../services/pane.service';
import { SortDefinition } from 'src/modules/diversite/model/search-state';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
    selector: "diversite-treeview-pane-content-container",
    templateUrl: "./treeview-pane-content-container.component.html",
    styleUrls: ["./treeview-pane-content-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeviewPaneContentContainerComponent implements OnInit, OnDestroy {
    constructor(
        private contextNodeService: ContextNodeService,
        private contextMenuService: ContextMenuService,
        private contextNodePaneActionsService: ContextNodePaneActionsService,
        private actionsService: ContextNodePaneActionsService,
        private paneRefService: PaneReferenceService,
        private paneService: PaneService,
        private contactsSelection: ContactsSelectionService,
        private authService: AuthService,
        private db: AngularFirestore,
        private router: Router
    ) { }

    @Input() pane: Pane;
    nodes$: Observable<ContextNode[]>;
    loading: { [id: string]: boolean } = {};

    private node: ContextNode;
    private disposeBag = new DisposeBag();

    globalOpen = false;
    addContactOpen = false;
    projectFocusNode: ContextNode;

    contactSelection$: Observable<string[]>;

    ngOnInit(): void {
        this.nodes$ = this.contextNodeService.nodeView({ listen: true }).pipe(debounceTime(100));
        this.contactSelection$ = this.contactsSelection.contactsSelection();


        // this.db.collection("projects").get().pipe(
        //     switchMap(projectDocs => {
        //         const projects = projectDocs.docs.map((d: any) => {
        //             return { ...d.data(), id: d.id };
        //         })
        //         console.log("projects", projects)
        //         return this.db.collection("contextNodes").get().pipe(
        //             switchMap(docs => {
        //                 const nodes = docs.docs.map((d: any) => {
        //                     return { ...d.data(), id: d.id };
        //                 }).filter((n: any) => n.type === "project" && n.contextData?.projectId);
        //                 console.log("nodes", nodes);
        //                 return forkJoin(
        //                     nodes.map(node => {
        //                         const p = projects.find(p => p.id === node.contextData.projectId);
        //                         if (p) {
        //                             return this.db.collection("contextNodes").doc(node.id).update({
        //                                 created: p.created.toDate()
        //                             })
        //                         }
        //                     }).filter(_ => _)
        //                 )
        //             })
        //         )
        //     })
        // ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pane?.currentValue) {
            const projectNodeId = changes.pane?.currentValue.contextData?.focusProjectContextNodeId;
            if (projectNodeId) {
                this.contextNodeService
                    .node(projectNodeId, { listen: false })
                    .subscribe((projectNode) => {
                        this.projectFocusNode = projectNode;
                    })
                    .disposedBy(this.disposeBag);
            }
        }
    }

    openProjectsPane(): void {
        this.actionsService.openProjectsPane().subscribe().disposedBy(this.disposeBag)
    }

    onNodeLoaded(node: ContextNode): void {
        this.node = node;
    }

    get username(): string {
        return this.authService.loggedInUser.email;
    }

    openAddContactPane(dbId?: string): void {
        this.actionsService
            .openActionPaneAtIndex(
                "add-contact",
                this.paneRefService.currentLayout.findIndex((paneContent) => paneContent.paneRef.id === this.pane.id) + 1,
                { contactsDatabaseId: dbId ? dbId : null }
            )
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    viewActivities(): void {
        this.actionsService.openActivitiesPane().subscribe().disposedBy(this.disposeBag)
    }

    onRightClick(event: PointerEvent): void {
        this.contextMenuService.openContextMenu(
            [
                {
                    icon: "briefcase",
                    label: "Ajouter un projet",
                    action: () => {
                        this.actionsService.addProject();
                    },
                },
            ],
            event
        );
    }

    onAddProjectClick(): void {
        this.contextNodePaneActionsService.addProject();
    }

    get expandable(): boolean {
        return this.node && this.node.children && this.node.children.length > 0;
    }

    logout(): void {
        this.authService.logout().subscribe(() => {
            this.router.navigate(["/"]);
        });
    }

    trackById(_: number, obj: any): string {
        return obj.id;
    }

    openMassEditPane(dbId?: string): void {
        this.actionsService
            .openActionPaneAtIndex(
                "mass-edit",
                this.paneRefService.currentLayout.findIndex((paneContent) => paneContent.paneRef.id === this.pane.id) + 1,
                { datasource: dbId }
            )
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    openGenericSearchPane(orderBy: "createdAt" | "lastUpdatedAt" = "lastUpdatedAt"): void {
        let order: SortDefinition;
        if (orderBy) {
            order = {
                field: orderBy,
                direction: "desc"
            }
        }
        this.actionsService.openDatabaseSearchPane(null, order).subscribe().disposedBy(this.disposeBag);
    }

    onFocusProject(n: ContextNode): void {
        this.pane = this.pane.change({
            contextData: this.pane.contextData
                ? { ...this.pane.contextData, focusProjectContextNodeId: n.id }
                : { focusProjectContextNodeId: n.id },
        });
        this.paneService.editPane(this.pane).subscribe().disposedBy(this.disposeBag);
        this.projectFocusNode = n;
    }

    onCloseFocus(): void {
        this.pane = this.pane.change({
            contextData: this.pane.contextData
                ? { ...this.pane.contextData, focusProjectContextNodeId: null }
                : { focusProjectContextNodeId: null },
        });
        this.paneService.editPane(this.pane).subscribe().disposedBy(this.disposeBag);
        this.projectFocusNode = null;
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
