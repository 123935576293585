import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

import { PaneReferenceService } from '../components/dashboard/services/pane-reference.service';
import { ContextData, ContextNode, ContextNodeType } from '../model/context-node';
import { Pane } from '../model/pane';
import { ReportService } from './report.service';
import { FormService } from './form.service';
import { ContactService } from './contact.service';
import { SearchDefinition } from '../model/search-definition';
import { SearchState } from '../model/search-state';


@Injectable({
    providedIn: "root",
})
export class ContextNodeService {
    private _allowedDropForMovingNodeToNode = new Map<ContextNodeType, ContextNodeType[]>();

    constructor(
        private authService: AuthService,
        private formService: FormService,
        private paneReferenceService: PaneReferenceService,
        private contactService: ContactService,
        private reportService: ReportService,
        private db: AngularFirestore
    ) {
        this._allowedDropForMovingNodeToNode.set("folder", ["folder", "project"]);
        this._allowedDropForMovingNodeToNode.set("search", ["folder", "project"]);
        this._allowedDropForMovingNodeToNode.set("search-parameters", ["search", "project"]);
        this._allowedDropForMovingNodeToNode.set("project", []);
    }

    allowedDropForMovingNode(nodeType: ContextNodeType): ContextNodeType[] {
        return this._allowedDropForMovingNodeToNode.get(nodeType) || [];
    }

    nodes(nodeIds: string[], options = { listen: true }): Observable<ContextNode[]> {
        if (nodeIds && nodeIds.length > 0) {
            return combineLatest(nodeIds.map((id) => this.node(id, options))).pipe(
                map((nodes) => nodes.filter((n) => n).sort((a, b) => {
                    if (a.created && b.created) {
                        return b.created.getTime() - a.created.getTime();
                    }
                    return -1;
                }))
            );
        } else {
            return of([]);
        }
    }

    node(id: string, options = { listen: true }): Observable<ContextNode> {
        if (options.listen) {
            return this.db
                .collection("contextNodes")
                .doc(id)
                .snapshotChanges()
                .pipe(
                    map((doc) => {
                        if (doc.payload.exists) {
                            const data: any = doc.payload.data();
                            return this.contextNodeDtoToContextNode({ ...data, id });
                        } else {
                            return undefined;
                        }
                    })
                );
        } else {
            return this.db
                .collection("contextNodes")
                .doc(id)
                .get()
                .pipe(
                    map((doc) => {
                        if (doc && doc.exists) {
                            const data: any = doc.data();
                            return this.contextNodeDtoToContextNode({ ...data, id });
                        } else {
                            return undefined;
                        }
                    })
                );
        }
    }

    removeNodeFromNodeView(nodeId: string): Observable<void> {
        return this.db
            .collection("contextViews")
            .doc(this.authService.loggedInUser.id)
            .get()
            .pipe(
                switchMap((doc) => {
                    let childrenRoot = (doc.data() as any).children || [];
                    return from(
                        this.db
                            .collection("contextViews")
                            .doc(this.authService.loggedInUser.id)
                            .update({
                                children: [...new Set(childrenRoot.filter((nId) => nId !== nodeId))],
                            })
                    ).pipe(map((_) => undefined));
                })
            );
    }

    addFolderToProjectRoot(projectId: string, folderName = "Dossier"): Observable<{
        parent: ContextNode;
        child: ContextNode;
    }> {
        return this.projectNodes(projectId).pipe(switchMap(contextNodes => {
            if (contextNodes[0]) {
                return this.addChild(contextNodes[0], "folder", {}, folderName)
            }
        }))
    }


    moveNodeToNode(nodeToMoveId: string, destinationNodeId: string): Observable<MoveNodeEventResponse> {
        if (nodeToMoveId === destinationNodeId) {
            //move node in same node ?
            return of({ success: false });
        }

        return forkJoin([
            this.node(nodeToMoveId, { listen: false }),
            this.node(destinationNodeId, { listen: false }),
        ]).pipe(
            switchMap((nodes) => {
                const nodeToMove = nodes[0];
                const destinationNode = nodes[1];
                if (!this.isMoveNodeToNodeAllowed(nodeToMove, destinationNode)) {
                    return of({ success: false });
                }

                return this.node(nodeToMove.parentNodeId, { listen: false }).pipe(
                    switchMap((nodeToMoveParent) => {
                        return forkJoin([
                            this.editNode(nodeToMove.change({ parentNodeId: destinationNodeId })),
                            this.editNode(nodeToMoveParent.removeChildNode(nodeToMove.id)),
                            this.editNode(destinationNode.addChildNode(nodeToMoveId)),
                        ]);
                    }),
                    map((_) => {
                        return { success: true };
                    })
                );
            })
        );
    }

    moveFormToNode(
        formId: string,
        sourceNodeId: string,
        destinationNodeId: string,
        destinationProjectId: string
    ): Observable<MoveNodeEventResponse> {

        if (sourceNodeId === destinationNodeId) {
            //move form in same node ?
            return of({ success: false });
        }

        return forkJoin([
            this.node(sourceNodeId, { listen: false }),
            this.node(destinationNodeId, { listen: false }),
            this.formService.form(formId, { listen: false }).pipe(switchMap(f => this.formService.saveACopyInProject(f, destinationProjectId)))
        ]).pipe(
            switchMap((nodes) => {
                const sourceNode = nodes[0];
                const destinationNode = nodes[1];
                const newForm = nodes[2];
                const newFormId = newForm.id;
                if (!this.isMoveFormToNodeAllowed(destinationNode)) {
                    return of({ success: false });
                }

                return forkJoin([
                    this.editNode(destinationNode.addFormIds([newFormId])),
                ]).pipe(
                    map((_) => {
                        return { success: true };
                    })
                );
            })
        );
    }

    private isMoveNodeToNodeAllowed(nodeToMove: ContextNode, destinationNode: ContextNode): boolean {
        const allowedDroppedType = this._allowedDropForMovingNodeToNode.get(nodeToMove.type);
        if (allowedDroppedType) {
            return allowedDroppedType.includes(destinationNode.type);
        } else {
            return false;
        }
    }

    private isMoveFormToNodeAllowed(destinationNode: ContextNode): boolean {
        return destinationNode.type === "folder" || destinationNode.type === "project";
    }


    projectNodes(projectId: string, options = { listen: false }): Observable<ContextNode[]> {
        if (options.listen) {
            return this.db
                .collection("contextNodes", (ref) => {
                    return ref.where("contextData.projectId", "==", projectId);
                })
                .snapshotChanges()
                .pipe(
                    map((docs) => {
                        return docs.map((doc) => {
                            const data: any = doc.payload.doc.data();
                            return this.contextNodeDtoToContextNode({ ...data, id: doc.payload.doc.id });
                        });
                    })
                );
        } else {

            return this.db
                .collection("contextNodes", (ref) => {
                    return ref.where("contextData.projectId", "==", projectId);
                })
                .get()
                .pipe(
                    map((docs) => {
                        return docs.docs.map((doc) => {
                            const data: any = doc.data();
                            return this.contextNodeDtoToContextNode({ ...data, id: doc.id });
                        });
                    })
                );
        }
    }

    createReportForNode(node: ContextNode): Observable<ContextNode> {

        return this.contactService.searchContacts(
            SearchDefinition.DEFAULT_SEARCH_DEFINITION_NODE(node.id),
            new SearchState({ limit: 9999 })
        ).pipe(
            switchMap(contactResults => {
                return this.reportService.createReport(contactResults.contactResults.map(cr => cr.contactId)).pipe(
                    take(1),
                    switchMap((rId) => {
                        return this.paneReferenceService
                            .addPane(
                                new Pane({
                                    type: "report-edit",
                                    contextNodeId: node.id,
                                    projectId: node.contextData?.projectId,
                                    contextData: { reportId: rId },
                                })
                            )
                            .pipe(
                                switchMap(() => {
                                    return this.editNode(node.addReportIds([rId]));
                                })
                            );
                    })
                );
            })
        );
    }

    createReportForNodeId(nodeId?: string): Observable<ContextNode> {
        if (nodeId) {

            return this.node(nodeId, { listen: false }).pipe(switchMap((n) => this.createReportForNode(n)));
        } else {

        }
    }

    searchParametersNodeForProject(projectId: string): Observable<ContextNode[]> {
        return this.projectNodes(projectId).pipe(
            switchMap((nodes) => {
                if (nodes.length > 0) {
                    return this.db
                        .collection("contextNodes", (ref) => {
                            return ref
                                .where("parentNodeId", "==", nodes[0].id)
                                .where("type", "==", "search-parameters");
                        })
                        .snapshotChanges()
                        .pipe(
                            map((docs) => {
                                return docs.map((doc) => {
                                    const data: any = doc.payload.doc.data();
                                    return this.contextNodeDtoToContextNode({ ...data, id: doc.payload.doc.id });
                                });
                            })
                        );
                }
                return of([]);
            })
        );
    }

    nodeForSearchParameter(searchParamId: string, options?: { listen: boolean }): Observable<ContextNode> {
        if (options && options.listen) {
            return this.db
                .collection("contextNodes", (ref) => {
                    return ref.where("contextData.searchContextId", "==", searchParamId);
                })
                .snapshotChanges()
                .pipe(
                    map((docs) => {
                        const doc = docs && docs.length > 0 ? docs[0] : null;
                        if (doc) {
                            const data: any = doc.payload.doc.data();
                            return this.contextNodeDtoToContextNode({ ...data, id: doc.payload.doc.id });
                        }
                        return undefined;
                    })
                );
        } else {
            return this.db
                .collection("contextNodes", (ref) => {
                    return ref.where("contextData.searchContextId", "==", searchParamId);
                })
                .get()
                .pipe(
                    map((docs) => {
                        const doc = docs.docs && docs.docs.length > 0 ? docs.docs[0] : null;
                        if (doc) {
                            const data: any = doc.data();
                            return this.contextNodeDtoToContextNode({ ...data, id: doc.id });
                        }
                        return undefined;
                    })
                );
        }
    }

    assignFormsToProjectNode(projectId: string, formIds: string[]): Observable<void> {
        return this.projectNodes(projectId).pipe(
            switchMap((nodes) => {
                return forkJoin(nodes.map((node) => this.editNode(node.addFormIds(formIds))));
            }),
            map((_) => undefined)
        );
    }

    linkNodesToNodeView(nodeIds: string[], userId?: string): Observable<void> {
        return this.db
            .collection("contextViews")
            .doc(userId ? userId : this.authService.loggedInUser.id)
            .get()
            .pipe(
                switchMap((doc) => {

                    let childrenRoot = (doc.data() as any)?.children || [];
                    childrenRoot = [...childrenRoot, ...nodeIds];
                    console.log(nodeIds, userId)
                    return from(
                        this.db
                            .collection("contextViews")
                            .doc(this.authService.loggedInUser.id)
                            .update({ children: [...new Set(childrenRoot)] })
                    );
                })
            );
    }

    private createAndAddNode(
        type: ContextNodeType,
        parentNodeId?: string,
        contextData?: ContextData,
        name?: string
    ): Observable<ContextNode> {
        const id = this.db.createId();
        const node = new ContextNode({
            id,
            name: name ? name : this.nameForNewNode(type),
            type,
            created: new Date(),
            contextData: contextData || null,
        });

        return this.addNode(node, parentNodeId);
    }

    private addNode(node: ContextNode, parentNodeId: string): Observable<ContextNode> {
        node = node.change({
            parentNodeId,
            createdBy: this.db.collection("users").doc(this.authService.loggedInUser.id).ref,
        });

        if (!node.id) {
            node = node.change({ id: this.db.createId() });
        }
        return from(this.db.collection("contextNodes").doc(node.id).set(this.contextNodeToContextNodeDTO(node))).pipe(
            map((_) => {
                return node;
            })
        );
    }

    private nameForNewNode(type: ContextNodeType): string {
        switch (type) {
            case "folder":
                return "Dossier";
            case "search":
                return "Recherche";
            case "project":
                return "Projet";
            default:
                return "";
        }
    }

    editNode(node: ContextNode): Observable<ContextNode> {
        return from(
            this.db.collection("contextNodes").doc(node.id).update(this.contextNodeToContextNodeDTO(node))
        ).pipe(
            map((_) => {
                return node;
            })
        );
    }

    addChild(
        parent: ContextNode,
        childType: ContextNodeType,
        contextData?: ContextData,
        name?: string
    ): Observable<{ parent: ContextNode; child: ContextNode }> {
        return this.createAndAddNode(childType, parent.id, contextData, name).pipe(
            switchMap((childNode) => {
                return this.editNode(parent.addChildNode(childNode.id)).pipe(
                    map((p) => {
                        return { parent: p, child: childNode };
                    })
                );
            })
        );
    }

    addChilren(
        parent: ContextNode,
        contextNodes: ContextNode[]
    ): Observable<{ parent: ContextNode; children: ContextNode[] }> {
        return forkJoin(contextNodes.map((n) => this.addNode(n, parent.id))).pipe(
            switchMap((nodes) => {
                return this.editNode(parent.addChildrenNodes(nodes.map((n) => n.id))).pipe(
                    map((p) => {
                        return { parent: p, children: nodes };
                    })
                );
            })
        );
    }

    contextNodeToContextNodeDTO(contextNode: ContextNode): ContextNodeDTO {
        return {
            id: contextNode.id,
            name: contextNode.name,
            color: contextNode.color,
            type: contextNode.type,
            parentNodeId: contextNode.parentNodeId || null,
            children: contextNode.children || null,
            contextData: contextNode.contextData || null,
            created: contextNode.created || null,
            createdBy: contextNode.createdBy || null,
        };
    }

    contextNodeDtoToContextNode(data: ContextNodeDTO): ContextNode {
        return new ContextNode({
            id: data.id,
            name: data.name || "",
            color: data.color || "#333333",
            type: data.type,
            contextData: data.contextData || null,
            parentNodeId: data.parentNodeId || null,
            children: data.children || [],
            created: data.created ? data.created.toDate() : null,
            createdBy: data.createdBy || null,
        });
    }

    removeNode(node: ContextNode): Observable<void> {
        let obs: Observable<void>;
        if (node.parentNodeId) {
            obs = this.node(node.parentNodeId, { listen: false }).pipe(
                switchMap((parentNode) => {
                    if (parentNode) {
                        return this.editNode(parentNode.removeChildNode(node.id));
                    }
                    return of({});
                }),
                map(() => undefined)
            );
        } else {
            obs = this.removeNodeFromNodeView(node.id);
        }

        return obs.pipe(
            switchMap((_) => {
                if (node.children && node.children.length > 0) {
                    return this.deleteChildNodes(node).pipe(switchMap((_) => this.deleteNode(node)));
                } else {
                    return this.deleteNode(node);
                }
            }),
            map((_) => undefined)
        );
    }

    private deleteChildNodes(node: ContextNode): Observable<void> {
        if (node.children && node.children.length > 0) {
            return this.nodes(node.children).pipe(
                switchMap((nodes) =>
                    forkJoin(
                        nodes.map((n) =>
                            this.deleteChildNodes(n).pipe(
                                switchMap((_) => {
                                    return this.db.collection("contextNodes").doc(n.id).delete();
                                })
                            )
                        )
                    )
                ),
                map((_) => undefined)
            );
        }
        return this.deleteNode(node);
    }

    private deleteNode(node: ContextNode): Observable<void> {
        return from(this.db.collection("contextNodes").doc(node.id).delete());
    }

    addRootElement(type: ContextNodeType, contextData?: ContextData, name?: string): Observable<ContextNode> {
        return this.createAndAddNode(type, null, contextData, name).pipe(
            switchMap((n) => {
                return this.db
                    .collection("contextViews")
                    .doc(this.authService.loggedInUser.id)
                    .get()
                    .pipe(
                        switchMap((doc) => {
                            let childrenRoot = (doc.data() as any)?.children || [];
                            childrenRoot = [...childrenRoot, n.id];
                            if (doc.exists) {
                                return from(
                                    this.db
                                        .collection("contextViews")
                                        .doc(this.authService.loggedInUser.id)
                                        .update({ children: [...new Set(childrenRoot)] })
                                ).pipe(map((_) => n));
                            } else {
                                return from(
                                    this.db
                                        .collection("contextViews")
                                        .doc(this.authService.loggedInUser.id)
                                        .set({ children: [...new Set(childrenRoot)] })
                                ).pipe(map((_) => n));
                            }
                        })
                    );
            })
        );
    }

    nodeView(options = { listen: true }): Observable<ContextNode[]> {
        if (this.authService.isLoggedIn) {
            const doc = this.db.collection("contextViews").doc(this.authService.loggedInUser.id);
            if (options && options.listen === true) {
                return doc.snapshotChanges().pipe(
                    switchMap((snapshot) => {
                        const data: any = snapshot.payload.data();
                        if (snapshot.payload.exists && data.children && data.children.length > 0) {
                            return this.nodes(data.children);
                        }
                        return of([]);
                    })
                );
            } else {
                return doc.get().pipe(
                    switchMap((doc) => {
                        const data: any = doc.data();
                        if (data.children && data.children.length > 0) {
                            return this.nodes(data.children, { listen: false });
                        }
                        return of([]);
                    })
                );
            }
        }
    }
}
interface ContextNodeDTO {
    id: string;
    name: string;
    color: string;
    type: ContextNodeType;
    contextData: ContextData;
    parentNodeId: string;
    children: string[];
    created: any;
    createdBy: DocumentReference;
}

export type OperationForContextNode = "remove";

export interface MoveNodeEventResponse {
    success: boolean;
}
