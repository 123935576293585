import { TranslatableLabel } from "../../services/data-catalog.service";
import { FormElement } from "./form-element/form-element";

interface Builder {
    id?: string;
    name?: TranslatableLabel;
    formElements?: FormElement[];
    order?: number;
    optional?: boolean;
    deleted?: boolean;
    isConscentModule?: boolean;
    isMandatoryValidationModule?: boolean;
}

export class FormModule {
    private _id: string;
    private _name: TranslatableLabel;
    private _formElements: FormElement[];
    private _order: number;
    private _optional: boolean;
    private _deleted: boolean;
    private _isConscentModule: boolean;
    private _isMandatoryValidationModule: boolean;

    constructor(builder: Builder) {
        this._id = builder.id;
        this._name = builder.name || {};
        this._formElements = builder.formElements || [];
        this._order = Number.isInteger(builder.order) ? builder.order : Infinity;
        this._optional = builder.optional === true ? true : false;
        this._deleted = builder.deleted === true ? true : false;
        this._isConscentModule = builder.isConscentModule === true ? true : false;
        this._isMandatoryValidationModule = builder.isMandatoryValidationModule === true ? true : false;
    }

    get id(): string {
        return this._id;
    }
    get name(): TranslatableLabel {
        return this._name;
    }
    get formElements(): FormElement[] {
        return this._formElements;
    }
    get activeFormElements(): FormElement[] {
        return this._formElements.filter((fe) => !fe.deleted);
    }
    get order(): number {
        return this._order;
    }
    get optional(): boolean {
        return this._optional;
    }
    get deleted(): boolean {
        return this._deleted;
    }
    get isConscentModule(): boolean {
        return this._isConscentModule;
    }
    get isMandatoryValidationModule(): boolean {
        return this._isMandatoryValidationModule;
    }

    change(builder: Builder): FormModule {
        return new FormModule({
            id: this.id,
            name: this._name,
            formElements: this._formElements,
            order: this._order,
            optional: this.optional,
            deleted: this.deleted,
            isConscentModule: this._isConscentModule,
            isMandatoryValidationModule: this.isMandatoryValidationModule,
            ...builder,
        });
    }

    hasFormElement(formElementName: string): boolean {
        return this._formElements.find((fe) => fe.name === formElementName) ? true : false;
    }

    changeFormElement(formElement: FormElement): FormModule {
        return this.change({
            formElements: this._formElements.map((fe) => {
                if (fe.id === formElement.id) {
                    return formElement;
                } else {
                    return fe;
                }
            }),
        });
    }

    deleteFormElement(formElementId: string): FormModule {
        return this.change({
            formElements: this._formElements.map((fe) => (fe.id === formElementId ? fe.change({ deleted: true }) : fe)),
        });
    }

    reactivateFormElement(formElementId: string): FormModule {
        return this.change({
            formElements: this._formElements.map((fe) =>
                fe.id === formElementId ? fe.change({ deleted: false }) : fe
            ),
        });
    }

    addFormElementAtIndex(formElement: FormElement, index: number): FormModule {
        const formElements = [...this.formElements];
        formElements.splice(index, 0, formElement);
        return this.change({ formElements });
    }
}
