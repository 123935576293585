import { BlobServiceClient, BlockBlobParallelUploadOptions } from '@azure/storage-blob';
import { forkJoin, Observable } from 'rxjs';
import { getMimeTypeFromExtension } from 'src/app/core/functions';

import { Asset, UploadFileResponse } from './file-upload.service';

export class FileUploadConnector {
    private _account = "castingdbdevsa";
    private _containerName: "fileinputs" | "input-videos" = "fileinputs";
    private _sasKey;

    private _blobServiceClient: BlobServiceClient;

    constructor(container?: "fileinputs" | "input-videos") {

        if (container) {
            this._containerName = container;
        }

        if (this._containerName === "input-videos") {
            this._sasKey = "si=*&spr=https&sv=2022-11-02&sr=c&sig=tRqHfXoN3%2FCndKal%2Fr9pjDn2HROnpAy9rQMtKWyh45M%3D"
        } else {
            this._sasKey = "st=2022-08-02T18:03:24Z&se=2027-07-02T02:03:24Z&si=*&spr=https&sv=2021-06-08&sr=c&sig=IAxi1JRVxgt%2FVY9NucR5KSmb1QKUBkNkeXh3ecSOEEQ%3D";
        }

        this._blobServiceClient = new BlobServiceClient(
            `https://${this._account}.blob.core.windows.net?${this._sasKey}`, undefined, { retryOptions: { maxTries: 15 } }
        );
    }

    uploadFile(file: File): Observable<UploadFileResponse> {
        const containerClient = this._blobServiceClient.getContainerClient(this._containerName);
        let result = file.name.split(".");
        let extention;
        if (result.length > 1) {
            extention = `.${result.pop()}`;
        } else {
            extention = "";
        }
        const filename = `${result.join("").replace(/[^a-z0-9]/gi, "_").toLowerCase()}${extention}`;
        const blobName = `${Math.random().toString(20).substr(2, 6)}-${filename}`;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        const finalUrl = `https://${this._account}.blob.core.windows.net/${this._containerName}/${blobName}`;
        const returnedAsset: Asset = { assetName: filename, file, finalUrl };
        return new Observable<UploadFileResponse>((subscriber) => {
            subscriber.next({
                asset: returnedAsset,
                loadedBytes: 0,
            });
            const blobOptions: BlockBlobParallelUploadOptions = {
                blobHTTPHeaders: {
                    blobContentType: getMimeTypeFromExtension(extention)
                },
                onProgress: (data) => {
                    subscriber.next({
                        asset: returnedAsset,
                        loadedBytes: data.loadedBytes,
                    });
                }
            };

            blockBlobClient
                .uploadData(returnedAsset.file, blobOptions)
                .then((blobResponse) => {
                    subscriber.next({
                        asset: returnedAsset,
                        loadedBytes: file.size,
                    });
                    subscriber.complete();
                });
        });
    }

    uploadFiles(files: File[]): Observable<UploadFileResponse[]> {
        return forkJoin(files.map((file) => this.uploadFile(file)));
    }
}

// export class FileUploadConnector {
//     private _account = "castingdbdevsa";
//     private _containerName: "fileInputs" | "input-videos";
//     private _sasKey;
//     private _blobServiceClient: BlobServiceClient;

//     constructor(containerName: "fileInputs" | "input-videos" = "fileInputs") {
//         // tslint:disable-next-line:max-line-length
//         this._containerName = containerName;

//         if (this._containerName === "fileInputs") {
//             this._sasKey = "st=2022-08-02T18:03:24Z&se=2027-07-02T02:03:24Z&si=*&spr=https&sv=2021-06-08&sr=c&sig=IAxi1JRVxgt%2FVY9NucR5KSmb1QKUBkNkeXh3ecSOEEQ%3D";
//         } else if (this._containerName === "input-videos") {
//             this._sasKey = "sp=r&st=2024-07-01T17:31:47Z&se=2029-07-02T01:31:47Z&spr=https&sv=2022-11-02&sr=c&sig=3HUA%2FKjpPravxBQCuSEQGGMzR4XqIU8dLdHRfNr7sQE%3D";
//         }
//         this._blobServiceClient = new BlobServiceClient(
//             `https://${this._account}.blob.core.windows.net?${this._sasKey}`
//         );
//     }

//     uploadFile(file: File): Observable<UploadFileResponse> {
//         const containerClient = this._blobServiceClient.getContainerClient(this._containerName);
//         let result = file.name.split(".");
//         let extention;
//         if (result.length > 1) {
//             extention = `.${result.pop()}`;
//         } else {
//             extention = "";
//         }

//         const filename = `${result.join("").replace(/[^a-z0-9]/gi, "_").toLowerCase()}${extention}`;
//         const blobName = `${Math.random().toString(20).substr(2, 6)}-${filename}`;
//         const blockBlobClient = containerClient.getBlockBlobClient(blobName);

//         const finalUrl = `https://${this._account}.blob.core.windows.net/${this._containerName}/${blobName}`;
//         const returnedAsset: Asset = { assetName: filename, file, finalUrl };
//         return new Observable<UploadFileResponse>((subscriber) => {
//             subscriber.next({
//                 asset: returnedAsset,
//                 loadedBytes: 0,
//             });
//             const blobOptions: BlockBlobParallelUploadOptions = {
//                 blobHTTPHeaders: {
//                     blobContentType: getMimeTypeFromExtension(extention)
//                 },
//                 onProgress: (data) => {
//                     subscriber.next({
//                         asset: returnedAsset,
//                         loadedBytes: data.loadedBytes,
//                     });
//                 }
//             };

//             blockBlobClient
//                 .uploadData(returnedAsset.file, blobOptions)
//                 .then((blobResponse) => {
//                     subscriber.complete();
//                 });
//         });
//     }

//     uploadFiles(files: File[]): Observable<UploadFileResponse[]> {
//         return forkJoin(files.map((file) => this.uploadFile(file)));
//     }
// }
