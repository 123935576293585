<div class="attribute" *ngIf="attrLabel$ | async as attrLabel">
    <span *ngIf="attrLabel.attributeId.startsWith('recruitment_'); else notForm" class="label-attribute"
        >Formulaire :
        <span class="no-margin action-link" (click)="openForm(attrLabel.attributeId)"
            ><diversite-attribute-value
                [attributeName]="attrLabel.attributeId"
                [value]="attrLabel.attributeId"
            ></diversite-attribute-value></span
    ></span>

    <ng-template #notForm>
        <span class="label-attribute"
            ><diversite-attribute-label [attributeLabel]="attrLabel"></diversite-attribute-label>
        </span>
        <diversite-attribute-value
            [isSearched]="isSearched"
            [attributeLabel]="attrLabel"
            [value]="attrLabel.hasOptionLabels() ? value : stringValue"
        ></diversite-attribute-value>
    </ng-template>
</div>
