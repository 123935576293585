<div [class.minimized]="minimized" class="selection-container" *ngIf="selectionLength > 0">
    <div *ngIf="toCopyCPs && toCopyCPs.length > 0" class="progress top">
        <progress [value]="(toCopyCPs.length / selectionLength) * 100" max="100"></progress>
    </div>
    <div class="selection-description">
        <div class="text-up-selection">
            <strong>{{ selectionLength }}</strong> contact(s) selectionné(s)
        </div>
        <div class="actions">
            <clr-tooltip>
                <cds-icon (click)="openCreateFolder()" clrTooltipTrigger shape="folder"></cds-icon>
                <clr-tooltip-content [clrPosition]="'top-left'" [clrSize]="'sm'">
                    Créer un dossier
                </clr-tooltip-content>
            </clr-tooltip>

            <clr-tooltip>
                <cds-icon (click)="openSelectionPane()" clrTooltipTrigger shape="pop-out"></cds-icon>
                <clr-tooltip-content [clrPosition]="'top-left'" [clrSize]="'sm'"
                    >Ouvrir la sélection</clr-tooltip-content
                >
            </clr-tooltip>

            <clr-tooltip>
                <cds-icon
                    (click)="minimized = !minimized"
                    shape="angle"
                    clrTooltipTrigger
                    [direction]="direction"
                ></cds-icon>
                <clr-tooltip-content [clrPosition]="'top-left'" [clrSize]="'sm'">Minimisé / Ouvrir</clr-tooltip-content>
            </clr-tooltip>

            <clr-tooltip>
                <cds-icon (click)="clearSelection()" clrTooltipTrigger shape="times"></cds-icon>
                <clr-tooltip-content [clrPosition]="'top-left'" [clrSize]="'sm'"
                    >Fermer / Effacer la sélection</clr-tooltip-content
                >
            </clr-tooltip>
        </div>
    </div>
    <div class="contacts-container" [hidden]="minimized">
        <div class="contacts">
            <div class="contact" *ngFor="let contactData of contactObs; trackBy: trackById">
                <diversite-photo-cover
                    *ngIf="contactData.contactPerspectiveObs | async as cp"
                    [mode]="'cover'"
                    [url]="cp.contact.images[0]?.url"
                    [rotate]="cp.contact.images[0]?.rotate"
                >
                    <cds-icon (click)="remove(cp)" shape="times"></cds-icon
                ></diversite-photo-cover>
            </div>
            <div class="more" *ngIf="selectionLength > 10">
                <small>... plus {{ selectionLength - 10 }}</small>
            </div>
        </div>
        <div class="actions">
            <a class="action-link" (click)="copySelectionInfo()">Copier les infos</a>
            <a class="action-link" (click)="openDownloadPane()">Télécharger</a>
            <a class="action-link" (click)="createReport()">Créer un rapport</a>
            <a class="action-link" (click)="openMassEditPane()">Modifier les membres</a>
            <a
                class="action-link"
                (click)="openMergeMembersPane()"
                [class.disabled]="contactIds && contactIds.length !== 2"
                >Fusionner les membres</a
            >
        </div>
    </div>
</div>

<table class="hidden table-for-copy">
    <tr *ngFor="let contactPerspective of toCopyCPs; trackBy: trackById">
        <td>{{ contactPerspective.contact.idfig }}</td>
        <td>{{ contactPerspective.contact.fullname }}</td>
        <td>{{ contactPerspective.contact.email }}</td>
        <td>{{ contactPerspective.contact.mainPhoneLiteral }}</td>
        <td>{{ contactPerspective.contact.age }}</td>
        <td>{{ contactPerspective.contact.sex }}</td>
    </tr>
</table>
