import { DocumentReference } from "@angular/fire/compat/firestore";
import { slugify } from "src/app/core/functions";

import { TranslatableLabel } from "../../services/data-catalog.service";
import { FormElement } from "./form-element/form-element";
import { FormOption } from "./form-element/form-option";
import { FormModule } from "./form-module";

export interface Builder {
    id?: string;
    name?: TranslatableLabel;
    projectId?: string;
    modules?: FormModule[];
    isPublished?: boolean;
    logo?: string;
    endText?: TranslatableLabel;
    isTemplate?: boolean;
    variables?: Variable[];
    languages?: string[];
    users?: DocumentReference[];
    archived?: boolean;
    deleted?: boolean;
    lastUpdated?: number;
    created?: Date;
}
export class Form {
    private _id: string;
    private _name: TranslatableLabel;
    private _projectId: string;
    private _modules: FormModule[];
    private _isPublished: boolean;
    private _logo: string;
    private _endText: TranslatableLabel;
    private readonly _users: DocumentReference[];
    private _isTemplate: boolean;
    private _variables: Variable[];
    private _languagues: string[];
    private _archived: boolean;
    private _deleted: boolean;
    private _lastUpdated: number;
    private _created: Date;

    constructor(builder: Builder) {
        this._id = builder.id;
        this._name = builder.name;
        this._projectId = builder.projectId || null;
        this._endText = builder.endText || {};
        this._modules = builder.modules || [];
        this._isPublished = builder.isPublished === true ? true : false;
        this._logo = builder.logo;
        this._users = builder.users || [];
        this._isTemplate = builder.isTemplate === true ? true : false;
        this._variables = builder.variables ? builder.variables : [];
        this._languagues = builder.languages || [];
        this._archived = builder.archived === true ? true : false;
        this._deleted = builder.deleted === true ? true : false;
        this._lastUpdated = builder.lastUpdated || 0;
        this._created = builder.created || null;
    }

    get id(): string {
        return this._id;
    }
    get name(): TranslatableLabel {
        return this._name;
    }
    get projectId(): string {
        return this._projectId;
    }
    get isPublished(): boolean {
        return this._isPublished;
    }
    get modules(): FormModule[] {
        return this._modules;
    }
    get logo(): string {
        return this._logo;
    }
    get endText(): TranslatableLabel {
        return this._endText;
    }
    get isTemplate(): boolean {
        return this._isTemplate;
    }
    get variables(): Variable[] {
        return this._variables;
    }
    get languages(): string[] {
        return this._languagues;
    }
    get users(): DocumentReference[] {
        return this._users;
    }
    get archived(): boolean {
        return this._archived;
    }
    get deleted(): boolean {
        return this._deleted;
    }
    get lastUpdated(): number {
        return this._lastUpdated || null;
    }
    get created(): Date {
        return this._created;
    }

    hasLanguage(lang: string): boolean {
        return this.languages.includes(lang);
    }

    change(builder: Builder): Form {
        return new Form({
            id: this._id,
            name: this._name,
            projectId: this._projectId,
            modules: this._modules,
            isPublished: this._isPublished,
            logo: this._logo,
            endText: this._endText,
            isTemplate: this._isTemplate,
            variables: this._variables,
            languages: this._languagues,
            archived: this._archived,
            deleted: this._deleted,
            lastUpdated: this._lastUpdated,
            created: this._created,
            ...builder,
            users: this._users,
        });
    }

    setDefaultValueForNewLang(fromLang: string, toLang: string): Form {
        return this.change({
            modules: this._modules.map((module) =>
                module.change({
                    formElements: module.formElements.map((fe) => {
                        if (fe.isInput()) {
                            let returningFe: any = fe;
                            if (returningFe.label && (!returningFe.label[toLang] || returningFe.label[toLang] === "")) {
                                const l = { ...returningFe.label };
                                l[toLang] = l[fromLang];
                                returningFe = returningFe.change({ label: l }) as FormElement;
                            }

                            if (returningFe.options && Array.isArray(returningFe.options)) {
                                returningFe = returningFe.change({
                                    options: returningFe.options.map((option: FormOption) => {
                                        if (
                                            option &&
                                            option.label &&
                                            (!option.label[toLang] || option.label[toLang] === "")
                                        ) {
                                            const l = { ...option.label };
                                            l[toLang] = l[fromLang] || "";
                                            return { ...option, label: l };
                                        }
                                        return option;
                                    }),
                                });
                            }

                            return returningFe;
                        }
                        return fe;
                    }),
                })
            ),
        });
    }

    publish(): Form {
        if (!this.isTemplate) {
            return this.change({ isPublished: true });
        } else {
            throw new Error("Can't publish template");
        }
    }

    formElementByName(formElementName: string): FormElement {
        const formElements: FormElement[] = this.modules
            .map((m) => m.formElements)
            .reduce((acc, val) => acc.concat(val), []);
        return formElements.find((fe) => fe.name === formElementName);
    }

    formElementById(id: string): FormElement {
        const formElements: FormElement[] = this.modules
            .map((m) => m.formElements)
            .reduce((acc, val) => acc.concat(val), []);
        return formElements.find((fe) => fe.id === id);
    }

    changeFormElement(fe: FormElement): Form {
        return this.change({
            modules: this.modules.map((m) => (m.hasFormElement(fe.name) ? m.changeFormElement(fe) : m)),
        });
    }

    module(moduleId: string): FormModule {
        return this._modules.find((m) => m.id === moduleId);
    }

    changeModule(module: FormModule): Form {
        return this.change({ modules: this._modules.map((m) => (m.id === module.id ? module : m)) });
    }

    changeFormElementPosition(structures: ElementStructure[]): Form {
        const formElements: FormElement[] = this.modules
            .map((m) => m.formElements)
            .reduce((acc, val) => acc.concat(val), []);
        return this.change({
            modules: structures.map((structure) =>
                this.modules
                    .find((m) => m.id === structure.moduleId)
                    .change({
                        formElements: structure.formElementIds.map((feId) => formElements.find((f) => f.id === feId)),
                    })
            ),
        });
    }

    addVariable(v: Variable): Form {
        return this.change({ variables: [...this.variables, v] });
    }

    removeVariable(vid: string): Form {
        return this.change({ variables: this.variables.filter((v) => v.id !== vid) });
    }

    activeFormElements(): FormElement[] {
        const formElements = this.modules
            .filter((m) => !m.deleted && !m.isConscentModule)
            .map((m) => m.formElements.filter((fe) => fe && !fe.deleted))
            .reduce((acc, val) => acc.concat(val), []) as FormElement[];
        return formElements;
    }

    conscentFormElements(): FormElement[] {
        return this.modules
            .filter((m) => !m.deleted && m.isConscentModule)
            .map((m) => m.formElements.filter((fe) => !fe.deleted))
            .reduce((acc, val) => acc.concat(val), []) as FormElement[];
    }

    toValidateFormElements(): FormElement[] {
        return this.modules
            .filter((m) => !m.deleted && m.isMandatoryValidationModule)
            .map((m) => m.formElements.filter((fe) => !fe.deleted))
            .reduce((acc, val) => acc.concat(val), []) as FormElement[];
    }

    formElements(): FormElement[] {
        const formElements: FormElement[] = this.modules
            .map((m) => m.formElements)
            .reduce((acc, val) => acc.concat(val), []);
        return formElements;
    }
}

export class Variable {
    private _id: string;
    private _name: string;
    private _slug: string;

    constructor(builder: { id: string; name: string }) {
        this._id = builder.id;
        this._name = builder.name;
        this._slug = slugify(builder.name).toUpperCase();
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get slug(): string {
        return this._slug;
    }

    change(builder: { id: string; name: string }): Variable {
        return new Variable({
            id: this._id,
            name: this._name,
            ...builder,
        });
    }
}

export interface ElementStructure {
    moduleId: string;
    formElementIds: string[];
}
