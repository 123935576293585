import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { FormElement, FormElementInput } from "src/modules/diversite/model/form/form-element/form-element";

import { ResponseInput } from "../../model/response-input";

export const DEFAULT_OPTIONS: FormElementGenericOptions = {
    showLabel: true
};

@Component({
    selector: "fillout-form-element",
    templateUrl: "./form-element.component.html",
    styleUrls: ["./form-element.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormElementComponent implements OnInit, OnChanges {
    @Input() lang: string;
    @Input() formElement: FormElement | FormElementInput;
    @Input() value: any;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };

    @Output() responseChange = new EventEmitter<ResponseInput>();

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.formElement && changes.value && changes.value.currentValue) {
            if (changes.value.currentValue !== undefined && changes.value.currentValue !== "") {
                this.onResponseChange(changes.value.currentValue);
            }
        }
    }

    ngOnInit(): void { }

    onResponseChange(value: any): void {
        this.responseChange.emit({
            value,
            input: this.formElement as FormElementInput,
        });
    }

    tooltipShow(formElement: FormElement): boolean {
        return formElement.description[this.lang] && formElement.description[this.lang] !== "";
    }
}

export interface FormElementGenericOptions {
    showLabel: boolean;
}
