import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, combineLatest, concat, concatMap, filter, forkJoin, map, mergeMap, Observable, of, startWith, switchMap, take, tap } from 'rxjs';
import { guid } from 'src/app/core/functions';

import { Attribute, Contact } from '../model/contact';
import { SearchDefinition } from '../model/search-definition';
import { ContactResult, ElasticSearchService, SearchResultContacts } from './elasticsearch.service';
import { ContextNode } from '../model/context-node';
import { ContactService } from './contact.service';
import { ContextNodeService } from './context-node.service';
import { SystemRequestService } from 'src/app/services/system-request.service';
import { SearchState } from '../model/search-state';

@Injectable({
    providedIn: 'root'
})
export class ContactContextNodeAssignService {


    private contactsAssigned$ = new BehaviorSubject<ContactAssignedData>(null);
    private nodeContactsChanged$ = this.contactsAssigned$.pipe(
        switchMap(data => {
            if (data) {
                return this.systemRequestService.get(data.jobId).pipe(
                    filter(sr => sr.status === "success" || sr.status === "error"),
                    map(_ => data.nodeId)
                )
            } else {
                return of(null);
            }
        })
    );


    constructor(private contactService: ContactService, private contextNodeService: ContextNodeService, private systemRequestService: SystemRequestService) { }

    contactsForNodeId(nodeId: string, options = { listen: true }): Observable<Contact[]> {
        if (options?.listen === true) {
            return this.nodeContactsChanged$.pipe(
                filter(changedNodeId => !changedNodeId || changedNodeId === nodeId),
                switchMap(_ => {
                    return this.searchForNodeContacts(nodeId);
                })
            )
        } else {
            return this.searchForNodeContacts(nodeId);
        }
    }

    private searchForNodeContacts(nodeId: string): Observable<Contact[]> {
        const searchDef = SearchDefinition.DEFAULT_SEARCH_DEFINITION_NODE(nodeId);
        return this.contactService.searchContacts(
            SearchDefinition.DEFAULT_SEARCH_DEFINITION_NODE(nodeId),
            new SearchState({ limit: 0 })
        ).pipe(
            take(1),
            switchMap(search => {
                const total = search.total.value;
                const limit = 50;
                const pages = Math.ceil(total / limit);
                const pagesArray = [...Array(pages)].map((_, index) => { return index + 1 });
                let contacts = [];

                if (pagesArray.length > 0) {
                    return concat(...pagesArray.map(page => {
                        return this.contactService.searchContacts(
                            searchDef,
                            new SearchState({ limit, page })
                        ).pipe(take(1))
                    })).pipe(
                        switchMap(results => {
                            return forkJoin(results.contactResults.map(cr => this.contactService.contactById(cr.contactId)))
                        }),
                        map((cs: Contact[]) => {
                            contacts = [...contacts, cs].reduce((acc, val) => acc.concat(val), []);
                            return contacts
                        })
                    );
                }
                return of([]);
            })
        )


        // return this.contactService.searchContacts(
        //     SearchDefinition.DEFAULT_SEARCH_DEFINITION_NODE(nodeId),
        //     new SearchState({ limit: 9999 })
        // ).pipe(
        //     switchMap(contactResults => {
        //         if (contactResults.contactResults.length > 0) {
        //             return forkJoin(contactResults.contactResults.map((cr, index) => {
        //                 return this.contactService.contactById(cr.contactId);
        //             }));
        //         } else {
        //             return of([]);
        //         }
        //     })
        // );
    }

    assignSearchResultsToNode(searchDef: SearchDefinition, node: ContextNode, options = { wait: false }): Observable<ContextNode> {
        return this.contactService.massEditSearchDefinitionResults(
            searchDef,
            [{ attribute: `contextNode_${node.id}`, type: 'set', value: true }],
            options
        ).pipe(
            map(job => {
                return {
                    jobId: job.jobId,
                    contextNode: node
                }
            }),
            switchMap(data => {
                return this.contextNodeService.editNode(data.contextNode.change({
                    contextData: { ...data.contextNode.contextData, jobId: data.jobId }
                })).pipe(tap(n => {
                    this.contactsAssigned$.next({
                        nodeId: n.id,
                        jobId: n.contextData.jobId
                    })
                }))
            })
        );
    }

    assignContactsToNode(contactIds: string[], node: ContextNode, options = { wait: false }): Observable<ContextNode> {
        return this.contactService.massEditContactIds(
            contactIds,
            [{ attribute: `contextNode_${node.id}`, type: 'set', value: true }],
            options
        ).pipe(
            map(job => {
                return {
                    jobId: job.jobId,
                    contextNode: node
                }
            }),
            switchMap(data => {
                return this.contextNodeService.editNode(data.contextNode.change({
                    contextData: { ...data.contextNode.contextData, jobId: data.jobId }
                })).pipe(tap(n => {
                    this.contactsAssigned$.next({
                        nodeId: n.id,
                        jobId: n.contextData.jobId
                    })
                }))
            })
        );
    }


    unassignContactsFromNode(contactIds: string[], node: ContextNode, options = { wait: false }): Observable<ContextNode> {
        return this.contactService.massEditContactIds(
            contactIds,
            [{ attribute: `contextNode_${node.id}`, type: 'set', value: false }],
            options
        ).pipe(
            map(job => {
                return {
                    jobId: job.jobId,
                    contextNode: node
                }
            }),
            switchMap(data => {
                return this.contextNodeService.editNode(data.contextNode.change({
                    contextData: { ...data.contextNode.contextData, jobId: data.jobId }
                })).pipe(tap(n => {
                    this.contactsAssigned$.next({
                        nodeId: n.id,
                        jobId: n.contextData.jobId
                    })
                }))
            })
        );
    }


}

export interface ContactAssignedData {
    jobId: string;
    nodeId: string;
}