import { Injectable } from '@angular/core';
import { getDaysArray, guid, isDate, isNumber, isString, readableDate } from 'src/app/core/functions';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchGroup } from 'src/modules/diversite/model/search-group';
import { Occurance, SearchOperation } from 'src/modules/diversite/model/search-operation';
import { SearchState } from 'src/modules/diversite/model/search-state';
import { ModulesAttributesService } from 'src/modules/diversite/services/modules-attributes.service';

const HIGHLIGHTS = {
    highlight: {
        fields: {
            valueString: {
                pre_tags: ["<span style='background:yellow;'>"],
                post_tags: ["</span>"],
                force_source: true,
            },
        },
    },
};


const DEFAULT_PROFILE_ATTRIBUTE = [
    // "idfig",
    // "faceUrl",
    // "thumbnailUrl",
    // "identity_firstname",
    // "identity_lastname",
    // "identity_dateOfBirth"
];


@Injectable({
    providedIn: "root",
})
export class ElasticSearchMapperService {


    private _operandStrategies = new Map<SearchStrategy, (op: SearchOperation) => QueryObject>();

    constructor() {
        this._operandStrategies.set("createdAt", (op: SearchOperation) => { return this.strategyCreatedAt(op); });
        this._operandStrategies.set("fullname", (op: SearchOperation) => { return this.strategyFullname(op); });
        this._operandStrategies.set("idfig", (op: SearchOperation) => { return this.strategyIdfig(op); });
        this._operandStrategies.set("form", (op: SearchOperation) => { return this.strategyForm(op); })
        this._operandStrategies.set("affiliation", (op: SearchOperation) => { return this.strategyAffiliation(op); })
        this._operandStrategies.set("contactId", (op: SearchOperation) => { return this.strategyContactId(op); })
        this._operandStrategies.set("generic", (op: SearchOperation) => { return this.strategyGenericByOperator(op); })
    }
    // {
    //     has_child: {
    //         type: "attributedocument",
    //         query: {
    //             match_all: {},
    //         },
    //         inner_hits: { _source: true, size: 100 },
    //         min_children: `${1}`,
    //     },
    // }
    searchParamsToESQuery(
        departmentId: string,
        searchDef: SearchDefinition,
        searchState: SearchState = new SearchState({})
    ): any {
        let sort: any = [
            "_score"
        ];
        let s = {};
        s[searchState.sort.field] = { order: searchState.sort.direction };
        sort.push(s);

        const completeQuery: any = {
            size: searchState.limit,
            from: this.from(searchState.page, searchState.limit),
            track_total_hits: true,
            sort,
            query: {
                bool: {
                    must: [
                        ...this.mainMust(searchDef),
                        {
                            has_child: {
                                type: "attributedocument",
                                query: {
                                    term: { attribute: `department_${departmentId}` },
                                },
                            },
                        }
                    ],
                    must_not: [
                        ...this.excludeContactQueries(searchDef.excludeContactIds || []),
                        {
                            has_child: {
                                type: "attributedocument",
                                query: {
                                    term: { attribute: "suppressed" },
                                },
                            },
                        },
                        ...this.mustContactSearchParameters(searchDef.searchOperations, "must-not"),
                        // ...this.shouldContactSearchParameters(searchDef.searchOperations, "must-not"),
                    ],
                    should: [{
                        has_child: {
                            type: "attributedocument",
                            query: {
                                query_string: {
                                    // query: this.moduleService.allFields.map(attributeId => `(attribute:${attributeId})`).join(" OR "),
                                    query: DEFAULT_PROFILE_ATTRIBUTE.map(attributeId => `(attribute:${attributeId})`).join(" OR "),
                                    boost: 0
                                },
                            },
                            score_mode: "none",
                            inner_hits: { _source: true, size: 100 },
                        },

                    }]
                },
            },
        };

        return completeQuery;
    }


    private excludeContactQueries(contactIds: string[]): QueryObject[] {
        return contactIds.map((contactId) => {
            return {
                term: {
                    contactId,
                },
            };
        });
    }

    mainMust(searchDef: SearchDefinition): QueryObject[] {
        let musts: QueryObject[] = [];

        if (searchDef.searchGroup && searchDef.searchOperations.length > 0) {
            const mainSearchGroup = this.groupQueryObject(searchDef.searchGroup);
            if (mainSearchGroup) {
                musts.push(this.groupQueryObject(searchDef.searchGroup));
            }
        }

        if (searchDef.datasource) {
            musts.push(this.hasChild([{ term: { attribute: `datasource_${searchDef.datasource}` } }], 1));
        }

        if (searchDef.bookmarkSpec) {
            musts.push({
                bool: {
                    must: [{
                        has_child: {
                            type: "attributedocument",
                            query: {
                                match_all: {},
                            },
                            score_mode: "max",
                            min_children: `${1}`,
                        },
                    }, this.rangeObject("lastUpdatedAt", searchDef.bookmarkSpec.new ? { min: searchDef.bookmarkSpec.bookmark.date } : { max: searchDef.bookmarkSpec.bookmark.date })]
                }
            });
        }

        if (musts.length === 0) {
            musts.push({
                has_child: {
                    type: "attributedocument",
                    query: {
                        match_all: {},
                    },
                    min_children: `${1}`,
                },
            });
        }

        return musts;
    }

    private groupQueryObject(group: SearchGroup): QueryObject {
        const musts: QueryObject[] = [];
        const shoulds: QueryObject[] = [];
        const mustNots: QueryObject[] = [];
        if (!group.disabled) {
            if (group.searchGroups) {
                const ops = group.searchGroups.map((sg) => this.groupQueryObject(sg)).filter((_) => _);
                group.operator === "OR" ? shoulds.push(...ops) : musts.push(...ops);
            } else if (group.hasFullTextSearch() || (group.searchOperations && group.searchOperations.length > 0)) {
                const ops = this.searchGroupSearchOperations(group);

                mustNots.push(...this.mustNotContactSearchParameters(group.searchOperations, "must"));

                group.operator === "OR" ? shoulds.push(...ops) : musts.push(...ops);
            }

            if (musts.length > 0 || shoulds.length > 0 || mustNots.length > 0) {
                const query = {
                    bool: {},
                };
                if (musts.length > 0) {
                    query.bool["must"] = musts;
                }
                if (shoulds.length > 0) {
                    query.bool["should"] = shoulds;
                    query.bool["minimum_should_match"] = 1;
                }
                if (mustNots.length > 0) {
                    query.bool["must_not"] = mustNots;
                }
                return query;
            }
        }
    }

    private searchGroupSearchOperations(searchGroup: SearchGroup): QueryObject[] {
        const musts = [...this.mustContactSearchParameters(searchGroup.searchOperations, "must")];
        const ors = [...this.shouldContactSearchParameters(searchGroup.searchOperations, "must")];

        if (searchGroup.hasFullTextSearch()) {
            musts.push(this.fullTextSearchQueryObject(searchGroup));
        }

        if (ors.length > 0) {
            musts.push({
                bool: {
                    must: ors,
                },
            });
        }

        if (musts.length === 0) {
            musts.push({
                has_child: {
                    type: "attributedocument",
                    query: {
                        match_all: {},
                    },
                    min_children: `${1}`,
                },
            });
        }

        return musts;
    }

    private fullTextSearchQueryObject(searchGroup: SearchGroup): QueryObject {
        return {
            has_child: {
                type: "attributedocument",
                score_mode: "max",
                query: searchGroup.fullTextSearch.operator === "query" ? this.fullTextFuzzyQuery(searchGroup.fullTextSearch) : this.fullTextExactQuery(searchGroup.fullTextSearch),
                inner_hits: { ...HIGHLIGHTS, name: `fullttext-has-child-${searchGroup.id}` },
                min_children: `1`,
            },
        };
    }

    private fullTextFuzzyQuery(fullTextSearchOperation: SearchOperation): QueryObject {

        const boolOps = {
            should: this.shouldFullTextQuery(fullTextSearchOperation.value),
            minimum_should_match: 1,
        };

        if (fullTextSearchOperation.operand && fullTextSearchOperation.operand !== "") {
            boolOps["must"] = [{ term: { attribute: fullTextSearchOperation.operand } }];
        }

        return {
            bool: boolOps
        }
    }

    private shouldFullTextQuery(value: string): QueryObject[] {
        return [
            {
                query_string: {
                    query: value.split(" ").map(word => {
                        return `*${word.trim()}*`
                    }).join(' AND '),
                    default_field: "valueString",

                },
            },
            {
                query_string: {
                    query: value,
                    default_field: "valueString",

                },
            },
            {
                match: {
                    valueString: {
                        query: value,
                        operator: "AND"
                    }
                }
            },
        ]
    }

    private fullTextExactQuery(fullTextSearchOperation: SearchOperation): QueryObject {
        const valueOperation = {
            match_phrase: {
                valueString: fullTextSearchOperation.value
            }
        };
        if (fullTextSearchOperation.operand && fullTextSearchOperation.operand !== "") {
            return {
                bool: { must: [{ term: { attribute: fullTextSearchOperation.operand } }, valueOperation] }
            }
        }
        return valueOperation;
    }

    private mustContactSearchParameters(sops: SearchOperation[], occurance: Occurance): QueryObject[] {
        return [...this.mustAdvancedParams(sops, occurance), ...this.availabilityParams(sops, occurance)];
    }

    private shouldContactSearchParameters(sops: SearchOperation[], occurance: Occurance): QueryObject[] {
        return [...this.shouldSearchOperations(sops, occurance)];
    }

    private mustNotContactSearchParameters(sops: SearchOperation[], occurance: Occurance): QueryObject[] {
        return [...this.mustNotAdvancedParams(sops, occurance)];
    }

    private shouldSearchOperations(sops: SearchOperation[], occurance: Occurance): QueryObject[] {
        return [...this.shouldAdvancedParams(sops, occurance)];
    }
    private shouldAdvancedParams(sops: SearchOperation[], occurance: Occurance): QueryObject[] {
        if (sops && sops.length > 0) {
            const shouldArray: QueryObject[] = [
                ...sops
                    .filter((op) => this.isShouldSearchOperation(op, occurance))
                    .map((op) => {
                        return this.shouldOperationQueryBuilder(op);
                    })
                    .reduce((acc, val) => acc.concat(val), []),
            ];

            return shouldArray;
        }
        return [];
    }

    hasChild(queryObjects: QueryObject[], boost: number = 1, minChildren = 1): QueryObject {
        return {
            has_child: {
                type: "attributedocument",
                score_mode: "max",
                query: {
                    bool: { must: queryObjects },
                },
                inner_hits: { ...HIGHLIGHTS, name: `has-child-${guid()}` },
                boost,
                min_children: `${minChildren}`,
            },
        };
    }


    private availabilityParams(sops: SearchOperation[], occurance: Occurance): QueryObject[] {
        if (sops && sops.length > 0) {
            const availabilities = sops
                .filter(
                    (sop) =>
                        sop.type === "availability" &&
                        !sop.disabled &&
                        sop.occurance === occurance &&
                        sop.value &&
                        sop.value.length > 0
                )
                .map((sop) => sop.value)
                .reduce((acc, val) => acc.concat(val), []);

            if (availabilities.length > 0) {
                const datesAsStrings = [
                    ...new Set(
                        availabilities
                            .map((dateRange) =>
                                getDaysArray(dateRange.from, dateRange.to).map((date) => readableDate(date, "yyyyMMdd"))
                            )
                            .reduce((acc, val) => acc.concat(val), [])
                    ),
                ];
                if (datesAsStrings.length > 0) {
                    return datesAsStrings.map((date) => {
                        return this.hasChild(
                            [{ term: { attribute: `availability_${date}` } }, { term: { valueBoolean: true } }],
                            1
                        );
                    });
                }
            }
        }

        return [];
    }

    private searchOperationHasValue(op: SearchOperation): boolean {
        if (!op.value && op.operator !== "exists") {
            return false;
        }
        if (Array.isArray(op.value) && op.value.length === 0) {
            return false;
        }
        if (typeof op.value === "string" && op.value.trim() === "") {
            return false;
        }
        return true;
    }

    private isANullToExclude(sop: SearchOperation): boolean {
        return isString(sop.value) && sop.value === "NULL";
    }

    private isMustSearchOperation(searchOperation: SearchOperation, occurance: Occurance): boolean {
        return (
            searchOperation.occurance === occurance &&
            !searchOperation.disabled &&
            this.searchOperationHasValue(searchOperation) &&
            !this.isANullToExclude(searchOperation) &&
            searchOperation.operator !== "or" &&
            searchOperation.operator !== "or-single-source"
        );
    }

    private isMustNotSearchOperation(searchOperation: SearchOperation, occurance: Occurance): boolean {
        return (
            searchOperation.occurance === occurance &&
            !searchOperation.disabled &&
            this.searchOperationHasValue(searchOperation) &&
            this.isANullToExclude(searchOperation) &&
            searchOperation.operator !== "or" &&
            searchOperation.operator !== "or-single-source"
        );
    }
    private isShouldSearchOperation(searchOperation: SearchOperation, occurance: Occurance): boolean {
        return (
            !searchOperation.disabled &&
            searchOperation.occurance === occurance &&
            this.searchOperationHasValue(searchOperation) &&
            searchOperation.type !== "availability" &&
            (searchOperation.operator === "or" || searchOperation.operator === "or-single-source")
        );
    }

    private mustAdvancedParams(sops: SearchOperation[], occurance: Occurance): QueryObject[] {
        if (sops && sops.length > 0) {
            return sops
                .filter((op) => this.isMustSearchOperation(op, occurance))
                .map((op) => this.mapSearchOperationToQueryObject(op));
        }
        return [];
    }

    private mustNotAdvancedParams(sops: SearchOperation[], occurance: Occurance): QueryObject[] {
        if (sops && sops.length > 0) {
            return sops
                .filter((op) => this.isMustNotSearchOperation(op, occurance))
                .map((op) => this.mapSearchOperationToQueryObject(op));
        }
        return [];
    }

    private mapSearchOperationToQueryObject(op: SearchOperation): QueryObject {
        const searchStrategy: SearchStrategy = this.searchStrategyForOperand(op.operand);
        const strategy: (string) => QueryObject = this._operandStrategies.get(searchStrategy);
        if (strategy) {
            return strategy(op);
        } else {
            return this._operandStrategies.get("generic")(op);
        }
    }

    private searchStrategyForOperand(operand: string): SearchStrategy {
        if (operand) {
            if (operand.startsWith("recruitment_")) {
                return "form";
            }
            if (operand.startsWith("affiliation_")) {
                return "affiliation";
            }
        }
        return operand as SearchStrategy;
    }



    private strategyAffiliation(op: SearchOperation): QueryObject {
        if (op.operator === "query") {
            return this.strategyGenericByOperator(op);
        } else {
            if (op.operand === "affiliation_uda") {
                return this.hasChild([{ term: { attribute: "isUda" } }, {
                    term: {
                        valueBoolean: true,
                    },
                }])
            } else if (op.operand === "affiliation_actra") {
                return this.hasChild([{ term: { attribute: "isActra" } }, {
                    term: {
                        valueBoolean: true,
                    },
                }])
            }
        }
    }

    private strategyContactId(op: SearchOperation): QueryObject {

        const commaOrSpaces = /\s*[\s,]\s*/;
        const contactIds: string[] = op.value.split(commaOrSpaces);
        return {
            query_string: {
                query: `${contactIds.map(cId => {
                    return `((id:${cId}) AND (join:contactdocument))`
                }).join(' OR ')}`
            }
        }

    }

    private strategyCreatedAt(op: SearchOperation): QueryObject {
        const d: QueryObject = {
            range: {
                createdAt: this.strategyGenericByOperator(op).has_child.query.bool.must[1].range.valueDateTime
            }
        }
        return d;
    }

    private strategyFullname(op: SearchOperation): QueryObject {
        let queryForField: QueryObject[] = [{ term: { attribute: op.operand } }];
        const words = op.value.trim().split(" ");

        if (words.length > 1) {
            queryForField.push({
                query_string: {
                    query: `${words.map(word => {
                        return `${word.trim()}*`
                    }).join(' AND ')}`,
                    default_field: "valueString",
                    fuzziness: "AUTO"
                },
            })
        } else {
            queryForField.push({
                query_string: {
                    query: op.value,
                    default_field: "valueString",
                },
            })
        }
        return this.hasChild(queryForField);
    }

    private shouldOperationQueryBuilder(op: SearchOperation): QueryObject[] {
        if (Array.isArray(op.value) && op.value.length > 0) {
            if (op.operator === "or") {
                return op.value.map((id) => {
                    return this.hasChild(this.shouldQueryBuilder(id, true), op.boost);
                });
            }
            if (op.operator === "or-single-source") {
                return op.value.map((id) => {
                    return this.hasChild(this.shouldQueryBuilder(op.operand, id), op.boost);
                });
            }
        }
        return [];
    }

    private shouldQueryBuilder(id: string, value: any): QueryObject[] {
        let should: QueryObject[] = [
            {
                match: {
                    attribute: id,
                },
            },
        ];

        if (value === true || value === false) {
            should = [
                ...should,
                {
                    term: {
                        valueBoolean: value,
                    },
                },
            ];
        } else {
            should = [
                ...should,
                {
                    match: {
                        valueString: value,
                    },
                },
            ];
        }
        return should;
    }

    private strategyIdfig(op: SearchOperation): QueryObject {
        let queryForField: QueryObject[] = [{ term: { attribute: op.operand } }];
        const commaOrSpaces = /\s*[\s,]\s*/;
        const idfigs: string[] = op.value.split(commaOrSpaces).map(raw => this.parseIdfig(raw)).filter(idfig => idfig.length >= 2);
        if (idfigs.length > 1) {
            queryForField.push({
                query_string: {
                    query: `${idfigs.map(idfig => {
                        return `"${idfig}"`
                    }).join(' OR ')}`,
                    default_field: "valueString",
                    fuzziness: 0
                }
            })
        } else {
            queryForField.push({
                term: {
                    valueString: this.parseIdfig(op.value),
                },
            })
        }
        return this.hasChild(queryForField);
    }

    private strategyForm(op: SearchOperation): QueryObject {
        let queryForField: QueryObject[] = [{ term: { attribute: op.operand } }];
        if (op.operator === "greaterThan") {
            // change operand term
            queryForField = [
                { term: { attribute: `${op.operand}_firstSubmissionDate` } },
                this.rangeObject("valueDateTime", { min: op.value })
            ];
        }
        else {
            // Nothing, we just want to check if the attribute exists within the contact that already
            // exists within queryForField    
        }
        return this.hasChild(queryForField)
    }

    private strategyGenericByOperator(op: SearchOperation): QueryObject {
        let queryForField: QueryObject[] = [{ term: { attribute: op.operand } }];
        if (op.operator && op.value !== "NULL") {
            // Generic operator strategy
            if (op.operator === "greaterThan") {
                queryForField.push(this.rangeObject(this.valueTypeForValueSearch(op.value), { min: op.value }));
            } else if (op.operator === "lowerThan") {
                queryForField.push(this.rangeObject(this.valueTypeForValueSearch(op.value), { max: op.value }));
            } else if (op.operator === "range") {
                queryForField.push(
                    this.rangeObject(this.valueTypeForValueSearch(op.value), { min: op.value[0], max: op.value[1] })
                );
            } else if (op.operator === "exists" || op.value === "NOT NULL") {
                // Nothing, we just want to check if the attribute exists within the contact that already
                // exists within queryForField
            } else if (op.operator === "boolean") {

                queryForField.push({
                    term: {
                        valueBoolean: op.value,
                    },
                });
            } else if (op.operator === "query") {
                queryForField.push({
                    query_string: {
                        query: op.value,
                        default_field: "valueString",
                        default_operator: "AND"
                    },
                });
            } else {
                queryForField.push({
                    bool: {
                        should: this.shouldFullTextQuery(op.value),
                        minimum_should_match: 1
                    }
                })
            }

        }
        return this.hasChild(queryForField);
    }

    private parseIdfig(idfigRaw: string): string {
        const idfig = idfigRaw.trim();
        return this.trimLeadingZeros(this.removeAfterNonDigit(idfig));
    }

    private trimLeadingZeros(str: string): string {
        return str.replace(/^0+/, '');
    }

    private removeAfterNonDigit(text: string): string {
        for (let i = 0; i < text.length; i++) {
            if (!/\d/.test(text[i])) {
                return text.slice(0, i);
            }
        }
        return text;
    }

    private valueTypeForValueSearch(value: any): string {
        if (isDate(value)) {
            return "valueDateTime";
        } else if (isNumber(value)) {
            return "valueInt";
        } else if (Array.isArray(value)) {
            return isDate(value[0]) ? "valueDateTime" : "valueInt";
        }

        return "valueString";
    }

    private rangeObject(index: string, minmax: { min?: any; max?: any }): QueryObject {
        const rangeObj = {};
        if (minmax.max || minmax.min) {
            if (minmax.min && minmax.max) {
                rangeObj[index] = {
                    gte: minmax.min,
                    lte: minmax.max,
                };
            } else if (minmax.min) {
                rangeObj[index] = {
                    gte: minmax.min,
                };
            } else if (minmax.max) {
                rangeObj[index] = {
                    lte: minmax.max,
                };
            }

            return {
                range: rangeObj,
            };
        }
    }

    private from(page: number, limit: number): number {
        return page * limit - limit;
    }
}

interface ValueDefinitionObject {
    attribute?: string;
    valueString?: string;
    valueInt?: number;
    valueBoolean?: boolean;
    valueDateTime?: Date;
    datasource?: string;
    contactId?: string;
    id?: string;
}

export interface QueryObject {
    match?: ValueDefinitionObject | any;
    term?: ValueDefinitionObject;
    wildcard?: ValueDefinitionObject | any;
    range?: any;
    exists?: any;
    match_phrase?: any;
    has_child?: any;
    has_parent?: any;
    query_string?: any;
    bool?: BoolObject;
}

interface BoolObject {
    must_not?: QueryObject[] | QueryObject;
    must?: QueryObject[] | QueryObject;
    should?: QueryObject[];
    minimum_should_match?: number;
}

interface OperationObject {
    bool: BoolObject;
}

type SearchStrategy = "generic" | "form" | "affiliation" | "createdAt" | "fullname" | "idfig" | "contactId";
