import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DocumentReference, Timestamp } from 'firebase/firestore';
import { BehaviorSubject, combineLatest, concat, concatMap, debounceTime, distinctUntilChanged, filter, forkJoin, map, Observable, of, share, switchMap, take, tap, zip } from 'rxjs';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchState } from 'src/modules/diversite/model/search-state';
import { ElasticSearchService } from 'src/modules/diversite/services/elasticsearch.service';
import { LocalDatabaseService } from 'src/modules/diversite/services/local-database.service';

const CONTACTS_CACHE_LAST_UPDATE = "contactsCacheLastUpdate";
const CONTACTS_PERCENT_THRESHOLD = 0.9;
const CONTACTS_LAST_PAGE_CACHED = "contactsCacheLastPageCached";
const DEFAULT_SYNC_TTL_DAYS = 30;


@Injectable({
    providedIn: 'root'
})
export class ContactCacheService {

    constructor(private db: AngularFirestore, private elasticSearchService: ElasticSearchService, private indexedDB: LocalDatabaseService) { }
    private _lastUpdateCache: Date;

    private cacheProgress$ = new BehaviorSubject<CacheProgress>(undefined);
    private contactChange$ = new BehaviorSubject<string>(undefined);


    updateCache(): Observable<any> {
        return this.indexedDB.init().pipe(
            switchMap(() => {
                return forkJoin([
                    this.indexedDB.countCollection("contacts"),
                    this.getTTLFromConfig()
                ])
            }),
            switchMap(data => {
                const cachedContactsCount: number = data[0];
                const configTTL: number = data[1];
                return combineLatest([this.fetchAndCacheDB(cachedContactsCount, configTTL), this.syncContactsChangesWithCache()]);
            })
        );
    }

    contactChange(id: string): Observable<string> {
        return this.contactChange$.asObservable().pipe(filter(_ => _ ? true : false), filter(contactIdChange => contactIdChange === id));
    }

    cacheProgress(): Observable<CacheProgress> {
        return this.cacheProgress$.asObservable();
    }

    private syncContactsChangesWithCache(): Observable<any> {
        if (localStorage.getItem(CONTACTS_CACHE_LAST_UPDATE)) {
            this._lastUpdateCache = new Date(localStorage.getItem(CONTACTS_CACHE_LAST_UPDATE));
        }

        return this.db.collection("syncChannel", ref => {
            if (this._lastUpdateCache) {
                return ref.orderBy("timestamp", "asc").where("timestamp", ">", this._lastUpdateCache);
            }
            return ref.orderBy("timestamp", "asc");
        }).valueChanges().pipe(
            debounceTime(1000),
            switchMap(docs => {

                const cacheOperations: CacheOperation[] = docs.filter((d: any) => {
                    if (this._lastUpdateCache) {
                        return d.timestamp.toDate() > this._lastUpdateCache
                    }
                    return true
                }).map((d: any) => {
                    return {
                        id: d.id,
                        delete: d.deletion === true
                    };
                });

                if (cacheOperations.length > 0) {
                    return forkJoin(cacheOperations.map(cacheOperation => this.cacheContact(cacheOperation))).pipe(
                        tap(contactDocs => {
                            console.info("Contacts has been cached", contactDocs);
                        })
                    )
                }
                return of({});
            }),
            tap(_ => {
                this.setLastUpdate();
            })
        )
    }

    private getTotalContactsCount(): Observable<number> {
        return this.elasticSearchService.searchContacts(new SearchDefinition({}), new SearchState({ limit: 0 })).pipe(
            map(initResult => initResult.total.value)
        )
    }

    private setLastUpdate() {
        this._lastUpdateCache = new Date();
        localStorage.setItem(CONTACTS_CACHE_LAST_UPDATE, this._lastUpdateCache.toString())
    }

    private cacheContacts(cacheOperations: CacheOperation[], updateProgress?: boolean): Observable<any[]> {
        return forkJoin(cacheOperations.map(cacheOperation => this.cacheContact(cacheOperation).pipe(tap(_ => {
            if (updateProgress === true) {
                this.cacheProgress$.next(this.cacheProgress$.value.add(1))
            }
        }))));
    }

    private getTTLFromConfig(): Observable<number> {
        return this.db.collection("systemData").doc("parameters").get().pipe(map(doc => {
            const data = doc.data() as any;
            return doc.exists && data?.synchChannelTTLInDays ? data.synchChannelTTLInDays : DEFAULT_SYNC_TTL_DAYS;
        }))
    }

    private differenceInDays(lastUpdated: any): number {
        const now: any = new Date();
        const diffTime = Math.abs(now - lastUpdated);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    private fetchAndCacheDB(cachedContactsCount: number, configTTL: number): Observable<any> {

        const cachedLastUpdate = localStorage.getItem(CONTACTS_CACHE_LAST_UPDATE);
        if (!cachedLastUpdate) { this.setLastUpdate() }

        return this.getTotalContactsCount().pipe(switchMap((total: number) => {
            console.info(`Cached contacts (${cachedContactsCount}/${total})`);

            const lastUpdated: any = new Date(localStorage.getItem(CONTACTS_CACHE_LAST_UPDATE));
            const differenceInDays = this.differenceInDays(lastUpdated);
            const isHigherThanTTL = differenceInDays > (configTTL * CONTACTS_PERCENT_THRESHOLD);
            const cachedTotalIsLessTotal = cachedContactsCount < total;

            if (cachedTotalIsLessTotal || isHigherThanTTL) {

                let obs: Observable<any> = of(undefined);
                if (isHigherThanTTL && !cachedTotalIsLessTotal) {
                    console.info("Your cache is too old. Refreshing the cache...")
                    localStorage.setItem(CONTACTS_LAST_PAGE_CACHED, `0`);
                    obs = this.indexedDB.clearCollection("contacts");
                }

                const lastPageCached = cachedContactsCount === 0 ? 0 : parseInt(localStorage.getItem(CONTACTS_LAST_PAGE_CACHED)) || 0;
                const limit = 20;
                const pages = Math.ceil(total / limit);
                const pagesArray = [...Array(pages)].map((_, index) => { return index + 1 }).filter(p => p > lastPageCached);

                if (pagesArray.length > 0) {
                    this.cacheProgress$.next(new CacheProgress(cachedContactsCount, total))
                    return obs.pipe(
                        switchMap(_ => {
                            return concat(...pagesArray.map(page => {
                                return this.elasticSearchService.searchContacts(new SearchDefinition({}), new SearchState({ limit, page, sort: { field: "createdAt", direction: "asc" } })).pipe(switchMap(results => {
                                    const contactIds = results.contactResults.map(cr => cr.contactId);
                                    console.info(`-------------------------------------------`);
                                    console.info(`Caching page ${page}...`);

                                    if (contactIds.length > 0) {
                                        return this.cacheContacts(contactIds.map(id => { return { delete: false, id }; }), true).pipe(
                                            tap(_ => {
                                                console.info(`Page ${page} of ${pages} is now cached.`);
                                                localStorage.setItem(CONTACTS_LAST_PAGE_CACHED, `${page}`);
                                            })
                                        )
                                    } else {
                                        console.warn(`ES return no contacts for page ${page}`)
                                    }
                                    return of({});
                                })).pipe(
                                    map(_ => {
                                        return { isDone: page === pagesArray[pagesArray.length - 1] }
                                    })
                                )
                            }));
                        }),
                        tap(_ => {
                            if (_.isDone) {

                                this.cacheProgress$.next(this.cacheProgress$.value.complete());
                                setTimeout(() => {
                                    this.cacheProgress$.next(undefined);
                                }, 5000)
                            }
                        })
                    )
                    // ,
                    //     tap(_ => {
                    //         this.cacheProgress$.next(this.cacheProgress$.value.complete())
                    //     })
                    // )

                } else {
                    console.info("No contacts to cache");
                    return of({})
                }
            } else {

            }
            return of({});
        }));
    }

    cacheContact(cacheOperation: CacheOperation): Observable<any> {
        let obs;
        if (cacheOperation.delete) {
            obs = this.indexedDB.delete("contacts", cacheOperation.id);
        } else {
            const docRef = this.db.collection("contacts").doc(cacheOperation.id);
            obs = docRef.get().pipe(
                switchMap(doc => {
                    if (doc.exists) {
                        let contact = { ...doc.data() as any, id: cacheOperation.id };
                        return docRef.collection("pictures").get().pipe(switchMap(docs => {
                            const images = docs.docs.map(d => {
                                return { ...d.data(), id: d.id }
                            })
                            contact = { ...contact, images };
                            return this.indexedDB.set("contacts", this.firestoreToIndexDBMapper(contact));
                        })).pipe(take(1))
                    } else {
                        console.warn(`Contact id: ${cacheOperation.id} doesnt exist in firestore`);
                        return of({});
                    }

                }),
                take(1)
            )
        }

        return obs.pipe(tap(_ => {
            this.contactChange$.next(cacheOperation.id);
        }))
    }

    private firestoreToIndexDBMapper(contact: any): any {
        return JSON.parse(JSON.stringify(contact, (key, value) => {
            if (value instanceof DocumentReference) {
                return value.path;
            }
            if (value instanceof Timestamp) {
                return value.toDate();
            }
            return value;
        }))
    }

}

export class CacheProgress {
    private _total: number;
    private _cachedContacts: number;
    private _complete: boolean;


    constructor(cachedContacts: number, total: number, complete?: boolean) {
        this._total = total;
        this._cachedContacts = cachedContacts;
        this._complete = complete === true ? true : false;
    }


    get total(): number { return this._total; }
    get cachedContacts(): number { return this._cachedContacts; }
    get percent(): number { return Math.ceil(this.cachedContacts / this.total * 100) }
    get isComplete(): boolean { return this._complete; }

    add(x: number = 1): CacheProgress {
        return new CacheProgress(this._cachedContacts + 1, this.total);
    }

    complete(): CacheProgress {
        return new CacheProgress(this.cachedContacts, this.total, true);
    }
}

interface CacheOperation { id: string, delete: boolean; };