import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ElasticSearchMapperService } from 'src/modules/core/mappers/elasticsearch-mapper.service';

import { ContactMapperService } from '../mappers/contact-mapper.service';
import { Contact } from '../model/contact';
import { SearchDefinition } from '../model/search-definition';
import { ApplicationDepartmentService } from 'src/app/services/application-department.service';
import { ContactImageDTO } from '../api-clients/contact-api-client.service';
import { SearchState } from '../model/search-state';

export const CONTACT_INDEX = "contacts02";
export const ATTRIBUTES_INDEX = "attributes";
@Injectable({
    providedIn: "root",
})
export class ElasticSearchService {
    constructor(
        private http: HttpClient,
        private esMapper: ElasticSearchMapperService,
        private contactMapper: ContactMapperService,
        private applicationDepartmentService: ApplicationDepartmentService,
        private db: AngularFirestore
    ) { }

    searchContacts(
        searchParams: SearchDefinition,
        searchPagination: SearchState,
        options = { simpleProfile: false }
    ): Observable<SearchResultContacts> {
        const query = this.esMapper.searchParamsToESQuery(this.applicationDepartmentService.currentDepartmentId(), searchParams, searchPagination);
        // console.log("QUERY");
        // console.log(query)
        // console.log(JSON.stringify(query));
        return this.postESQuery(query, CONTACT_INDEX).pipe(
            map((results) => {
                // console.log("ES Response");
                // console.log(results);

                if (options.simpleProfile) {
                    return {
                        page: searchPagination.page,
                        limit: searchPagination.limit,
                        total: results.hits.total,
                        contactResults: results.hits.hits.map((sourceContact) => {
                            const attrs = sourceContact.inner_hits.attributedocument.hits.hits.map(d => d._source);
                            const attrsObject = {};
                            let idfig;
                            let imageDto: ContactImageDTO;
                            attrs.forEach(sourceAttribute => {
                                if (sourceAttribute.attribute === "idfig") {
                                    idfig = sourceAttribute.valueString;
                                }
                                if (sourceAttribute.attribute === "faceUrl" || sourceAttribute.attribute === "thumbnailUrl") {

                                    if (!imageDto) {
                                        imageDto = {
                                            id: `${sourceAttribute.contactId}.simpleProfilePicture`,
                                            originalUrl: sourceAttribute.valueString,
                                            thumbnails: [],
                                            inputSource: null
                                        }
                                    }

                                    if (sourceAttribute.attribute === "faceUrl") {
                                        imageDto.thumbnails = [...imageDto.thumbnails, { type: "face", thumbnailUrl: sourceAttribute.valueString, faceRectangle: null, width: null, height: null, metaData: null }];
                                    }
                                    if (sourceAttribute.attribute === "thumbnailUrl") {
                                        imageDto.thumbnails = [...imageDto.thumbnails, { type: "thumbnail", thumbnailUrl: sourceAttribute.valueString, faceRectangle: null, width: null, height: null, metaData: null }];
                                    }

                                } else {
                                    attrsObject[sourceAttribute.attribute] = this.getRawValueForSourceValue(sourceAttribute);
                                }
                            });

                            return {
                                contact: this.contactMapper.contactDtoToContact({
                                    id: sourceContact._id,
                                    attributes: attrsObject,
                                    createdAt: null,
                                    lastUpdate: null,
                                    images: imageDto ? [imageDto] : [],
                                    defaultImageIndex: 0,
                                    idfig,
                                    suppressed: false
                                }).change({
                                    createdAt: new Date((sourceContact._source as any).createdAt).getTime(),
                                    lastUpdate: new Date((sourceContact._source as any).lastUpdatedAt).getTime()
                                }),
                                highlights: this.highlightsFromSource(sourceContact),
                            };
                        }),
                        searchGroupTotals: {},
                    };
                } else {

                    return {
                        page: searchPagination.page,
                        limit: searchPagination.limit,
                        total: results.hits.total,
                        contactResults: results.hits.hits.map((source) => {
                            return {
                                contactId: source._source.contactId,
                                highlights: this.highlightsFromSource(source),
                            };
                        }),
                        searchGroupTotals: {},
                    };
                }
            }),
            switchMap((r) => {
                return this.countsForSearchGroups(searchParams).pipe(
                    map((searchGroupCounts) => {
                        return { ...r, searchGroupTotals: searchGroupCounts };
                    })
                );
            })
        );
    }


    private getRawValueForSourceValue(source: SourceDocumentContact): any {
        if (source.valueDateTime) {
            return new Date(source.valueDateTime);
        }

        return source.valueString;
    }

    private countsForSearchGroups(searchDef: SearchDefinition): Observable<{ [searchGroupId: string]: number }> {
        if (searchDef.searchGroup?.searchGroups && searchDef.searchGroup.searchGroups.length > 0) {
            const queries = searchDef.searchGroup.searchGroups
                .map((sg) => {
                    return {
                        searchGroupId: sg.id,
                        query: this.esMapper.searchParamsToESQuery(
                            this.applicationDepartmentService.currentDepartmentId(),
                            searchDef.change({
                                searchGroup: searchDef.searchGroup.change({
                                    searchGroups: searchDef.searchGroup?.searchGroups
                                        .filter((group) => group.id === sg.id)
                                        .map((sg) => sg.enable()),
                                }),
                            }),
                            new SearchState()
                        ),
                    };
                })
                .map((d) => {
                    d.query.size = 0;
                    delete d.query.sort;
                    delete d.query.from;
                    return d;
                });

            return forkJoin(
                queries.map((d) =>
                    this.postESQuery(d.query, CONTACT_INDEX).pipe(
                        map((response) => {
                            return { searchGroupId: d.searchGroupId, number: response.hits.total.value };
                        })
                    )
                )
            ).pipe(
                map((numberResults) => {
                    const obj: { [searchGroupId: string]: number } = {};
                    numberResults.forEach((nr) => {
                        obj[nr.searchGroupId] = nr.number;
                    });
                    return obj;
                })
            );
        }

        return of({});
    }

    private highlightsFromSource(source: any): ContactHighlight[] {
        if (source.inner_hits) {
            return Object.keys(source.inner_hits)
                .filter((key) => (source.inner_hits && source.inner_hits[key]?.hits?.hits ? true : false))
                .map((key) => {
                    return source.inner_hits[key].hits.hits
                        .filter((h) => {
                            return h?.highlight?.valueString && h._source?.attribute ? true : false;
                        })
                        .map((h) => {
                            return {
                                attributeName: h._source.attribute,
                                highlight: h.highlight.valueString.join("..."),
                            };
                        });
                })
                .reduce((acc, val) => acc.concat(val), []);
        } else {
            return [];
        }
    }

    findAttributes(value: string): Observable<AttributeMeta[]> {
        // see this
        // https://stackoverflow.com/questions/31978202/elasticsearch-has-child-query-with-children-aggregation-bucket-counts-are-wro

        const queryObject = {
            track_total_hits: true,
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                valueString: { query: `${value}`, operator: "AND" },
                            },
                        },
                        {
                            has_parent: {
                                parent_type: "contactdocument",
                                query: {
                                    bool: {
                                        must_not: [
                                            {
                                                has_child: {
                                                    type: "attributedocument",
                                                    score_mode: "max",
                                                    query: {
                                                        term: { attribute: "suppressed" },
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                    ],
                },
            },
            aggs: {
                attribute: {
                    terms: {
                        field: "attribute",
                        size: 10,
                    },
                },
            },
        };
        return this.postESQuery(queryObject, CONTACT_INDEX).pipe(
            map((response) => {
                const attributes = response.aggregations.attribute.buckets.map((b) => {
                    return {
                        attributeId: b.key,
                        count: b.doc_count,
                    };
                });
                return attributes;
            })
        );
    }

    findLabels(value: string): Observable<LabelMeta[]> {
        const FUNCTIONS = [
            {
                filter: { match: { source: "config" } },
                weight: 75,
            },
            {
                filter: { match: { source: "form" } },
                weight: 30,
            },
            {
                filter: { match: { source: "manualEntry" } },
                weight: 60,
            },
        ];

        const queryObject = {
            size: 1000,
            query: {
                has_child: {
                    type: "attributedefinitionchild",
                    query: {
                        bool: {
                            should: [
                                {
                                    function_score: {
                                        query: {
                                            match: {
                                                label: {
                                                    query: `${value}`,
                                                    operator: "AND",
                                                },
                                            },
                                        },
                                        functions: FUNCTIONS,
                                    },
                                },
                                {
                                    function_score: {
                                        query: {
                                            query_string: {
                                                query: value,
                                                default_field: "label",
                                            },
                                        },
                                        functions: FUNCTIONS,
                                    },
                                },
                                {
                                    function_score: {
                                        query: {
                                            wildcard: {
                                                label: `${value}*`,
                                            },
                                        },
                                        functions: FUNCTIONS,
                                    },
                                },
                            ],
                            minimum_should_match: 1,
                        },
                    },
                    score_mode: "max",
                    inner_hits: {},
                },
            },
        };
        return this.postESQuery(queryObject, ATTRIBUTES_INDEX).pipe(
            map((response) => {
                const formIds: string[] = response.hits.hits
                    .map((hit) => {
                        return hit.inner_hits.attributedefinitionchild.hits.hits
                            .filter((childhit) => childhit._source.formId)
                            .map((childhit) => childhit._source.formId);
                    })
                    .reduce((acc, val) => acc.concat(val), []);

                return response.hits.hits
                    .map((hit) => {
                        return {
                            attributeName: hit._id,
                            children: hit.inner_hits.attributedefinitionchild.hits.hits
                                .map((childhit) => {
                                    let child;
                                    if (childhit._source.source === "form") {
                                        const formId = formIds.find((fId) => fId === childhit._source.formId);
                                        if (formId) {
                                            child = {
                                                formId,
                                                source: childhit._source.source,
                                                formElementName: hit._id,
                                            };
                                        }
                                    }
                                    if (childhit._source.source === "config") {
                                        if (hit._id) {
                                            child = {
                                                source: childhit._source.source,
                                                formElementName: hit._id,
                                            };
                                        }
                                    }

                                    return child;
                                })
                                .filter((_) => (_ ? true : false)),
                        };
                    })
                    .filter((a) => a.children.length > 0);
            })
        );
    }

    private postESQuery(query: any, index: string): Observable<SearchResponse<SourceDocumentContact>> {
        return this.getTempKey().pipe(
            switchMap((apiKey) => {
                const url = `https://youlug-3.es.eastus2.azure.elastic-cloud.com/${index}/_search`;
                const headers = new HttpHeaders()
                    .set("Content-Type", "application/json")
                    .set("Authorization", `ApiKey ${apiKey}`);

                return this.http.post(url, query, { headers });
            }),
            map((response) => response as SearchResponse<SourceDocumentContact>)
        );
    }

    private getTempKey(): Observable<string> {
        return this.db
            .collection("systemKeys")
            .doc("subSystem01")
            .get()
            .pipe(
                map((doc: any) => {
                    const data = doc.data();
                    return data.api_key_base64;
                })
            );
    }
}

export interface SearchResultContacts {
    error?: string;
    page: number;
    limit: number;
    total: { value: number; relation: string };
    contactResults: ContactResult[];
    searchGroupTotals: { [searchGroupId: string]: number };
}


export interface ContactResult {
    contactId?: string;
    contact?: Contact;
    highlights: ContactHighlight[];
}

export interface ContactHighlight {
    attributeName: string;
    highlight: string;
}

interface ShardsResponse {
    total: number;
    successful: number;
    failed: number;
    skipped: number;
}

interface Explanation {
    value: number;
    description: string;
    details: Explanation[];
}

export interface SearchResponse<T> {
    took: number;
    timed_out: boolean;
    _scroll_id?: string;
    _shards: ShardsResponse;
    hits: {
        total: { value: number; relation: string };
        max_score: number;
        hits: {
            _index: string;
            _type: string;
            _id: string;
            _score: number;
            _source: T;
            _version?: number;
            _explanation?: Explanation;
            fields?: any;
            highlight?: any;
            inner_hits?: any;
            matched_queries?: string[];
            sort?: string[];
        }[];
    };
    aggregations?: any;
}

export interface SourceDocumentContact {
    attribute: string;
    contactId: string;
    valueString: string;
    valueDouble: number;
    valueInt: number;
    valueDateTime: any;
}

export interface AttributeMeta {
    attributeId: string;
    count: number;
}

export interface LabelMeta {
    attributeName: string;
    children: LabelMetaChild[];
}

export interface LabelMetaChild {
    formId: string;
    source: "form" | "config";
    formElementName: string;
}
