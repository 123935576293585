import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Form } from "src/modules/diversite/model/form/form";
import { Pane } from "src/modules/diversite/model/pane";
import { ContactService } from "src/modules/diversite/services/contact.service";
import { FormService } from "src/modules/diversite/services/form.service";
import { ContactPublicMapperService } from "src/modules/fillout/mapper/contact-public-mapper.service";
import { ContactPublic } from "src/modules/fillout/services/recruit.service";

@Component({
    selector: "diversite-form-pane-content-container",
    templateUrl: "./form-pane-content-container.component.html",
    styleUrls: ["./form-pane-content-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPaneContentContainerComponent implements OnChanges {
    @Input() pane: Pane;
    form$: Observable<Form>;

    contact$: Observable<ContactPublic>;

    constructor(
        private formService: FormService,
        private contactService: ContactService,
        private contactPublicMapperService: ContactPublicMapperService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pane?.currentValue) {
            const pane: Pane = changes.pane.currentValue;
            if (pane.contextData && pane.contextData.formId) {
                this.form$ = this.formService.form(pane.contextData.formId);
            } else {
                this.form$ = undefined;
            }

            if (pane.contextData && pane.contextData.contactId) {
                this.contact$ = this.contactService
                    .contactById(pane.contextData.contactId)
                    .pipe(map((c) => this.contactPublicMapperService.mapContactToContactPublic(c)));
            }
        }
    }
}
