<as-split unit="pixel">
    <as-split-area [size]="areaSizes[0]">
        <diversite-search-parameters
            [projectId]="projectId"
            [contextNodeId]="contextNodeId"
            [favoriteNodes]="favoriteSearchNodes"
            [searchMeta]="{ total: searchResults?.total ? searchResults?.total : 0 }"
            [tabOpen]="tabOpen"
            [searchDefinition]="searchDefinition"
            (search)="onSearch($event)"
            (changeTab)="onChangeTab($event)"
        />
    </as-split-area>

    <as-split-area size="*">
        <div class="contacts-results">
            <div class="menu-up" [class.open]="detailedSearchMetaOpen">
                <div *ngIf="searchResults && detailedSearchMetaOpen" class="detailed-section">
                    <div>
                        <button
                            class="btn btn-sm button-menu"
                            [disabled]="searchResults?.total.value >= 10000"
                            (click)="onMassEditResults(searchDefinition)"
                        >
                            Modifier les membres
                        </button>
                        <button
                            [disabled]="searchResults?.total.value > 500"
                            class="btn btn-sm button-menu"
                            (click)="onCreateReport()"
                        >
                            Créer un rapport
                        </button>
                        <button
                            [disabled]="searchResults?.total.value >= 5000"
                            class="btn btn-sm button-menu"
                            (click)="onExport()"
                        >
                            Exporter
                        </button>
                        <button
                            [disabled]="searchResults?.total.value >= 2000"
                            class="btn btn-sm button-menu"
                            (click)="onCreateFolder()"
                        >
                            Créer un dossier
                        </button>
                    </div>
                    <div class="top">
                        <div *ngIf="searchResults && searchResults.total" class="total-info">
                            <small>{{ searchResults.total.value }}</small>
                        </div>

                        <clr-range-container>
                            <label>Grandeur des cartes</label>
                            <input
                                type="range"
                                (change)="onImageWidthChange($event.target.value)"
                                [(ngModel)]="contactWidth"
                                clrRange
                                min="80"
                                max="500"
                            />
                        </clr-range-container>

                        <clr-range-container>
                            <label>Grandeur des infos</label>
                            <input type="range" [(ngModel)]="infoSize" clrRange min="35" max="500" />
                        </clr-range-container>
                    </div>

                    <div class="bottom">
                        <diversite-attribute-multiselect
                            [label]="'Champs à afficher'"
                            [selection]="shownAttributesSelection"
                            (selectionChange)="onComboboxSelectionChange($event)"
                        />
                        <diversite-search-parameters-compact [searchDefinition]="searchDefinition" />
                    </div>
                </div>

                <div
                    *ngIf="searchResults"
                    class="toggle-details"
                    (click)="detailedSearchMetaOpen = !detailedSearchMetaOpen"
                >
                    <div *ngIf="!detailedSearchMetaOpen && searchResults && searchResults.total" class="total-info">
                        <small>{{ searchResults.total.value }}</small>
                    </div>
                    <cds-icon
                        size="14"
                        shape="angle-double"
                        [direction]="detailedSearchMetaOpen ? 'up' : 'down'"
                    ></cds-icon>
                </div>
            </div>

            <div class="contacts">
                <ng-container *ngIf="searchResults">
                    <diversite-contact-search-profile
                        *ngFor="let contactData of searchResults.contactResults; trackBy: trackByContactResult"
                        [selected]="isSelected(contactData.contactId ? contactData.contactId : contactData.contact?.id)"
                        [contactId]="contactData.contactId"
                        [highlights]="contactData.highlights"
                        [height]="contactHeight(contactData.highlights)"
                        [width]="contactWidth"
                        [infoSize]="infoSize"
                        (selectContact)="toggleSelection($event)"
                        (viewProfile)="onViewProfile($event)"
                        (editContact)="onEditContact($event)"
                        (openFormPane)="onOpenFormPane($event, contactData.contactId)"
                    ></diversite-contact-search-profile>
                </ng-container>
                <div #loadingContacts class="loading-contacts">
                    <clr-spinner [hidden]="!loading"></clr-spinner>
                </div>
            </div>

            <div class="no-results" [hidden]="loading || (searchResults && searchResults.contactResults.length > 0)">
                <clr-icon size="50" shape="sad-face"></clr-icon>
                <em>Aucun résultat(s)</em>
            </div>
        </div>
    </as-split-area>
</as-split>
