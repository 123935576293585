<div *ngIf="!previewRestore">
    <div class="backups" *ngIf="backups$ | async as backups">
        <div class="backup" *ngFor="let backup of backups">
            {{ backup.backupDate | date : "dd-MM-yyyy - HH:mm" }}
            <button (click)="previewRestoreBackup(backup)" class="btn btn-sm">Restaurer...</button>
        </div>
    </div>
</div>

<div *ngIf="previewRestore" class="preview-form">
    <div class="warning">
        <p><strong>Etes vous sur de vouloir restaurer cette version du formulaire ?</strong></p>
        <button (click)="restoreBackup(previewRestore.form)" class="btn btn-sm btn-primary">Oui</button>
        <button (click)="removePreview()" class="btn btn-sm">Non</button>
    </div>

    <fillout-form-old [form]="previewRestore.form" [mode]="'preview'"></fillout-form-old>
</div>
