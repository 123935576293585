<div class="send-form-container">
    <div class="top">
        <div *ngIf="contacts$ | async as contacts" class="contacts">
            <div class="contact" *ngFor="let contact of contacts; trackBy: trackById">
                <diversite-photo-cover
                    [mode]="'cover'"
                    [url]="contact.images[0]?.url"
                    [rotate]="contact.images[0]?.rotate"
                >
                </diversite-photo-cover>
            </div>
            <div class="more" *ngIf="contacts.length > 10">
                <small>... plus {{ contacts.length - 10 }}</small>
            </div>
        </div>
    </div>
    <div class="middle">
        <div class="step" *ngIf="step === 'step1'">
            <clr-radio-container>
                <label>Formulaire a envoyer</label>
                <clr-radio-wrapper>
                    <input
                        (change)="resetFormSelection()"
                        type="radio"
                        clrRadio
                        name="form-type"
                        required
                        value="existing"
                        [(ngModel)]="formType"
                    />
                    <label>Existant</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input
                        (change)="resetFormSelection()"
                        type="radio"
                        clrRadio
                        name="form-type"
                        required
                        value="template"
                        [(ngModel)]="formType"
                    />
                    <label>Modèle</label>
                </clr-radio-wrapper>
            </clr-radio-container>

            <div *ngIf="formType === 'existing'">
                <clr-select-container>
                    <label>Choisir le project</label>
                    <select
                        clrSelect
                        name="project-form"
                        [value]="(selectedProjectId$ | async) || ''"
                        (change)="onProjectChange($event.target.value)"
                    >
                        <option value="">-</option>
                        <option *ngFor="let p of projects$ | async; trackBy: trackById" [value]="p.id">
                            {{ p.name }}
                        </option>
                    </select>
                    <clr-control-helper *ngIf="!formSelectedIsFromSameProject()"
                        >Une copie du formulaire sera ajouté au projet</clr-control-helper
                    >
                </clr-select-container>
                <clr-select-container *ngIf="forms$ | async as forms">
                    <label>Choisir le formulaire</label>
                    <select clrSelect name="recruitment-form" (change)="onFormChange($event.target.value, forms)">
                        <option value="">-</option>
                        <option *ngFor="let f of forms; trackBy: trackById" [value]="f.id">
                            {{ f.name[lang] }}
                        </option>
                    </select>
                </clr-select-container>
            </div>

            <div *ngIf="formType === 'template'">
                <clr-select-container *ngIf="templates$ | async as templates">
                    <label>Choisir le modèle de formulaire</label>
                    <select (change)="onFormChange($event.target.value, templates)" clrSelect name="template">
                        <option value="">-</option>
                        <option *ngFor="let template of templates; trackBy: trackById" [value]="template.id">
                            {{ template.name[lang] }}
                        </option>
                    </select>
                </clr-select-container>
                <div *ngIf="selectedForm">
                    <div class="variable" *ngFor="let variable of selectedForm.variables">
                        <clr-input-container *ngFor="let lang of selectedForm.languages; first as isFirst">
                            <label *ngIf="isFirst"
                                >{{ variable.name }} <small>([{{ variable.slug }}])</small></label
                            >
                            <input
                                clrInput
                                [placeholder]="lang"
                                [name]="variable.id + lang"
                                [(ngModel)]="variablesDefinition[variable.slug][lang]"
                            />
                        </clr-input-container>
                    </div>
                </div>
            </div>
            <form #publishForm>
                <clr-input-container *ngIf="formNeedToBePublished(selectedForm)">
                    <label>Lien du formulaire</label>
                    <input name="disabled-url-part" class="url-input" clrInput disabled [value]="urlFirstPart" />
                    <input
                        publicContextAsyncValidator
                        class="slug-input"
                        placeholder="Spécifier un url désiré..."
                        clrInput
                        [(ngModel)]="urlSlug"
                        name="urlSlug"
                        slug
                        required
                    />
                    <clr-control-error *clrIfError="'invalidSlug'"
                        >* Le lien est invalide. Seulement les tirets et lettres minuscules sont
                        permises.</clr-control-error
                    >
                    <clr-control-error *clrIfError="'required'">* Ce champs est requis</clr-control-error>
                    <clr-control-error *clrIfError="'alreadyExist'"
                        >* Un fomulaire avec le même lien existe déjà
                    </clr-control-error>
                    <clr-control-helper>Ce lien sera le lien publique pour accèder au formulaire.</clr-control-helper>
                </clr-input-container>
            </form>
        </div>

        <div class="step" *ngIf="step === 'step2'">
            <clr-textarea-container>
                <label>Texte dans le courriel</label>
                <textarea class="email-text" clrTextarea [(ngModel)]="emailText" name="emailText" required></textarea>
                <clr-control-helper>Ce texte accompagnera le lien dans le courriel</clr-control-helper>
            </clr-textarea-container>
        </div>

        <div class="form-preview-container">
            <fillout-form-container
                *ngIf="selectedForm$ | async as form; else noForm"
                [form]="form"
                [mode]="'preview'"
            ></fillout-form-container>
            <ng-template #noForm>
                <div class="no-form">
                    <em>Aucun formulaire sélectionné</em>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="bottom">
        <button class="btn btn-sm" (click)="onCancel()">Annuler</button>

        <button
            *ngIf="step === 'step1'"
            [disabled]="isSendDisabled"
            (click)="step = 'step2'"
            class="btn btn-primary btn-sm"
        >
            Suivant
        </button>

        <button *ngIf="step === 'step2'" (click)="sendForm(selectedForm)" class="btn btn-primary btn-sm">
            Envoyer
        </button>
    </div>
</div>
