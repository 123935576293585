import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { filter, map, Observable, switchMap } from 'rxjs';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { Project } from 'src/modules/diversite/model/project';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchState } from 'src/modules/diversite/model/search-state';
import { ContactContextNodeAssignService } from 'src/modules/diversite/services/contact-context-node-assign.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';
import { ElasticSearchService, SearchResultContacts } from 'src/modules/diversite/services/elasticsearch.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';

@Component({
    selector: 'diversite-create-folder-action',
    templateUrl: './create-folder-action.component.html',
    styleUrl: './create-folder-action.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFolderActionComponent {
    @Input() searchDefinition: SearchDefinition;
    @Output() close = new EventEmitter<void>();

    private queryResult$: Observable<SearchResultContacts>;
    projects$: Observable<Project[]>;
    contacts$: Observable<Contact[]>;
    metaMore$: Observable<number>;

    projectId: string = "";
    folderName = "";
    isCreating = false;

    private disposeBag = new DisposeBag();

    constructor(
        private projectService: ProjectService,
        private elasticSearchService: ElasticSearchService,
        private contextNodeService: ContextNodeService,
        private contactService: ContactService,
        private contextNodeAssignService: ContactContextNodeAssignService,
        private notificationService: NotificationService,
    ) { }

    ngOnInit(): void {
        this.projects$ = this.projectService.activeProjects();
        this.projectId = this.searchDefinition.projectId || "";


        this.queryResult$ = this.elasticSearchService.searchContacts(this.searchDefinition, new SearchState({ page: 1, limit: 10 }));
        this.contacts$ = this.queryResult$.pipe(switchMap(r => {
            return this.contactService.contactsByIds(r.contactResults.map(r => r.contactId))
        }));

        this.metaMore$ = this.queryResult$.pipe(
            filter(r => r.total && r.total.value > 10 ? true : false),
            map(r => {
                return r.total.value - 10;
            })
        );

    }

    get submitEnabled(): boolean {
        return !this.isCreating && this.folderName.trim() !== "" && this.projectId !== "";
    }

    createFolder(): void {
        this.isCreating = true;
        return this.contextNodeService.addFolderToProjectRoot(this.projectId, this.folderName).pipe(
            switchMap((data) => {
                return this.contextNodeAssignService.assignSearchResultsToNode(this.searchDefinition, data.child)
            })
        ).subscribe(_ => {
            this.notificationService.show("Votre dossier à été ajouté avec succès.", "success");
            this.onCancel();
        }).disposedBy(this.disposeBag)
    }

    onCancel(): void {
        this.close.emit();
    }

    trackById(_, entity: any): string {
        return entity.id;
    }
}
