import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserApiClientService } from '../api-clients/user-api-client.service';
import { User } from '../model/user';

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private userApi: UserApiClientService, private db: AngularFirestore) { }

    user(id: string): Observable<User> {
        if (id) {
            return this.userApi.user(id);
        } else {
            return of(undefined);
        }
    }

    users(options: { listen: boolean } = { listen: false }): Observable<User[]> {
        if (options?.listen) {
            return this.db
                .collection("users")
                .snapshotChanges()
                .pipe(
                    map((docs) => {
                        return docs.map((doc) => {
                            const data = doc.payload.doc.data() as any;
                            return new User({
                                id: doc.payload.doc.id,
                                email: data.email,
                                lastLogin: data.lastLogin.toDate(),
                                access: data.access,
                                selectedDepartmentApplication: data.selectedDepartmentApplication
                            });
                        });
                    }),
                );
        } else {

            return this.db
                .collection("users")
                .get()
                .pipe(
                    map((docs) => {
                        return docs.docs.map((doc) => {
                            const data = doc.data() as any;
                            return new User({
                                id: doc.id,
                                email: data.email,
                                lastLogin: data.lastLogin.toDate(),
                                access: data.access,
                                selectedDepartmentApplication: data.selectedDepartmentApplication
                            });
                        });
                    }),
                );
        }
    }

    updateUser(user: User): Observable<User> {
        const userDto: any = {
            id: user.id,
            email: user.email,
            lastLogin: new Date(user.lastLogin),
            access: user.access,
            selectedDepartmentApplication: user.selectedDepartmentApplication || null
        };

        return from(this.db.doc(`users/${user.id}`).set(userDto)).pipe(map(_ => user));
    }

    addUser(user: User): Observable<User> {
        return this.userApi.addUser(user);
    }
}
