import { formatDate } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { concat, delay, Observable, of } from 'rxjs';

declare var $: any;

export function isEmpty(obj: object): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function slugify(s: string): string {
    const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return s
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w\-]+/g, "") // Remove all non-word characters
        .replace(/\-\-+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
}

export function focusPane(paneId: string, chRef?: ChangeDetectorRef): void {
    setTimeout(() => {
        const paneElement = $(`#${paneId}`);
        if (paneElement.length > 0) {
            paneElement
                .addClass("added")
                .delay(2000)
                .queue((next) => {
                    paneElement.removeClass("added");
                    next();
                });

            paneElement.get(0).scrollIntoView({ behavior: "smooth", inline: "center" });
            if (chRef) {
                chRef.detectChanges();
            }
        }
    }, 300);
}

export function browserLanguage(): string {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang =
        lang ||
        window.navigator.language ||
        (window.navigator as any).browserLanguage ||
        (window.navigator as any).userLanguage;

    let shortLang = lang;
    if (shortLang.indexOf("-") !== -1) {
        shortLang = shortLang.split("-")[0];
    }

    if (shortLang.indexOf("_") !== -1) {
        shortLang = shortLang.split("_")[0];
    }

    return shortLang;
}

export function isImageUrl(url: string): boolean {
    return url && typeof url === "string" && url?.match(/\.(jpeg|jpg|gif|png)$/) !== null ? true : false;
}

export function progressiveLoading<T>(array: T[], delayMillis: number = 100): Observable<T[]> {
    const size = 20;
    const pages = Math.ceil(array.length / size);
    const pagesArray = [...Array(pages)].map((_, index) => { return index + 1 });
    let toReturnArray = [];
    return concat(...pagesArray.map(page => {
        const start = ((page - 1) * size);
        const end = start + size;

        toReturnArray = [...toReturnArray, ...array.slice(start, end)];
        return of(toReturnArray).pipe(delay(delayMillis));
    }))
}

export function isString(value: any): boolean {
    return Object.prototype.toString.call(value) === "[object String]";
}

export function isArrayOfImageUrl(urlArray: any): boolean {
    return Array.isArray(urlArray) && urlArray.some((imgUrl) => isImageUrl(imgUrl));
}

export function guid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        // tslint:disable-next-line:no-bitwise
        const r = (Math.random() * 16) | 0;
        // tslint:disable-next-line:no-bitwise
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}



export function readableDate(date: Date, format?: string): string {
    return formatDate(date, format ? format : "yyyy-MM-dd", "fr");
}

export function isDate(dateTest: any): boolean {
    return dateTest instanceof Date && typeof dateTest.getMonth === "function";
}

export function isNumber(value: string | number): boolean {
    return value != null && !isNaN(Number(value.toString()));
}

export function isObject(value: any): boolean {
    return typeof value === "object" && !Array.isArray(value) && value !== null;
}

export function saysWho(): string {
    let ua = navigator.userAgent,
        tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
            return tem.slice(1).join(" ").replace("OPR", "Opera");
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    // tslint:disable-next-line:no-conditional-assignment
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return M.join(" ");
}

export function round(num: number, decimalPlaces: number): number {
    const p = Math.pow(10, decimalPlaces);
    const e = Number.EPSILON * num * p;
    return Math.round(num * p + e) / p;
}

export function copyTextInClipboard(text: string): void {
    navigator.clipboard.writeText(text);
}

// tslint:disable-next-line:typedef
export function copyToClipboard(elem) {
    // create hidden text element, if it doesn't already exist
    const targetId = "_hiddenCopyText_";
    const isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
    // tslint:disable-next-line:one-variable-per-declaration
    let origSelectionStart, origSelectionEnd, target;

    if (isInput) {
        // can just use the original source element for the selection and copy
        target = elem;
        origSelectionStart = elem.selectionStart;
        origSelectionEnd = elem.selectionEnd;
    } else {
        // must use a temporary form element for the selection and copy
        target = document.getElementById(targetId);
        if (!target) {
            target = document.createElement("textarea");
            target.style.position = "absolute";
            target.style.left = "-9999px";
            target.style.top = "0";
            target.id = targetId;
            document.body.appendChild(target);
        }
        target.textContent = elem.textContent;
    }
    // select the content
    const currentFocus = document.activeElement as any;
    target.focus();
    target.setSelectionRange(0, target.value.length);

    // copy the selection
    let succeed;
    try {
        succeed = document.execCommand("copy");
    } catch (e) {
        succeed = false;
    }
    // restore original focus
    if (currentFocus && typeof currentFocus.focus === "function") {
        currentFocus.focus();
    }

    if (isInput) {
        // restore prior selection
        elem.setSelectionRange(origSelectionStart, origSelectionEnd);
    } else {
        // clear temporary content
        target.textContent = "";
    }
    return succeed;
}

export function dataURLtoFile(dataurl, filename): File {
    // tslint:disable-next-line:one-variable-per-declaration
    const arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
    // Usage example:
    // const file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt');
    // console.log(file);
}

export function calculateAge(fromDate: Date): number {
    if (isDate(fromDate)) {
        const ageDifMs = Date.now() - fromDate.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
}

export function uniqueBy(uniqueKey: string, objects: any[]): any[] {
    const ids = objects.map((object) => object[uniqueKey]);
    return objects.filter((object, index) => !ids.includes(object[uniqueKey], index + 1));
}

export function selectElementContents(el: HTMLElement): void {
    const body = document.body as any;
    let range;
    let sel;
    if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
            range.selectNodeContents(el);
            sel.addRange(range);
        } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
        }
    } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
    }
}

export function validateYouTubeUrl(url: string): string | boolean {
    if (url && url !== "") {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        if (match && match.length > 2 && match[2].length === 11) {
            return `https://www.youtube.com/embed/${match[2]}?autoplay=0`;
        }
    }
    return false;
}

export function isVisibleInViewport(el: HTMLElement, fullyVisible = false): boolean {
    if (!el) {
        return false;
    }
    const rect = el.getBoundingClientRect();
    const topLeftVisible: boolean =
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth);
    const bottomRightVisible: boolean =
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    return fullyVisible ? topLeftVisible && bottomRightVisible : topLeftVisible;
}

export function validateVimeoURL(url: string): string | false {

    if (url && url !== "") {
        const regExp = /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
        const match = url.match(regExp);
        if (match && match[4]) {
            return `https://player.vimeo.com/video/${match[4]}`;
        }
        return false;
    }
    return false;
}

export function weightLiteral(kgValue: number): string {
    return `${kgValue}kg./${pounds(kgValue)}lbs.`;
}

export function pounds(kg: number): string {
    if (kg) {
        return `${Math.round((kg || 0) * 2.20462)}`;
    }
    return "";
}

export function heightLiteral(centimeterValue: number): string {
    return `${meters(centimeterValue)}m./${feetInch(centimeterValue)}`;
}

function meters(cm: number): string {
    if (cm) {
        return `${Number((cm / 100).toFixed(2))}`;
    }
    return "";
}

function feetInch(cm: number): string {
    if (cm) {
        const totalInches = cm / 2.54;
        const feet = Math.floor(totalInches / 12);
        const inch = Math.floor(totalInches - 12 * feet);
        return `${feet}'${inch}"`;
    }
    return "";
}

export function getMinutesBetweenDates(startDate, endDate) {
    if (startDate && endDate) {
        const diff = endDate.getTime() - startDate.getTime();
        return diff / 60000;
    }
    return null;
}

export function validateEmail(email: string): boolean {
    const regexp = new RegExp(
        // tslint:disable-next-line:max-line-length
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return regexp.test(email);
}

export function isDateInRange(date: Date, from: Date, to: Date): boolean {
    const dateTimestamp = date.getTime();
    const fromTimestamp = from.getTime();
    const toTimestamp = to.getTime();
    return dateTimestamp >= fromTimestamp && dateTimestamp <= toTimestamp;
}

export function getDaysArray(start: Date, end: Date) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return [...new Set(arr)];
}

export function range(start: number, end: number): number[] {
    if (typeof end === "undefined") {
        (end = start), (start = 0);
    }

    if (end >= start) {
        let len = end - start + 1;
        let arr = [];
        while (len--) {
            arr[len] = start + len;
        }
        return arr;
    } else {
        let len = start - end + 1;
        let arr = [];
        while (len--) {
            arr[len] = end + len;
        }
        return arr.reverse();
    }
}

// https://gist.github.com/GuilhermeRossato/f9c9a56db090b6d1e247313203fe8209
export function getMimeTypeFromExtension(extension = "txt"): string {
    if (extension[0] === ".") {
        extension = extension.substr(1);
    }
    return {
        "aac": "audio/aac",
        "abw": "application/x-abiword",
        "arc": "application/x-freearc",
        "avi": "video/x-msvideo",
        "azw": "application/vnd.amazon.ebook",
        "bin": "application/octet-stream",
        "bmp": "image/bmp",
        "bz": "application/x-bzip",
        "bz2": "application/x-bzip2",
        "cda": "application/x-cdf",
        "csh": "application/x-csh",
        "css": "text/css",
        "csv": "text/csv",
        "doc": "application/msword",
        "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "eot": "application/vnd.ms-fontobject",
        "epub": "application/epub+zip",
        "gz": "application/gzip",
        "gif": "image/gif",
        "htm": "text/html",
        "html": "text/html",
        "ico": "image/vnd.microsoft.icon",
        "ics": "text/calendar",
        "jar": "application/java-archive",
        "jpeg": "image/jpeg",
        "jpg": "image/jpeg",
        "js": "text/javascript",
        "json": "application/json",
        "jsonld": "application/ld+json",
        "mid": "audio/midi audio/x-midi",
        "midi": "audio/midi audio/x-midi",
        "mjs": "text/javascript",
        "mp3": "audio/mpeg",
        "mp4": "video/mp4",
        "mpeg": "video/mpeg",
        "mpkg": "application/vnd.apple.installer+xml",
        "odp": "application/vnd.oasis.opendocument.presentation",
        "ods": "application/vnd.oasis.opendocument.spreadsheet",
        "odt": "application/vnd.oasis.opendocument.text",
        "oga": "audio/ogg",
        "ogv": "video/ogg",
        "ogx": "application/ogg",
        "opus": "audio/opus",
        "otf": "font/otf",
        "png": "image/png",
        "pdf": "application/pdf",
        "php": "application/x-httpd-php",
        "ppt": "application/vnd.ms-powerpoint",
        "pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "rar": "application/vnd.rar",
        "rtf": "application/rtf",
        "sh": "application/x-sh",
        "svg": "image/svg+xml",
        "swf": "application/x-shockwave-flash",
        "tar": "application/x-tar",
        "tif": "image/tiff",
        "tiff": "image/tiff",
        "ts": "video/mp2t",
        "ttf": "font/ttf",
        "txt": "text/plain",
        "vsd": "application/vnd.visio",
        "wav": "audio/wav",
        "weba": "audio/webm",
        "webm": "video/webm",
        "webp": "image/webp",
        "woff": "font/woff",
        "woff2": "font/woff2",
        "xhtml": "application/xhtml+xml",
        "xls": "application/vnd.ms-excel",
        "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "xml": "application/xml",
        "xul": "application/vnd.mozilla.xul+xml",
        "zip": "application/zip",
        "3gp": "video/3gpp",
        "3g2": "video/3gpp2",
        "7z": "application/x-7z-compressed"
    }[extension] || "application/octet-stream";
}