import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';
import Quill from 'quill';
import { BehaviorSubject, debounceTime, filter, skip } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

declare var $: any;

@Component({
    selector: 'diversite-text-html-editor',
    templateUrl: './text-html-editor.component.html',
    styleUrl: './text-html-editor.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextHtmlEditorComponent {
    @Input() contentHtml: string = "<h1>Hello!</h1>";
    @Output() contentChange = new EventEmitter<string>();

    private contentChange$ = new BehaviorSubject<string>(undefined);
    private quill: Quill;
    private disposeBag = new DisposeBag();
    private readonly QUILL_MODULES = {
        toolbar: [
            ["bold", "italic", "underline", "strike", "link"], // toggled buttons
            // ['blockquote', 'code-block'],

            // [{ header: 1 }, { header: 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
            // [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
            // [{ direction: 'rtl' }],                         // text direction

            // [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            // [{ font: [] }],
            // [{ align: [] }],
            ["link", "image"], // link and image, video

            ["clean"], // remove formatting button
        ],
    };

    constructor(private host: ElementRef, private chRef: ChangeDetectorRef) { }

    ngOnInit() {
        // console.log(Quill.imports);
        // Quill.imports list all style attributors when generating html to have css inline
        const ColorClass: any = Quill.import('attributors/class/color');
        const SizeStyle: any = Quill.import('attributors/style/size');
        const AlignStyle: any = Quill.import('attributors/style/align');

        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Quill.register(AlignStyle, true);

    }

    ngAfterViewInit(): void {
        const element = $(".editor", this.host.nativeElement).get(0);

        this.quill = new Quill(element, {
            theme: "snow",
            // debug?: DebugLevel | boolean;   
            // registry?: Parchment.Registry;
            // readOnly?: boolean;
            // placeholder?: string;
            modules: this.QUILL_MODULES,
            // formats?: string[] | null;
        });

        this.quill.on('editor-change', (eventName, ...args) => {
            if (eventName === 'text-change') {
                const html = this.quill.getSemanticHTML();
                this.contentChange$.next(html);
            }
        });

        this.contentChange$.pipe(
            filter(html => html ? true : false),
            skip(1),
            debounceTime(1500)
        ).subscribe(html => {
            this.contentChange.emit(html);
        }).disposedBy(this.disposeBag);

        this.setValue(this.contentHtml);

    }

    ngOnChanges(changes: SimpleChanges): void {
        const value = changes.contentHtml?.currentValue;
        if (value && this.quill) {
            this.setValue(value);
        }
    }

    private setValue(value: string): void {
        if (this.quill && value) {
            if (this.quill.getSemanticHTML() !== value) {
                const delta = this.quill.clipboard.convert({ html: value });
                this.quill.setContents(delta, 'silent');
            }
        }
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }

}
