<fillout-form-old
    *ngIf="form && isOldForm"
    [mode]="mode"
    [hideEmailBox]="hideEmailBox"
    [contact]="contact"
    [isSubmitted]="isSubmitted"
    [form]="form"
    (submitForm)="onSubmitForm($event)"
/>

<fillout-form
    *ngIf="form && !isOldForm"
    [contact]="contact"
    [mode]="mode"
    [form]="form"
    [submitted]="isSubmitted"
    (submitForm)="onSubmitForm($event)"
/>
