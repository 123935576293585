import { calculateAge, isDate, isNumber, readableDate } from 'src/app/core/functions';

import { FacePositionService } from '../services/face-position.service';
import { ContactImage } from './contact-image';
import { ContactPerspective } from './contactPerspective';
import { Phone } from './phone';

export interface Builder {
    id?: string;
    createdAt?: number;
    lastUpdate?: number;
    attributes?: Attribute[];
    images?: ContactImage[];

    defaultImageIndex?: number;
    cellMembers?: any[];
    hasCell?: boolean;
    idfig?: string;
    suppressed?: boolean;
}

export class Contact {
    private _id: string;
    private _createdAt: number;
    private _lastUpdate: number;
    private _attributes: Attribute[];
    private _images: ContactImage[];
    private _cellMembers: any[];
    private _hasCell: boolean;
    private _defaultImageIndex: number;

    private _email: string;
    private _firstname: string;
    private _lastname: string;
    private _idfig: string;
    private _mainPhone: Phone | string;
    private _sex: string;
    private _age: number;
    private _ageLiteral: string;

    private _suppressed: boolean;

    private _literalStrategy = new Map<string, (index: string) => string>([["age", () => this.ageLiteralStrategy()]]);

    private _defaultPerspective: ContactPerspective;

    static attributeReadableValue(value: any): string {
        if (isDate(value)) {
            return readableDate(value);
        }
        if (isNumber(value)) {
            return `${value}`;
        }
        return value;
    }

    constructor(builder: Builder) {
        this._id = builder.id;
        this._createdAt = builder.createdAt || null;
        this._lastUpdate = builder.lastUpdate || null;
        this._attributes = builder.attributes || [];
        this._images = builder.images || [];
        this._cellMembers = builder.cellMembers || null;
        this._hasCell = builder.hasCell === true ? true : false;
        this._defaultImageIndex =
            builder.defaultImageIndex || builder.defaultImageIndex === 0 ? builder.defaultImageIndex : null;

        this._email = this.getEmail() || null;
        this._firstname = this.getFirstName();
        this._lastname = this.getLastName();
        this._mainPhone = this.getMainPhone();
        this._idfig = builder.idfig || null;
        this._age = this.getAge();
        this._ageLiteral = this.calculateAgeLiteral();
        this._sex = this.getSex();
        this._suppressed = builder.suppressed === true ? true : false;
        this._defaultPerspective = this.generateDefaultPerspective();
    }

    get id(): string {
        return this._id;
    }
    get createdAt(): number {
        return this._createdAt;
    }
    get lastUpdate(): number {
        return this._lastUpdate;
    }
    get attributes(): Attribute[] {
        return this._attributes;
    }
    get images(): ContactImage[] {
        return this._images;
    }
    get cellMembers(): any[] {
        return this._cellMembers;
    }
    get email(): string {
        return this._email;
    }
    get fullname(): string {
        return `${this._lastname}, ${this._firstname}`;
    }
    get firstname(): string {
        return this._firstname;
    }
    get idfig(): string {
        return this._idfig;
    }
    get age(): number {
        return this._age;
    }
    get ageLiteral(): string {
        return this._ageLiteral;
    }
    get suppressed(): boolean {
        return this._suppressed;
    }
    get defaultImageIndex(): number {
        return this._defaultImageIndex;
    }
    get isPremium(): boolean {
        const contextNode_siteFjb = this.attributes.find(attr => attr.def.id === "contextNode_sitefjb") ? true : false;
        const datein = this.attributes.find(attr => attr.def.id === "datein") ? true : false;
        return datein || contextNode_siteFjb;
    }

    get informativeAttributes(): Attribute[] {
        return this.attributes.filter(
            (attr) =>
                !attr.def.id.startsWith("recruitment_") &&
                !attr.def.id.startsWith("availability_") &&
                !attr.def.id.startsWith("photo_") &&
                !attr.def.id.startsWith("video_") &&
                !attr.def.id.startsWith("z_")
        );
    }

    toggleContextNode(nodeId: string): Contact {
        const attrName = `contextNode_${nodeId}`;
        if (this.hasAttribute(attrName)) {
            return this.changeAttribute(attrName, this.getAttributeValue(`contextNode_${nodeId}`) === true ? false : true);
        } else {
            return this.change({
                attributes: [...this.attributes, { def: { id: attrName, name: attrName, type: 'string' }, value: true }]
            });
        }

    }

    private calculateAgeLiteral(): string {
        const todate = new Date();
        const fromdate = this.getAttributeValue("identity_dateOfBirth");
        if (fromdate && this._age < 5) {
            const age = [];
            // tslint:disable-next-line:one-variable-per-declaration
            let y, ydiff, m, mdiff, d, ddiff;
            (y = [todate.getFullYear(), fromdate.getFullYear()]),
                (ydiff = y[0] - y[1]),
                (m = [todate.getMonth(), fromdate.getMonth()]),
                (mdiff = m[0] - m[1]),
                (d = [todate.getDate(), fromdate.getDate()]),
                (ddiff = d[0] - d[1]);

            if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) {
                --ydiff;
            }
            if (mdiff < 0) {
                mdiff += 12;
            }
            if (ddiff < 0) {
                fromdate.setMonth(m[1] + 1, 0);
                ddiff = fromdate.getDate() - d[1] + d[0];
                --mdiff;
            }
            if (ydiff > 0) {
                age.push(ydiff + " an" + (ydiff > 1 ? "s " : " "));
            }
            if (mdiff > 0) {
                age.push(mdiff + " mois");
            }
            // if (ddiff > 0) {
            //     age.push(ddiff + " day" + (ddiff > 1 ? "s" : ""));
            // }
            // if (age.length > 1) {
            //     age.splice(age.length - 1, 0, " and ");
            // }

            return age.join("");
        } else if (fromdate && this._age >= 5) {
            return `${this.age}`;
        } else {
            return "";
        }
    }

    // TODO DEPLACER DANS LE MAPPER EN UTILISANT SYSTEM DATA
    private getFirstName(): string {
        const fn = this.getAttributeValue("identity_firstname");
        if (this._attributes && fn) {
            return fn.trim();
        }
    }
    // TODO DEPLACER DANS LE MAPPER EN UTILISANT SYSTEM DATA
    private getLastName(): string {
        const ln = this.getAttributeValue("identity_lastname");
        if (this._attributes && ln) {
            return ln.trim();
        }
    }
    // TODO DEPLACER DANS LE MAPPER EN UTILISANT SYSTEM DATA
    private getAge(): number {
        const dateOfBirth = this.getAttributeValue("identity_dateOfBirth");
        if (this._attributes && dateOfBirth) {
            return calculateAge(dateOfBirth);
        }
    }
    // TODO DEPLACER DANS LE MAPPER EN UTILISANT SYSTEM DATA
    private getEmail(): string {
        const email = this.getAttributeValue("contactInformation_email");
        if (this._attributes && email) {
            return email;
        }
    }
    // TODO DEPLACER DANS LE MAPPER EN UTILISANT SYSTEM DATA
    private getMainPhone(): Phone {
        const mainPhone = this.getAttributeValue("contactInformation_mainPhone");
        if (this._attributes && mainPhone) {
            return mainPhone;
        }
    }
    // TODO DEPLACER DANS LE MAPPER EN UTILISANT SYSTEM DATA
    private getSex(): string {
        const gender = this.getAttributeValue("identity_gender");
        if (this._attributes && gender) {
            return gender;
        }
    }

    get measurements(): Attribute[] {
        return this._attributes.filter(
            (attr) =>
                attr.def.id.startsWith("measurements_") &&
                attr.def.id !== "measurements_height" &&
                attr.def.id !== "measurements_weight"
        );
    }

    get mainPhone(): Phone | string {
        return this._mainPhone;
    }

    get mainPhoneLiteral(): string {
        const isPhoneObject = this._mainPhone as Phone;
        if (isPhoneObject && isPhoneObject.areaCode && isPhoneObject.localPrefix && isPhoneObject.localSuffix) {
            return `(${isPhoneObject.areaCode}) ${isPhoneObject.localPrefix}-${isPhoneObject.localSuffix}`;
        }
        return this._mainPhone as string;
    }

    get sex(): string {
        return this._sex;
    }

    get hasCell(): boolean {
        return this._hasCell;
    }

    hasAttribute(index: string): boolean {
        const attributeForIndex = this.attributes.find((attr) => attr.def.id === index);
        return attributeForIndex ? true : false;
    }

    getAttributeValue(index: string): any {
        if (index === "idfig") {
            return this.idfig;
        }

        const attributeForIndex = this.attributes.find((attr) => attr.def.id === index);
        return attributeForIndex ? attributeForIndex.value : "";
    }

    getAttribute(index: string): Attribute {
        const attributeForIndex = this.attributes.find((attr) => attr.def.id === index);
        return attributeForIndex;
    }

    getAttributeValueLiteral(index: string, isExcel = false): string {
        const literalStrategy = this._literalStrategy.get(index);

        if (literalStrategy) {
            return literalStrategy(index);
        } else {
            const rawAttr = this.getAttributeValue(index);

            if (rawAttr && rawAttr.areaCode && rawAttr.localPrefix && rawAttr.localSuffix) {
                return `(${rawAttr.areaCode}) ${rawAttr.localPrefix}-${rawAttr.localSuffix}`;
            } else if (rawAttr instanceof Date) {
                if (isExcel) {
                    return rawAttr.toDateString();
                } else {
                    return readableDate(rawAttr);
                }
            } else {
                return rawAttr;
            }
        }
    }

    changeAttribute(attrId: string, value: any): Contact {
        if (this.hasAttribute(attrId)) {
            return this.change({
                attributes: this.attributes.map((attribute) => {
                    if (attrId === attribute.def.id) {
                        attribute.value = value;
                    }
                    return attribute;
                }),
            });
        } else {
            return this.change({
                attributes: [...this.attributes, { def: { id: attrId, name: attrId }, value }]
            })
        }
    }

    get defaultPerspective(): ContactPerspective {
        return this._defaultPerspective;
    }

    private generateDefaultPerspective(): ContactPerspective {
        const defaultImages = this.images
            .filter((_, index) => index < 3)
            .map((image) => {
                return image.id;
            });
        const defaultLayout = FacePositionService.profileLayoutVariantsForNumberOfPictures(defaultImages.length)[0];
        return new ContactPerspective({
            id: this.id,
            contact: this,
            visibleImages: defaultImages,
            layout: defaultLayout,
        });
    }

    changeImageRotation(imageId: string, rotation: number): Contact {
        return this.change({
            images: this._images.map((img) => {
                if (imageId === img.id) {
                    return img.changeRotation(rotation);
                }
                return img;
            }),
        });
    }

    change(builder: Builder): Contact {
        return new Contact({
            id: this.id,
            createdAt: this.createdAt,
            lastUpdate: this.lastUpdate,
            attributes: this.attributes,
            images: this.images,
            cellMembers: this._cellMembers,
            idfig: this._idfig,
            defaultImageIndex: this._defaultImageIndex,
            hasCell: this._hasCell,
            suppressed: this._suppressed,
            ...builder,
        });
    }

    private ageLiteralStrategy(): string {
        return `${this.age}`;
    }
}

export type Gender = "man" | "woman" | "other" | null;

export interface Attribute {
    def: AttributeDefinition;
    value: any;
}

export interface AttributeDefinition {
    id: string;
    name: string;
    type?: AttributeType;
}

export type AttributeType = "string" | "number" | "file" | "choice" | "date";
