import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { User } from '../model/user';
import { UserService } from './user.service';
import { ApplicationDepartmentService } from './application-department.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ContactCacheService } from './contact-cache.service';



@Injectable({
    providedIn: "root",
})
export class AuthService {
    readonly user$ = new BehaviorSubject<User | null>(null);
    readonly loaded$ = new BehaviorSubject<boolean>(false);

    constructor(
        private db: AngularFirestore,
        private afAuth: AngularFireAuth,
        private contactCacheService: ContactCacheService,
        private applicationDepartmentService: ApplicationDepartmentService,
        private userService: UserService,
    ) { }

    get isLoggedIn(): boolean {
        return this.user$.value ? true : false;
    }

    get loggedInUser(): User {
        const user = this.user$.value;
        if (!user) {
            this.logout();
        }
        return user;
    }

    init(): Observable<User> {
        return this.afAuth.authState.pipe(
            switchMap((authUser) => {
                if (authUser && authUser.emailVerified) {
                    return this.userService.user(authUser.uid).pipe(take(1));
                } else {
                    return of(undefined);
                }
            }),
            tap((u) => {
                if (u) {
                    this.applicationDepartmentService.changeDepartment(u.selectedDepartmentApplication);
                    this.user$.next(u);
                } else {
                    this.applicationDepartmentService.changeDepartment();
                    this.user$.next(null);
                }

                if (!this.loaded$.value) {
                    this.firstLoad();
                }
            })
        );
    }

    login(email: string, password: string): Observable<LoginResponse> {
        const obs = new Observable<LoginResponse | firebase.auth.UserCredential>((subscriber) => {
            this.afAuth
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                    this.afAuth
                        .signInWithEmailAndPassword(email, password)
                        .then((credential) => {
                            subscriber.next(credential);
                        })
                        .catch((err) => {
                            console.error(err);
                            subscriber.next("error");
                        });
                })
                .catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.warn(errorCode);
                    console.warn(errorMessage);
                });
        });

        return obs.pipe(
            switchMap((credential) => {
                if (credential !== "error") {
                    const c = credential as firebase.auth.UserCredential;
                    return this.updateUserData(c.user).pipe(
                        map((_) => "success" as LoginResponse)
                    );
                } else {
                    return of("error" as LoginResponse);
                }
            })
        );
    }

    logout(): Observable<void> {
        return from(this.afAuth.signOut()).pipe(
            map((_) => {
                this.user$.next(null);
            })
        );
    }

    emailSignUp(email: string, password: string): Observable<void> {
        return from(this.afAuth.createUserWithEmailAndPassword(email, password)).pipe(
            switchMap((uc) => {
                const host = window.location.href.split("#")[0];
                const actionCodeSettings: firebase.auth.ActionCodeSettings = {
                    url: `${host}#/castlug/login?uid=${uc.user.uid}&email=${email}`,
                    handleCodeInApp: true,
                };

                return from(uc.user.sendEmailVerification(actionCodeSettings)).pipe(
                    map((_) => {
                        this.logout();
                        return undefined;
                    })
                );
            })
        );
    }

    private updateUserData(user: firebase.User): Observable<void> {
        const lastSignIn = user.metadata.lastSignInTime;
        return this.userService.user(user.uid).pipe(
            take(1),
            switchMap((u) => {
                return this.userService
                    .updateUser(
                        new User({
                            id: u.id,
                            email: u.email,
                            lastLogin: new Date(lastSignIn).getTime(),
                            access: u.access,
                            selectedDepartmentApplication: u.selectedDepartmentApplication
                        })
                    )
                    .pipe(
                        tap(() => console.warn("Updated user's last login.")),
                        switchMap(u => this.contactCacheService.updateCache()),
                        tap(_ => console.log("here"))
                    );
            })
        );
    }

    private firstLoad(): void {
        this.loaded$.next(true);
    }
}

export type LoginResponse = "error" | "success";
