import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter, Observable, share, switchMap } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { CacheProgress, ContactCacheService } from 'src/app/services/contact-cache.service';
import { Notification } from 'src/modules/core/model/notification';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

@Component({
    selector: "castlug-container",
    templateUrl: "./castlug-container.component.html",
    styleUrls: ["./castlug-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CastlugContainerComponent implements OnInit {
    constructor(private notificationService: NotificationService, private authService: AuthService, private contactCacheService: ContactCacheService) { }

    loaded$: Observable<boolean>;
    private updatingContactsCache$: Observable<any>;
    notification$: Observable<Notification>;

    cacheProgress$: Observable<CacheProgress>;

    private disposeBag = new DisposeBag();

    ngOnInit(): void {
        this.loaded$ = this.authService.loaded$;

        this.updatingContactsCache$ = this.loaded$.pipe(filter(_ => this.authService.isLoggedIn)).pipe(switchMap(_ => {
            return this.contactCacheService.updateCache()
        }))
        this.updatingContactsCache$.subscribe().disposedBy(this.disposeBag)

        this.notification$ = this.notificationService.alert$;

        this.cacheProgress$ = this.contactCacheService.cacheProgress();

    }



    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }


}
