import { Injectable } from '@angular/core';

import { ContactDTO, ContactImageDTO } from '../api-clients/contact-api-client.service';
import { Attribute, Contact } from '../model/contact';
import { ContactImage } from '../model/contact-image';
import { Thumbnail } from '../model/thumbnail';

@Injectable({
    providedIn: "root",
})
export class ContactMapperService {
    constructor() { }

    contactDtoToContact(contactDto: ContactDTO): Contact {
        if (contactDto) {
            const attrs = this.attributesDtoToAttributes(contactDto.attributes);
            const supAttr = attrs.find((attr) => attr.def.id === "suppressed");
            const suppressed = supAttr && supAttr.value === true;

            const contact = new Contact({
                id: contactDto.id,
                createdAt: contactDto.createdAt ? contactDto.createdAt.seconds * 1000 : null,
                lastUpdate: contactDto.lastUpdate ? contactDto.lastUpdate.seconds * 1000 : null,
                attributes: attrs,
                images: contactDto.images ? contactDto.images.map((img) => this.imageDtoToImage(img)) : [],
                defaultImageIndex: this.defaultImageIndex(contactDto.defaultImageIndex, contactDto.images),
                idfig: contactDto.idfig || null,
                suppressed: suppressed ? true : false,
            });
            const cellMembers = this.buildCellMembers(contact);
            return contact.change({
                cellMembers,
                hasCell:
                    cellMembers.length > 0 ||
                        contact.attributes.find(
                            (attr) =>
                                (attr.def.id === "has_cell_members" || attr.def.id === "hasCell") && attr.value === true
                        )
                        ? true
                        : false,
            });
        }
    }

    private defaultImageIndex(defaultImageIndexDto: number, imagesDto: ContactImageDTO[]): number {
        if (imagesDto && imagesDto.length > 0) {
            // if (defaultImageIndexDto || defaultImageIndexDto === 0) {
            //     return defaultImageIndexDto;
            // } else {
            return this.firstImageIndexWithFace(imagesDto);
            // }
        } else {
            return null;
        }
    }

    private firstImageIndexWithFace(imagesDto: ContactImageDTO[]): number {
        const firstImageIndexWithFace = imagesDto.findIndex((img) => {
            return (
                img &&
                img.thumbnails &&
                img.thumbnails[0] &&
                img.thumbnails[0].faceRectangle &&
                Object.keys(img.thumbnails[0].faceRectangle).length > 0
            );
        });

        if (firstImageIndexWithFace > 0) {
            return firstImageIndexWithFace;
        } else {
            return 0;
        }
    }

    private buildCellMembers(contact: Contact): any[] {
        const cellMembers = contact.attributes
            .filter((attr) => attr.def.id.indexOf("MOD") >= 0 && attr.def.id.indexOf("BULLE") >= 0)
            .filter((attr) => Array.isArray(attr.value))
            .map((attr) =>
                attr.value.map((cm) => {
                    return { ...cm, cellType: attr.def.name.match(/\d+/)[0] };
                })
            )
            .reduce((accumulator, value) => accumulator.concat(value), []);

        if (cellMembers && cellMembers.length > 0) {
            return cellMembers;
        } else {
            return [];
        }
    }

    private attributesDtoToAttributes(data: any): Attribute[] {
        if (!data) {
            return [];
        }
        const finalAttrs = Object.keys(data)
            .map((index) => {
                return {
                    def: { id: index, name: index },
                    value: this.convertValue(index, data[index]),
                };
            })
            .sort((a, b) => {
                if (a.def.id < b.def.id) {
                    return -1;
                }
                if (a.def.id > b.def.id) {
                    return 1;
                }
                return 0;
            });
        return finalAttrs;
    }

    private convertValue(index: string, value: any): any {
        if (this.valueIsTimestamp(value)) {
            const date: Date = this.convertTimestamp(value);
            const dateNoTimezone = new Date(date.toISOString().split("T")[0] + "T12:00:00");
            return dateNoTimezone;
        }

        if (this.valueIsAvailability(index)) {
            if (value && value.from && this.valueIsTimestamp(value.from)) {
                value = { ...value, from: this.convertTimestamp(value.from) };
            }
            if (value && value.to && this.valueIsTimestamp(value.to)) {
                value = { ...value, to: this.convertTimestamp(value.to) };
            }
        }

        if (
            Array.isArray(value) &&
            value.every((v) => v?.from && v?.to && this.valueIsTimestamp(v.from) && this.valueIsTimestamp(v.to))
        ) {
            value = value.map((v) => {
                return { ...v, from: this.convertTimestamp(v.from), to: this.convertTimestamp(v.to) };
            });
        }

        return value;
    }

    private valueIsAvailability(index: string): any {
        return index.startsWith("availability_");
    }

    valueIsTimestamp(value: any): boolean {
        return (
            value && typeof value === "object" && value.hasOwnProperty("seconds") && value.hasOwnProperty("nanoseconds")
        );
    }

    imageToImageDto(image: ContactImage): ContactImageDTO {
        return {
            id: image.id,
            originalUrl: image.originalUrl,
            inputSource: image.inputSource || null,
            thumbnails: image.thumbnails.map((thumbnail) => {
                return {
                    thumbnailUrl: thumbnail.thumbnailUrl || null,
                    metaData: thumbnail.metaData || null,
                    faceRectangle: thumbnail.faceRectangle || null,
                    width: thumbnail.width || null,
                    height: thumbnail.height || null,
                };
            }),
            uploadedAt: image.uploadedAt ? image.uploadedAt : null,
            originalDate: image.originalDate ? image.originalDate : null,
            isPictureOfCellMember: image.cellPicture,
        };
    }
    imageDtoToImage(image: ContactImageDTO): ContactImage {
        return new ContactImage({
            id: image.id,
            originalUrl: this.imageOriginalUrl(image),
            inputSource: image.inputSource || null,
            uploadedAt: image.uploadedAt ? this.convertTimestamp(image.uploadedAt) : null,
            originalDate: image.originalDate ? this.convertTimestamp(image.originalDate) : null,
            thumbnails: image.thumbnails
                ? image.thumbnails.map((thumbnailDto) => {
                    return new Thumbnail({
                        thumbnailUrl: thumbnailDto.thumbnailUrl,
                        metaData: thumbnailDto.metaData,
                        faceRectangle: thumbnailDto.faceRectangle,
                        width: thumbnailDto.width,
                        height: thumbnailDto.height,
                    });
                })
                : [],
            rotate: image.rotate,
            cellPicture: image.isPictureOfCellMember,
        });
    }

    private convertTimestamp(value: any): Date {
        return value.toDate ? value.toDate() : new Date((value.seconds * 1000) + value.nanoseconds)
    }

    private imageOriginalUrl(image: ContactImageDTO): string {
        return image.originalUrl;
    }
}
