import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

import { ContactPublic } from '../../services/recruit.service';
import { ResponseInput } from '../../model/response-input';
import { Form } from 'src/modules/diversite/model/form/form';
import { FormMode } from '../../model/form-mode';
import { SubmissionReponses } from '../../model/submission-responses';

const SEPT_23_2024 = new Date(2024, 8, 23)

@Component({
    selector: 'fillout-form-container',
    templateUrl: './form-container.component.html',
    styleUrl: './form-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormContainerComponent {
    @Input() form: Form;
    @Input() mode: FormMode = "fillout";
    @Input() isSubmitted = false;
    @Input() isSubmitting = false;
    @Input() forceEndText = false;
    @Input() contact: ContactPublic;
    @Input() hideEmailBox = false;

    @Output() submitForm = new EventEmitter<SubmissionReponses>();
    @Output() responseChange = new EventEmitter<ResponseInput>();


    private _oldForm: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.form?.currentValue) {
            this._oldForm = !this.form.created || this.form.created < SEPT_23_2024;
        }
    }


    onSubmitForm(responses: SubmissionReponses): void {
        this.submitForm.emit(responses);
    }


    get isOldForm(): boolean {
        return this._oldForm;
    }
}
