<div class="contact-report" *ngIf="contactPerspective.contact">
    <cds-icon (click)="deleteContact(contactPerspective.contact.id)" class="delete-btn" shape="times"></cds-icon>

    <div *ngIf="reportType === 'picture-info'" class="picture-info-container">
        <diversite-photo-profile
            [showOptions]="false"
            [showThumbnailSelection]="editable"
            [contactPerspective]="contactPerspective"
            (contactPerspectiveChange)="onContactPerspectiveChange($event)"
        ></diversite-photo-profile>
        <div class="attributes">
            <div *ngFor="let attributeToShow of showAttributes">
                <diversite-attribute-label [id]="attributeToShow" />
                <diversite-attribute-value
                    [attributeName]="attributeToShow"
                    [value]="contactPerspective.contact.getAttributeValue(attributeToShow)"
                />
            </div>
        </div>
    </div>
    <diversite-contact-card
        *ngIf="reportType === 'picture-grid'"
        [showSquareFace]="false"
        [cardFaceDispositionOptions]="cardFaceDispositionOptions"
        (contactPerspectiveChange)="onContactPerspectiveChange($event)"
        [lazyload]="false"
        [editable]="editable"
        [contactPerspective]="contactPerspective"
    >
    </diversite-contact-card>
</div>
