import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormHeight } from 'src/modules/diversite/model/form/form-element/form-height';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: "fillout-height",
    templateUrl: "./height.component.html",
    styleUrls: ["./height.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeightComponent implements OnInit {
    @Input() lang: string;
    @Input() formElement: FormHeight;
    @Input() value: any;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<string>();
    delayedChanges$ = new BehaviorSubject<string>(undefined);

    private _disposeBag = new DisposeBag();

    constructor(private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe((v) => {
                this.responseChange.emit(v);
            })
            .disposedBy(this._disposeBag);
    }

    onInputChange(event: KeyboardEvent): void {
        this.value = (event.target as HTMLInputElement).value;
        this.delayedChanges$.next(this.value);
        this.chRef.detectChanges();
    }



    metersChange(m: number): void {
        this.value = m * 100;
        this.delayedChanges$.next(this.value);
        this.chRef.detectChanges();
    }

    meters(cm: number): number {
        if (cm) {
            return Number((cm / 100).toFixed(2));
        }
        return null;
    }

    feetInch(cm: number): string {
        if (cm) {
            const totalInches = cm / 2.54;
            const feet = Math.floor(totalInches / 12);
            const inch = Math.floor(totalInches - 12 * feet);
            return `${feet}'${inch}"`;
        }
        return "";
    }

    feetChange(feet: number, inch: any): void {
        setTimeout(() => {
            if (feet) {
                const cmTotal = feet * 30.48 + inch.value * 2.54;
                this.value = Math.round(cmTotal);
                this.delayedChanges$.next(this.value);
                this.chRef.detectChanges();
            }
        });
    }

    minus(): void {
        if (!this.value) {
            this.value = 154;
        }
        this.value = parseFloat(this.value) - 1;
        this.delayedChanges$.next(this.value);
        this.chRef.detectChanges();
    }

    plus(): void {
        if (!this.value) {
            this.value = 154;
        }
        this.value = parseFloat(this.value) + 1;
        this.delayedChanges$.next(this.value);
        this.chRef.detectChanges();
    }


    inchChange(feet: any, inch: number): void {
        setTimeout(() => {
            if (feet) {
                const cmTotal = feet.value * 30.48 + inch * 2.54;
                this.value = Math.round(cmTotal);
                this.delayedChanges$.next(this.value);
                this.chRef.detectChanges();
            }
        });
    }

    feet(cm: number): number {
        if (cm) {
            const totalInches = cm / 2.54;
            const feet = Math.floor(totalInches / 12);
            return feet;
        }
        return null;
    }
    inch(cm: number): number {
        if (cm) {
            const totalInches = cm / 2.54;
            const feet = Math.floor(totalInches / 12);
            const inch = Math.floor(totalInches - 12 * feet);
            return inch;
        }
        return null;
    }
}
