<diversite-search-contacts-pane-content
    [projectId]="pane.projectId"
    [contextNodeId]="pane.contextNodeId"
    [loading]="loading"
    [areaSizes]="pane.contextData?.areaSizes"
    [searchDefinition]="searchDefinition$ | async"
    [searchState]="searchState$ | async"
    [favoriteSearchNodes]="favoriteSearchNodes$ | async"
    [tabOpen]="pane.contextData?.tabOpen ? pane.contextData?.tabOpen : 'search'"
    [searchResults]="searchResults$ | async"
    [contactWidth]="pane.contextData?.contactCardWidth"
    (loadMore)="onLoadMore($event)"
    (changeSearchTab)="onSearchParametersTab($event)"
    (massEditResults)="onMassEditResults($event)"
    (changeContactWidth)="onContactWidthChange($event)"
    (search)="onSearch($event)"
/>
