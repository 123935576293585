<div *ngIf="loaded$ | async; else loading" class="main-container">
    <castlug-notification [notification]="notification$ | async"></castlug-notification>

    <div class="content-container">
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
    </div>

    <div
        *ngIf="cacheProgress$ | async as cacheProgress"
        class="cache-alert alert alert-app-level alert-info"
        role="alert"
    >
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <cds-icon class="alert-icon" shape="info-standard"></cds-icon>
                </div>
                <div class="alert-text">
                    Votre base de données locale est en cours de mise à jour. ... {{ cacheProgress.percent }}%

                    <div class="progress-block">
                        <clr-progress-bar [clrValue]="cacheProgress.percent"></clr-progress-bar>
                        <span>{{ cacheProgress.cachedContacts }}</span> / <span>{{ cacheProgress.total }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="loading"><span>Chargement...</span></div>
</ng-template>
