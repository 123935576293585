import { Routes } from "@angular/router";

import { AuthGuard } from "../core/auth.guard";
import { ContactContainerComponent } from "./components/contacts/contact-container/contact-container.component";
import { ContactEditContainerComponent } from "./components/contacts/contact-edit-container/contact-edit-container.component";
import { MainDashboardComponent } from "./components/dashboard/main-dashboard/main-dashboard.component";

export const diversiteRoutes: Routes = [
    { path: "", redirectTo: "main", pathMatch: "full" },
    { path: "main", component: MainDashboardComponent, canActivate: [AuthGuard] },
    {
        path: "contact",
        component: ContactContainerComponent,
        children: [{ path: "edit/:id", component: ContactEditContainerComponent }],
    },
];
