import { DocumentReference } from '@angular/fire/compat/firestore';

import { ActionType } from './pane';



export interface ContextNodeBuilder {
    id?: string;
    name?: string;
    color?: string;
    type?: ContextNodeType;
    parentNodeId?: string;
    contextData?: ContextData;
    children?: string[];
    created?: Date;
    createdBy?: DocumentReference;
}
export class ContextNode {
    private _id: string;
    private _name: string;
    private _color: string;
    private _type: ContextNodeType;
    private _contextData: ContextData;
    private _parentNodeId: string;
    private _children: string[];
    private _created: Date;
    private _createdBy: DocumentReference;

    constructor(builder: ContextNodeBuilder) {
        this._id = builder.id || null;
        this._name = builder.name || "";
        this._color = builder.color || "#333333";
        this._type = builder.type;
        this._contextData = builder.contextData || null;
        this._parentNodeId = builder.parentNodeId || null;
        this._children = builder.children || null;
        this._created = builder.created;
        this._createdBy = builder.createdBy;
    }

    get id(): string {
        return this._id;
    }
    get name(): string {
        return this._name;
    }
    get color(): string {
        return this._color || "#cccccc";
    }
    get type(): ContextNodeType {
        return this._type;
    }
    get contextData(): ContextData {
        return this._contextData;
    }
    get parentNodeId(): string {
        return this._parentNodeId;
    }
    get children(): string[] {
        return this._children;
    }
    get created(): Date {
        return this._created;
    }

    get createdBy(): DocumentReference {
        return this._createdBy;
    }

    addChildNode(nodeId: string): ContextNode {
        return this.change({ children: this.children ? [...new Set([...this.children, nodeId])] : [nodeId] });
    }

    removeChildNode(nodeId: string): ContextNode {
        return this.change({ children: this.children ? this.children.filter((id) => id !== nodeId) : [] });
    }

    addChildrenNodes(nodeIds: string[]): ContextNode {
        return this.change({ children: this.children ? [...new Set([...this.children, ...nodeIds])] : [...nodeIds] });
    }

    addFormIds(formIds: string[]): ContextNode {
        let newFormIds: string[];
        if (this._contextData && this._contextData.formIds) {
            newFormIds = [...this._contextData.formIds, ...formIds];
        } else {
            newFormIds = [...formIds];
        }

        // remove duplicate
        newFormIds = [...new Set(newFormIds)];

        const contextData = this._contextData ? { ...this._contextData, formIds: newFormIds } : { formIds: newFormIds };

        return this.change({ contextData });
    }

    removeFormIds(formIds: string[]): ContextNode {
        const newFormIds = this.contextData.formIds.filter((formId) => {
            const existInToDeleteFormIds = formIds.find((fid) => fid === formId) ? true : false;
            return !existInToDeleteFormIds;
        });
        const contextData = { ...this.contextData, formIds: newFormIds };
        return this.change({ contextData });
    }

    addReportIds(reportIds: string[]): ContextNode {
        let newReportIds: string[];
        if (this._contextData && this._contextData.reportIds) {
            newReportIds = [...this._contextData.reportIds, ...reportIds];
        } else {
            newReportIds = [...reportIds];
        }

        // remove duplicate
        newReportIds = [...new Set(newReportIds)];

        const contextData = this._contextData
            ? { ...this._contextData, reportIds: newReportIds }
            : { reportIds: newReportIds };

        return this.change({ contextData });
    }

    assignSharedPackage(packageId: string): ContextNode {
        return this.change({
            contextData: { ...this.contextData, sharedPackages: this.contextData?.sharedPackages ? [...this.contextData.sharedPackages, packageId] : [packageId] }
        });
    }

    unassignSharedPackage(packageId: string): ContextNode {
        return this.change({
            contextData: { ...this.contextData, sharedPackages: this.contextData.sharedPackages.filter(pckageId => packageId !== pckageId) }
        });
    }

    get hasForms(): boolean {
        return this.contextData && this.contextData.formIds && this.contextData.formIds.length > 0;
    }
    get hasReports(): boolean {
        return this.contextData && this.contextData.reportIds && this.contextData.reportIds.length > 0;
    }
    get hasSharedFolder(): boolean {
        return this.contextData && this.contextData.sharedPackages && this.contextData.sharedPackages.length > 0;
    }

    change(builder: ContextNodeBuilder): ContextNode {
        return new ContextNode({
            id: this._id,
            name: this._name,
            color: this._color,
            type: this._type,
            contextData: this._contextData,
            parentNodeId: this._parentNodeId,
            children: this._children,
            createdBy: this._createdBy,
            created: this._created,
            ...builder,
        });
    }
}

export interface ContextData {
    contextNodeId?: string;
    searchContextId?: string;
    formId?: string;
    jobId?: string;
    focusProjectContextNodeId?: string;
    projectId?: string;
    reportIds?: string[];
    contactIdfigs?: string[];
    viewOption?: string;
    contactId?: string;
    formIds?: string[];
    reportId?: string;
    areaSizes?: number[];
    paneId?: string;
    actionType?: ActionType;
    contactsDatabaseId?: string;
    datasource?: string;
    sharedPackages?: string[];
}

export type ContextNodeType = "folder" | "search" | "search-parameters" | "project" | "preselection" | "reject";
