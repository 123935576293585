<div class="pane" [class.disabled]="disabled" *ngIf="pane; else loading">
    <diversite-pane-header
        [class.vertical]="collapsed"
        [class.horizontal]="!collapsed"
        [closable]="isClosable(pane)"
        [locked]="pane.locked"
        [paneSize]="paneSize"
        [collapsible]="!collapsed && pane.type !== 'action'"
        [pinable]="isPinable(pane)"
        [collapsed]="collapsed"
        [paneType]="pane.type"
        [panePath]="panePath$ | async"
        [forceHeaderName]="headerName(pane)"
        (toggleCollapse)="onToggleCollapsePane()"
        (maximizePane)="onMaximize(pane)"
        (remove)="onClosePane()"
        (toggleLock)="onToggleLockPane(pane)"
        (paneSizeChange)="onPaneSizeChange($event)"
    />
    <div *ngIf="!collapsed" class="pane-content {{ pane.type }}">
        <diversite-default-pane-content [pane]="pane" *ngIf="pane.type === 'default'"></diversite-default-pane-content>

        <!-- <diversite-search-pane-content-container
            *ngIf="pane.type === 'search'"
            [pane]="pane"
        ></diversite-search-pane-content-container> -->

        <diversite-search-contacts-pane-content-container
            *ngIf="pane.type === 'search'"
            [pane]="pane"
        ></diversite-search-contacts-pane-content-container>

        <diversite-treeview-pane-content-container
            *ngIf="pane.type === 'treeview'"
            [pane]="pane"
        ></diversite-treeview-pane-content-container>

        <diversite-projects-pane-content-container
            *ngIf="pane.type === 'projects'"
            [pane]="pane"
        ></diversite-projects-pane-content-container>

        <diversite-form-pane-content-container
            *ngIf="pane.type === 'form-preview'"
            [pane]="pane"
        ></diversite-form-pane-content-container>

        <diversite-upload-photo-content-container
            *ngIf="pane.type === 'upload-photo-preview'"
            [pane]="pane"
        ></diversite-upload-photo-content-container>

        <diversite-edit-form-pane-content-container
            *ngIf="pane.type === 'form-edit'"
            [pane]="pane"
        ></diversite-edit-form-pane-content-container>

        <diversite-contacts-pane-content-container
            *ngIf="pane.type === 'contacts'"
            [pane]="pane"
        ></diversite-contacts-pane-content-container>

        <diversite-contact-pane-content-container
            *ngIf="pane.type === 'contact'"
            [pane]="pane"
        ></diversite-contact-pane-content-container>

        <diversite-report-edit-pane-content-container
            *ngIf="pane.type === 'report-edit'"
            [pane]="pane"
        ></diversite-report-edit-pane-content-container>

        <diversite-actions-pane-content-container
            *ngIf="pane.type === 'action'"
            [pane]="pane"
            (close)="onClosePane()"
        ></diversite-actions-pane-content-container>

        <diversite-activities-pane-content-container
            *ngIf="pane.type === 'activities'"
            (close)="onClosePane()"
        ></diversite-activities-pane-content-container>
    </div>
</div>
<ng-template #loading>
    <div class="loading">
        <clr-spinner [clrSmall]="true"></clr-spinner>
    </div>
</ng-template>
