<div class="form-request-container" [class.preview]="mode === 'preview'">
    <div *ngIf="mode !== 'admin_fix'" class="branding">
        <img class="main-logo-castlug" [src]="logo()" />
    </div>

    <div [hidden]="form.languages.length < 2" class="lang-selection">
        <span (click)="selectLang(lang)" [class.current]="lang === selectedLang" *ngFor="let lang of form.languages">{{
            lang
        }}</span>
    </div>

    <div class="loading-container" *ngIf="loading || isSubmitting">
        <span class="spinner spinner-lg"> {{ label("submittingWaitingLabel") }} </span>
    </div>

    <div class="form-filling" [hidden]="!isFormFilling">
        <div class="intro-email-request" [hidden]="!isIntroEmailRequestStep">
            <h3>{{ label("welcomeForFormLabel") }}</h3>
            <h1>{{ form.name[selectedLang] }}</h1>
            <p>{{ label("enterYourEmailExplanationLabel") }}</p>

            <input
                [placeholder]="label('emailPlaceholder')"
                class="email-request-input"
                [(ngModel)]="emailRequest"
                clrInput
                type="text"
            />

            <button (click)="sendLinksToEmail()" [disabled]="!isValidEmail(emailRequest)" class="btn btn-primary">
                {{ label("proceedButtonLabel") }}
            </button>
            <small (click)="goToForm()" class="action-link">{{ label("skipLabel") }}</small>
        </div>

        <div class="check-email" [hidden]="!isCheckYourEmailStep">
            <cds-icon shape="envelope" size="xxl"></cds-icon>
            <h3>{{ label("checkYourInboxLabel") }}</h3>

            <p>{{ label("checkYourInboxExplanationLabel") }}</p>
            <small (click)="goToForm()" class="action-link">{{ label("skipLabel") }}</small>
        </div>

        <div class="form" [hidden]="!isFormStep">
            <div
                class="module"
                [class.mandatory-module]="module.isMandatoryValidationModule"
                *ngFor="let module of notConscentModules(form); trackBy: trackById"
            >
                <div *ngIf="!module.deleted" class="module">
                    <div
                        *ngIf="module.name && module.name[selectedLang] && module.name[selectedLang] !== ''"
                        class="module-header"
                    >
                        <h3>
                            {{ module.name[selectedLang] }}
                        </h3>
                    </div>
                    <div
                        [id]="formElement.id"
                        class="form-element-container"
                        *ngFor="let formElement of module.formElements; trackBy: trackByFnFormElement"
                    >
                        <fillout-form-element
                            *ngIf="!formElement.deleted"
                            [lang]="selectedLang"
                            [value]="value(formElement)"
                            [formElement]="formElement"
                            (responseChange)="onResponseChange($event)"
                        >
                        </fillout-form-element>
                        <ng-container *ngIf="module.isMandatoryValidationModule && !formElement.deleted">
                            <clr-checkbox-wrapper class="certify-info">
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    [name]="formElement.id + '_certify'"
                                    required
                                    (change)="toggleCertify(formElement.id, $event.target.checked)"
                                />
                                <label>{{ label("certifyValidFieldLabel") }}</label>
                            </clr-checkbox-wrapper>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="footer-form-container" #footerForm>
                <div class="footer-form" [class.sticky]="!(isFooterVisible$ | async)">
                    <div class="center-content">
                        <div *ngIf="showErrorsBoard && !isFormValid()" class="errors alert alert-danger">
                            <div class="alert-items">
                                <strong>{{ label("fixFollowingErrorsText") }}</strong>
                                <div
                                    class="alert-item static"
                                    *ngFor="let errorsInput of errors; trackBy: trackByError"
                                >
                                    <ng-container *ngIf="errorsInput.length > 0">
                                        <div class="alert-icon-wrapper">
                                            <clr-icon class="alert-icon" shape="exclamation-circle"> </clr-icon>
                                        </div>

                                        <ng-container *ngIf="errorsInput[0].type === 'basic-info-validation'">
                                            <span class="alert-text">{{ label("errorBasicInfoValidation") }}</span>
                                            <div class="alert-actions">
                                                <a (click)="seeValidationErrors()" class="action-link">{{
                                                    label("seeErrorText")
                                                }}</a>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="errorsInput[0].type === 'required'">
                                            <span class="alert-text"
                                                >{{ label("errorRequiredText") }} :
                                                <em>{{ errorsInput[0].formElement.label[selectedLang] }}</em></span
                                            >
                                            <div class="alert-actions">
                                                <a (click)="seeError(errorsInput[0])" class="action-link">{{
                                                    label("seeErrorText")
                                                }}</a>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <button (click)="goIntroEmailRequest()" class="btn">
                            {{ label("backLabel") }}
                        </button>
                        <button (click)="goNext()" class="submit-btn btn btn-primary">
                            {{ label("nextLabel") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="conscent" *ngIf="isConscentStep">
            <div class="form">
                <div class="module conscent-module" *ngIf="conscentModule() as module">
                    <div *ngIf="!module.deleted" class="module">
                        <div
                            *ngIf="module.name && module.name[selectedLang] && module.name[selectedLang] !== ''"
                            class="module-header"
                        >
                            <h3>
                                {{ module.name[selectedLang] }}
                            </h3>
                        </div>
                        <div
                            [id]="formElement.id"
                            class="form-element-container"
                            *ngFor="let formElement of module.formElements; trackBy: trackByFnFormElement"
                        >
                            <fillout-form-element
                                *ngIf="!formElement.deleted"
                                [lang]="selectedLang"
                                [value]="value(formElement)"
                                [formElement]="formElement"
                                (responseChange)="onResponseChange($event)"
                            >
                            </fillout-form-element>
                        </div>
                    </div>
                </div>
                <div class="footer-form-container">
                    <div class="footer-form">
                        <div class="center-content">
                            <div
                                *ngIf="showErrorsBoardConscent && !isConscentValid()"
                                class="errors alert alert-danger"
                            >
                                <div class="alert-items">
                                    <strong>{{ label("fixFollowingErrorsText") }}</strong>
                                    <div
                                        class="alert-item static"
                                        *ngFor="let errorsInput of conscentErrors; trackBy: trackByError"
                                    >
                                        <div class="alert-icon-wrapper">
                                            <clr-icon class="alert-icon" shape="exclamation-circle"> </clr-icon>
                                        </div>
                                        <span class="alert-text">
                                            <span *ngIf="errorsInput.length <= 1"
                                                >{{ label("errorRequiredText") }} :
                                                <em>{{ errorsInput[0].formElement.label[selectedLang] }}</em>
                                            </span>

                                            <ng-container *ngIf="errorsInput[0].type === 'tableFieldsRequired'">
                                                {{ label("errorTableHasInvalidFieldsText") }}
                                            </ng-container>
                                        </span>
                                        <div class="alert-actions">
                                            <a (click)="seeError(errorsInput[0])" class="action-link">{{
                                                label("seeErrorText")
                                            }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button (click)="goToForm()" class="btn">
                                {{ label("backLabel") }}
                            </button>
                            <button (click)="goToConfirm()" class="submit-btn btn btn-primary">
                                {{ label("nextLabel") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="confirm" *ngIf="isConfirmInfoStep">
            <div *ngFor="let formElement of formInputs(); trackBy: trackById">
                <p>
                    <small>{{ formElement.label[selectedLang] }}</small>
                    <br />
                    <fillout-attribute-value
                        [formElement]="formElement"
                        [value]="formResponse(formElement)"
                        [lang]="selectedLang"
                    ></fillout-attribute-value>
                </p>
            </div>

            <button (click)="goToForm()" class="btn">
                {{ label("backLabel") }}
            </button>
            <button (click)="submit()" class="submit-btn btn btn-primary">
                {{ label("submitButtonLabel") }}
            </button>
        </div>
    </div>

    <div class="end-message" *ngIf="isSubmitted">
        <div [innerHtml]="safe(form.endText[selectedLang])"></div>
    </div>
</div>
