import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { debounceTime, first, map, switchMap, take } from 'rxjs/operators';

import { SearchDefinitionMapperService } from '../mappers/search-definition-mapper.service';
import { SearchDefinition } from '../model/search-definition';
import { BookmarkService } from './bookmark.service';
import { ContextNodeService } from './context-node.service';

@Injectable({
    providedIn: "root",
})
export class SearchContextService {
    constructor(
        private db: AngularFirestore,
        private contextNodeService: ContextNodeService,
        private bookmarkService: BookmarkService,
        private searchDefinitionMapper: SearchDefinitionMapperService
    ) { }

    searchDefinition(id: string, options?: { listen: boolean }): Observable<SearchDefinition> {
        if (options && options.listen) {
            return this.db
                .collection("searchContexts")
                .doc(id)
                .snapshotChanges()
                .pipe(
                    map((snapDoc) => {
                        const doc = snapDoc.payload;
                        return doc.exists
                            ? this.searchDefinitionMapper.searchDefinitionDTOToSearchDefinition(doc.data())
                            : SearchDefinition.DEFAULT_SEARCH_DEFINITION(id);
                    })
                );
        } else {
            return this.db
                .collection("searchContexts")
                .doc(id)
                .get()
                .pipe(
                    map((doc) => {
                        return doc.exists
                            ? this.searchDefinitionMapper.searchDefinitionDTOToSearchDefinition(doc.data())
                            : SearchDefinition.DEFAULT_SEARCH_DEFINITION(id);
                    })
                );
        }
    }

    changeState(searchParameterId: string, disabled: boolean): Observable<SearchDefinition> {
        return this.searchDefinition(searchParameterId, { listen: false }).pipe(
            switchMap((s) => {
                return this.saveSearchDefinition(s.setSearchOperationsState(disabled));
            })
        );
    }

    searchParametersForContextNode(contextNodeId: string): Observable<SearchDefinition[]> {
        return this.contextNodeService.node(contextNodeId, { listen: true }).pipe(
            switchMap((node) => {
                return forkJoin(
                    node.children.map((nodeId) => this.contextNodeService.node(nodeId, { listen: false }).pipe(first()))
                );
            }),
            switchMap((childNodes) => {
                const paramsNodes = childNodes.filter(
                    (n) => n.type === "search-parameters" && n.contextData?.searchContextId
                );
                return combineLatest(
                    paramsNodes.map((n) => this.searchDefinition(n.contextData.searchContextId, { listen: true }))
                );
            })
        );
    }

    searchParametersForProject(projectId: string): Observable<SearchDefinition[]> {
        return this.contextNodeService.searchParametersNodeForProject(projectId).pipe(
            switchMap((nodes) => {
                if (nodes.length > 0) {
                    return combineLatest(
                        nodes.map((node) => this.searchDefinition(node.contextData.searchContextId, { listen: true }))
                    );
                } else {
                    return of([]);
                }
            })
        );
    }

    createNewSearchDefinition(searchDef?: SearchDefinition): Observable<SearchDefinition> {
        const searchId = searchDef ? searchDef.id : this.db.createId();

        return this.bookmarkService.saveNewBookmark(searchId).pipe(switchMap(b => {
            const s: SearchDefinition = searchDef ? searchDef : SearchDefinition.DEFAULT_SEARCH_DEFINITION(searchId);
            return this.saveSearchDefinition(s.change({ bookmarkSpec: { bookmark: b, new: false } }));
        }));
    }

    saveSearchDefinition(searchDef: SearchDefinition): Observable<SearchDefinition> {
        return from(
            this.db
                .collection("searchContexts")
                .doc(searchDef.id)
                .set(this.searchDefinitionMapper.searchDefinitionToSearchDefinitionDTO(searchDef))
        ).pipe(
            take(1),
            map((_) => searchDef)
        );
    }
}
