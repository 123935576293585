import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

@Component({
    selector: "castlug-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
    password: string;
    email: string;

    private readonly _disposeBag = new DisposeBag();

    readonly numbers = Array(50)
        .fill(50)
        .map((x, i) => i);

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.route.paramMap.pipe(map(() => window.history.state)).subscribe((state) => {
            if (state && state.errorMessage) {
                this.notificationService.show(state.errorMessage, "danger");
            }
            if (state && state.successMessage) {
                this.notificationService.show(state.successMessage, "success");
            }
        });
        if (this.authService.isLoggedIn) {
            const url = "/castlug/diversite";
            this.router.navigateByUrl(url);
        }
    }

    login(email: string, password: string): void {
        this.authService
            .login(email, password)
            .subscribe((response) => {
                if (response === "success") {
                    const url = "/castlug/diversite";
                    this.router.navigateByUrl(url);
                } else {
                    this.notificationService.show("Votre courriel et/ou votre mot de passe est éroné.", "danger");
                }
            })
            .disposedBy(this._disposeBag);
    }
}
