import { slugify } from "src/app/core/functions";
import { FormElementDTO } from "src/modules/diversite/mappers/module-mapper.service";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";

import { FormElement, FormElementError, FormElementInput, FormElementType, FormError, LiteralValue } from "./form-element";
import { FormOption } from "./form-option";

export interface TableFieldBuilder {
    label: string;
    type: FormElementType;
    description?: string;
    required?: boolean;
    options?: { label: string; name: string }[];
    deleted?: boolean;
}
export class TableField {
    private _label: string;
    private _type: FormElementType;
    private _description: string;
    private _name: string;
    private _required: boolean;
    private _options: { label: string; name: string }[];
    private _deleted: boolean;

    constructor(builder: TableFieldBuilder) {
        this._label = builder.label;
        this._type = builder.type;
        this._description = builder.description || "";
        this._name = slugify(builder.label);
        this._required = builder.required === true ? true : false;
        this._options = builder.options;
        this._deleted = builder.deleted === true ? true : false;
    }

    get label(): string {
        return this._label;
    }

    get type(): string {
        return this._type;
    }

    get name(): string {
        return this._name;
    }

    get description(): string {
        return this._description;
    }

    get required(): boolean {
        return this._required;
    }

    get options(): { label: string; name: string }[] {
        return this._options;
    }
}
interface Builder extends FormElementDTO {
    fields?: { label: string; type: FormElementType }[];
    score?: number;
}
export class FormTable implements FormElement, FormElementInput {
    private _id: string;
    private _nameDescriptor: TranslatableLabel;
    private _description: TranslatableLabel;
    private _name: string;
    private _type: FormElementType = "table";

    private _label: TranslatableLabel;
    private _fields: TableField[];

    private _required: boolean;
    private _readonly: boolean;
    private _score: number;
    private _deleted: boolean;

    static defaultElement(): FormTable {
        return new FormTable({
            nameDescriptor: { fr: "Champ table" },
            label: { fr: "Nouveau champ table" },
            fields: [
                {
                    label: "Champ 1",
                    type: "textbox",
                },
                {
                    label: "Champ 2",
                    type: "textbox",
                },
            ],
        });
    }

    constructor(builder: Builder) {
        this._id = builder.id;
        this._nameDescriptor = builder.nameDescriptor || {};
        this._description = builder.description;

        this._name = builder.name || "";
        this._label = builder.label;
        this._fields = builder.fields.map((f) => new TableField(f)) || [];

        this._required = builder.required === true ? true : false;
        this._readonly = builder.readonly === true ? true : false;
        this._score = builder.score || 0;
        this._deleted = builder.deleted === true ? true : false;
    }
    options?: FormOption[];

    literalValue(value: any): LiteralValue {
        throw new Error("Method not implemented.");
    }
    nameDisabled?: boolean;

    get id(): string {
        return this._id;
    }
    get nameDescriptor(): TranslatableLabel {
        return this._nameDescriptor;
    }
    get description(): TranslatableLabel {
        return this._description;
    }

    get name(): string {
        return this._name;
    }
    get type(): FormElementType {
        return this._type;
    }

    get label(): TranslatableLabel {
        return this._label;
    }
    get fields(): TableField[] {
        return this._fields;
    }

    get required(): boolean {
        return this._required;
    }
    get readonly(): boolean {
        return this._readonly;
    }
    get score(): number {
        return this._score;
    }
    get deleted(): boolean {
        return this._deleted;
    }

    change(builder: Builder): FormTable {
        return new FormTable({
            id: this.id,
            nameDescriptor: this._nameDescriptor,
            description: this.description,
            name: this.name,
            label: this.label,
            fields: this.fields.map((f) => {
                return {
                    label: f.label,
                    type: f.type as FormElementType,
                    description: f.description,
                    required: f.required,
                    options: f.options,
                };
            }),
            score: this.score,
            required: this.required,
            readonly: this.readonly,
            deleted: this.deleted,
            ...builder,
        });
    }

    isInput(): true {
        return true;
    }

    toDto(): FormElementDTO {
        return {
            id: this.id,
            nameDescriptor: this._nameDescriptor,
            description: this.description,
            name: this.name,
            type: this.type,
            label: this.label,
            fields: this.fields.map((f) => {
                return {
                    label: f.label,
                    type: f.type,
                    description: f.description,
                    options: f.options || null,
                    required: f.required,
                };
            }),
            required: this.required,
            readonly: this.readonly,
            deleted: this.deleted,
        };
    }

    validate(value: any): (FormElementTableError | FormElementError)[] {
        const tableErrors = this.allFieldsValid(value);
        const tableMandatoryValid = this._isTableMandatoryValid(value);
        if (tableMandatoryValid.length > 0 || (tableErrors && tableErrors.length > 0)) {
            let errors: (FormElementTableError | FormElementError)[] = [];

            if (tableMandatoryValid) {
                errors = [...errors, ...tableMandatoryValid];
            }

            if (tableErrors && tableErrors.length > 0) {
                errors = [
                    ...errors,
                    {
                        type: "tableFieldsRequired",
                        formElement: this,
                        tableErrors,
                    },
                ];
            }

            if (errors.length > 0) {
                return errors;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private _isTableMandatoryValid(value: []): FormElementError[] {
        if (!this.required || (this.required && Array.isArray(value) && value.length > 0)) {
            return [];
        } else {
            return [
                {
                    type: "required",
                    formElement: this,
                },
            ];
        }
    }

    private allFieldsValid(rows: any[]): FieldError[] {
        if (rows) {
            return rows
                .map((row) =>
                    this.fields
                        .map((field) => {
                            if (!field.required) {
                                return null;
                            } else {
                                if (field.name in row && row[field.name]) {
                                    return null;
                                } else {
                                    return {
                                        fieldName: field.name,
                                        type: "required" as FormError,
                                    };
                                }
                            }
                        })
                        .filter((_) => _)
                )
                .reduce((acc, val) => acc.concat(val), []);
        } else {
            return null;
        }
    }
}

export interface FormElementTableError extends FormElementError {
    type: FormError;
    formElement: FormElementInput;
    tableErrors: FieldError[];
}

export interface FieldError {
    fieldName: string;
    type: FormError;
}
