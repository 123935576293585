import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { UserApiClientService } from 'src/app/api-clients/user-api-client.service';
import { AuthService } from 'src/app/services/auth.service';

import { ReportApiClientService } from '../api-clients/report-api-client.service';
import { ContactProfileDefinition } from '../model/contact-profile-definition';
import { ContactPerspective } from '../model/contactPerspective';
import { Report } from '../model/report';
import { ContactService } from './contact.service';
import { ModulesAttributesService } from './modules-attributes.service';
import { SearchDefinition } from '../model/search-definition';
import { ElasticSearchService } from './elasticsearch.service';
import { SearchState } from '../model/search-state';

@Injectable({
    providedIn: "root",
})
export class ReportService {
    constructor(
        private reportApi: ReportApiClientService,
        private authService: AuthService,
        private modulesAttributesService: ModulesAttributesService,
        private contactService: ContactService,
        private esService: ElasticSearchService,
        private userApi: UserApiClientService
    ) { }

    createReportForSearch(searchDef: SearchDefinition): Observable<string> {
        return this.esService.searchContacts(searchDef, new SearchState({ limit: 9999 })).pipe(switchMap(results => {
            return this.createReport(results.contactResults.map(cr => cr.contactId))
        }))
    }

    createReport(contactIds: string[]): Observable<string> {
        const report = new Report({
            introPageContent: `
            <p class='ql-align-center'><br></p>
            <p class='ql-align-center'><br></p>
            <h1 class='ql-align-center'>Rapport</h1>
            <p class='ql-align-center'><em>Text d'introduction à modifier</em></p>`,
            users: [this.userApi.documentReference(this.authService.loggedInUser.id)],
            contactProfileDefinition: ["idfig", "identity_firstname", "identity_lastname", "identity_dateOfBirth"],
            contactPerspectives: contactIds.map(cId => new ContactPerspective({
                id: cId
            })),
        });
        return this.reportApi.addReport(report);
    }


    private cpsTOREMOVE(cps: ContactPerspective[]): ContactPerspective[] {
        return cps.map(cp => {
            return cp.change({ visibleImages: [cp.visibleImages[0]], layout: [1] })
        })
    }

    private contactProfileDefinitionTOREMOVE(contactProfileDefinition: ContactProfileDefinition): ContactProfileDefinition {
        const defaultAttributes = [
            "identity_lastname",
            "identity_firstname",
            "idfig"
        ];
        return new ContactProfileDefinition({
            attributes: contactProfileDefinition.attributes.map(attr => {
                if (defaultAttributes.includes(attr.index)) {
                    attr.displayed = true;
                } else {
                    attr.displayed = false;
                }
                return attr
            })
        })
    }

    reportById(reportId: string, options?: { listen: boolean }): Observable<Report> {
        return this.reportApi.reportById(reportId, options);
    }

    editReport(report: Report): Observable<void> {
        return this.reportApi.editReport(report);
    }
}
