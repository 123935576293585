import { DocumentReference } from '@angular/fire/compat/firestore';

import { ContactProfileDefinition } from './contact-profile-definition';
import { ContactPerspective } from './contactPerspective';

export interface Builder {
    id?: string;
    name?: string;
    logo?: string;
    logoSize?: number;
    introPage?: boolean;
    introPageContent?: string;
    profilePerPage?: number;
    orientation?: Orientation;
    photoSize?: number;
    proportionFace?: number;
    photoMargin?: number;
    pageMargin?: number;
    type?: ReportType;
    hiddenFieldDefitionIds?: string[];
    contactPerspectives?: ContactPerspective[];
    contactProfileDefinition?: string[];
    users?: DocumentReference[];
    created?: Date;
}

export class Report {
    private _id: string;
    private _name: string;
    private _logo: string;
    private _logoSize: number;
    private _introPage: boolean;
    private _introPageContent: string;
    private _orientation: Orientation;
    private _type: ReportType;
    private _profilePerPage: number;
    private _photoSize: number;
    private _proportionFace: number;
    private _photoMargin: number;
    private _pageMargin: number;
    private _contactPerspectives: ContactPerspective[];
    private _contactProfileDefinition: string[];
    private _users: DocumentReference[];
    private _created: Date;

    constructor(builder: Builder) {
        this._id = builder.id;
        this._name = builder.name || "Titre";
        this._logo = builder.logo || null;
        this._logoSize = builder.logoSize || 15;
        this._type = builder.type || "picture-info";
        this._introPage = builder.introPage === true ? true : false;
        this._introPageContent = builder.introPageContent ? builder.introPageContent : "";
        this._profilePerPage = builder.profilePerPage ? builder.profilePerPage : 2;
        this._orientation = builder.orientation || "landscape";
        this._contactPerspectives = builder.contactPerspectives;
        this._photoSize = builder.photoSize || 1;
        this._proportionFace = builder.proportionFace || 0.5;
        this._photoMargin = builder.photoMargin || 0.2;
        this._pageMargin = builder.pageMargin || 0.6;
        this._contactProfileDefinition = builder.contactProfileDefinition || [];
        this._users = builder.users || [];
        this._created = builder.created;
    }

    get id(): string {
        return this._id;
    }
    get name(): string {
        return this._name;
    }
    get logo(): string {
        return this._logo;
    }
    get logoSize(): number {
        return this._logoSize;
    }
    get introPage(): boolean {
        return this._introPage;
    }
    get introPageContent(): string {
        return this._introPageContent;
    }
    get orientation(): Orientation {
        return this._orientation;
    }
    get type(): ReportType {
        return this._type;
    }
    get profilePerPage(): number {
        return this._profilePerPage;
    }
    get photoSize(): number {
        return this._photoSize;
    }
    get proportionFace(): number {
        return this._proportionFace;
    }
    get photoMargin(): number {
        return this._photoMargin;
    }
    get pageMargin(): number {
        return this._pageMargin;
    }
    get contactPerspectives(): ContactPerspective[] {
        return this._contactPerspectives;
    }
    get users(): DocumentReference[] {
        return this._users;
    }
    get contactProfileDefinition(): string[] {
        return this._contactProfileDefinition;
    }
    get created(): Date {
        return this._created;
    }

    addContactsPerspectives(cps: ContactPerspective[]): Report {
        let currentCps = [...this.contactPerspectives];
        cps.forEach((cp) => {
            if (!currentCps.find((existingCp) => existingCp.id === cp.id)) {
                currentCps.push(cp);
            }
        });

        return this.change({ contactPerspectives: currentCps });
    }

    removeContact(contactId: string): Report {
        return this.change({
            contactPerspectives: this._contactPerspectives.filter(c => c.id !== contactId)
        });
    }

    change(builder: Builder): Report {
        return new Report({
            id: this.id,
            name: this.name,
            logo: this._logo,
            logoSize: this._logoSize,
            introPage: this._introPage,
            introPageContent: this._introPageContent,
            orientation: this._orientation,
            type: this.type,
            profilePerPage: this.profilePerPage,
            proportionFace: this.proportionFace,
            photoSize: this.photoSize,
            photoMargin: this.photoMargin,
            pageMargin: this.pageMargin,
            contactPerspectives: this.contactPerspectives,
            users: this.users,
            contactProfileDefinition: this.contactProfileDefinition,
            created: this._created,
            ...builder,
        });
    }
}

export type ReportType = "picture-grid" | "list" | "picture-info";
export type Orientation = "landscape" | "portrait";
