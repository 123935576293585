import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    AngularFirestore,
    AngularFirestoreModule,
    SETTINGS as USE_FIRESTORE_SETTINGS,
} from '@angular/fire/compat/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CdsModule } from '@cds/angular';
import {
    accessibility2Icon,
    angleDoubleIcon,
    archiveIcon,
    assignUserIcon,
    backupRestoreIcon,
    banIcon,
    barsIcon,
    boltIcon,
    bookmarkIcon,
    briefcaseIcon,
    bugIcon,
    certificateIcon,
    ClarityIcons,
    clipboardIcon,
    cogIcon,
    copyToClipboardIcon,
    cursorHandOpenIcon,
    dataClusterIcon,
    downloadIcon,
    dragHandleCornerIcon,
    dragHandleIcon,
    employeeIcon,
    envelopeIcon,
    exportIcon,
    eyeIcon,
    fileIcon,
    fileShareIcon,
    folderIcon,
    formIcon,
    highlighterIcon,
    infoCircleIcon,
    landscapeIcon,
    layersIcon,
    linkIcon,
    listIcon,
    lockIcon,
    logoutIcon,
    minusCircleIcon,
    minusIcon,
    noteIcon,
    pdfFileIcon,
    pencilIcon,
    pictureIcon,
    plusCircleIcon,
    plusIcon,
    popOutIcon,
    refreshIcon,
    sadFaceIcon,
    scrollIcon,
    searchIcon,
    shareIcon,
    sortByIcon,
    starIcon,
    storageIcon,
    thumbsDownIcon,
    thumbsUpIcon,
    timesIcon,
    toolsIcon,
    trashIcon,
    treeViewIcon,
    unknownStatusIcon,
    unlockIcon,
    uploadCloudIcon,
    uploadIcon,
    userIcon,
    usersIcon,
    viewCardsIcon,
    viewListIcon,
    warningStandardIcon,
    fileZipIcon,
    xlsFileIcon,
    infoStandardIcon
} from '@cds/core/icon';
import { ClarityModule } from '@clr/angular';
import { environment } from 'src/environments/environment';

import { SearchValueSelectorComponent } from './components/search-value-selector/search-value-selector.component';
import {
    SearchValueHeightComponent,
} from './components/search-value-selector/types/search-value-height/search-value-height.component';
import {
    SearchValueMulitpleSelectionComponent,
} from './components/search-value-selector/types/search-value-mulitple-selection/search-value-mulitple-selection.component';
import {
    SearchValueUnionComponent,
} from './components/search-value-selector/types/search-value-union/search-value-union.component';
import {
    SearchValueWeightComponent,
} from './components/search-value-selector/types/search-value-weight/search-value-weight.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';

const MODULES = [
    RouterModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ClarityModule,
];

ClarityIcons.addIcons(
    plusIcon,
    fileIcon,
    folderIcon,
    userIcon,
    banIcon,
    cogIcon,
    sortByIcon,
    searchIcon,
    sadFaceIcon,
    toolsIcon,
    formIcon,
    logoutIcon,
    cogIcon,
    folderIcon,
    plusIcon,
    bookmarkIcon,
    briefcaseIcon,
    userIcon,
    usersIcon,
    accessibility2Icon,
    eyeIcon,
    timesIcon,
    archiveIcon,
    dragHandleCornerIcon,
    certificateIcon,
    refreshIcon,
    minusCircleIcon,
    plusCircleIcon,
    pencilIcon,
    cursorHandOpenIcon,
    bugIcon,
    dragHandleIcon,
    downloadIcon,
    noteIcon,
    employeeIcon,
    pictureIcon,
    listIcon,
    copyToClipboardIcon,
    trashIcon,
    highlighterIcon,
    linkIcon,
    starIcon,
    warningStandardIcon,
    infoCircleIcon,
    briefcaseIcon,
    barsIcon,
    formIcon,
    clipboardIcon,
    treeViewIcon,
    unknownStatusIcon,
    landscapeIcon,
    scrollIcon,
    backupRestoreIcon,
    angleDoubleIcon,
    layersIcon,
    exportIcon,
    envelopeIcon,
    pencilIcon,
    noteIcon,
    thumbsDownIcon,
    thumbsUpIcon,
    minusIcon,
    dataClusterIcon,
    storageIcon,
    lockIcon,
    unlockIcon,
    searchIcon,
    uploadCloudIcon,
    viewCardsIcon,
    viewListIcon,
    assignUserIcon,
    fileShareIcon,
    uploadIcon,
    linkIcon,
    pdfFileIcon,
    popOutIcon,
    shareIcon,
    boltIcon,
    xlsFileIcon,
    fileZipIcon,
    infoStandardIcon,
    boltIcon
);

@NgModule({
    declarations: [
        SearchValueSelectorComponent,
        VideoPlayerComponent,
        SearchValueWeightComponent,
        SearchValueHeightComponent,
        SearchValueUnionComponent,
        SearchValueMulitpleSelectionComponent,
    ],
    imports: [
        ...MODULES,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        // AngularFirestoreModule.enablePersistence({
        //     synchronizeTabs: true
        // }),
        AngularFirestoreModule,
        CdsModule,
    ],
    exports: [...MODULES, SearchValueSelectorComponent, VideoPlayerComponent],
    providers: [AngularFirestore, AngularFireAuth, DatePipe, {
        provide: USE_FIRESTORE_SETTINGS,
        useValue: {
            merge: true,
        }
    }],
})
export class CoreModule { }
